import React, { Component } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { ReactTable, TableHeaderRenderer, TableCellRenderer, showAlertMessage, Select, ConfirmBox, Modal, ErrorMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";
import AddManualCredits from "./components/AddManualCredits";
import EditUser from "./components/EditUser";

import { IconSearch } from "../../helpers/iconStyles";
import { isValidEmail } from "../../helpers/utils";

import { createUser, lockUnlockUser, deleteUser, resetUserPassword } from "../../services/userManagement";
import { getClientList } from "../../services/dashboard";

import { fetchUserList, resetUserList } from "../../actions/userManagement";
import { updateLoadingState } from "../../actions/application";

const defaultClientfilter = { client_id: null, client_name: "All Clients", isdisabled: false };
const defaultRolefilter = { id: null, name: "All Roles", isdisabled: false };
const defaultStatusfilter = { id: null, name: "All Status", isdisabled: false };
const rolesData = [{ id: null, name: "All Roles" }, { id: 1, name: "Admin" }, { id: 2, name: "Client" }];

const activeFilterList = [
  { id: null, name: "All Status", isdisabled: false },
  { id: 1, name: "Active", isdisabled: false },
  { id: 0, name: "In-Active", isdisabled: false },
];

class UserManagement extends Component {

  constructor(props) {
    super(props);

    const { intl } = props;

    this.defaultFilters = {
      roleId: defaultRolefilter,
      clientId: defaultClientfilter,
      isActive: defaultStatusfilter
    };

    this.defaultCreateUser = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      isNewClient: false,
      companyName: "",
      roleId: null,
      clientId: null
    };

    this.timer = null;

    this.state = {
      searchText: "",
      filters: _cloneDeep(this.defaultFilters),
      createUser: _cloneDeep(this.defaultCreateUser),
      tableData: [],
      clientList: [],
      selRecord: {},
      showLockModal: false,
      showUnlockModal: false,
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false,
      showResetPasswordModal: false,
      showCreditModal: false,
      reSyncTableData: false,
      errors: {}
    };

    this.columns = [
      {
        ID: "name",
        accessor: "name",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "user_management.table.name", defaultMessage: "Name" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "email",
        accessor: "email",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "user_management.table.email", defaultMessage: "Email" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "organization",
        accessor: "organization",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "user_management.table.organization", defaultMessage: "Organization" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "current_credits",
        accessor: "current_credits",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "user_management.table.available_credits", defaultMessage: "Available credits" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "role",
        accessor: "role",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "user_management.table.role", defaultMessage: "Role" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "user_id",
        accessor: "user_id",
        Header: () => (<TableHeaderRenderer sortable={true} className="text-center" value={intl.formatMessage({ id: "user_management.table.action", defaultMessage: "Action" })} />),
        Cell: (row) => {
          const isEnabled = _get(row, "row.original.enabled", false);

          return (
            <Dropdown className="dropdown-dot text-center">
              <Dropdown.Toggle className="text-capitalize dropdown-dot-custom">
                <span>
                  <FormattedMessage id="dropdown.dots" defaultMessage="•••" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul">
                {(isEnabled === true) && (
                  <Dropdown.Item as="li" onClick={() => this.setState({ selRecord: _get(row, "row.original", {}), showLockModal: true })}>
                    <span className="text-capitalize cursor-pointer">
                      <FormattedMessage id="user_management.action.lock_user" defaultMessage="Lock User" />
                    </span>
                  </Dropdown.Item>
                )}

                {(isEnabled === false) && (
                  <Dropdown.Item as="li" onClick={() => this.setState({ selRecord: _get(row, "row.original", {}), showUnlockModal: true })}>
                    <span className="text-capitalize cursor-pointer">
                      <FormattedMessage id="user_management.action.unlock_user" defaultMessage="Unlock User" />
                    </span>
                  </Dropdown.Item>
                )}

                <Dropdown.Item as="li" onClick={() => this.setState({ selRecord: _get(row, "row.original", {}), showEditModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.edit" defaultMessage="Edit" />
                  </span>
                </Dropdown.Item>

                <Dropdown.Item as="li" onClick={() => this.setState({ selRecord: _get(row, "row.original", {}), showDeleteModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.delete" defaultMessage="delete" />
                  </span>
                </Dropdown.Item>

                <Dropdown.Item as="li" onClick={() => this.setState({ selRecord: _get(row, "row.original", {}), showResetPasswordModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.reset_password" defaultMessage="Reset Password" />
                  </span>
                </Dropdown.Item>

                <Dropdown.Item as="li" onClick={() => this.setState({ selRecord: _get(row, "row.original", {}), showCreditModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.add_credits" defaultMessage="Add credits" />
                  </span>
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
          );
        }
      },
    ];
  }

  componentDidMount() {
    const { tableData } = this.props;

    this._fetchClientList();
    this._fetchTableData();

    this.setState({ tableData: _get(tableData, "data", []), reSyncTableData: false });
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.createUser, this.state.createUser)) { this._handleValidation(); }

    if (!_isEqual(prevProps.tableData, this.props.tableData) && !_isEmpty(this.props.tableData)) {
      const { tableData, resetUserList } = this.props;

      if ((tableData.status || null) === null) { return false; }

      if ((tableData.status || false) === true) {
        this.setState({ tableData: _get(tableData, "data", []), reSyncTableData: false });
      } else {
        showAlertMessage(tableData.message || "Something went wrong while fetching user list.");

        if (typeof resetUserList === "function") { resetUserList(); }
      }
    }

    if (
      (!_isEqual(prevState.filters, _get(this.state, "filters", {})) && !_isEmpty(_get(this.state, "filters", {}))) ||
      (!_isEqual(prevState.reSyncTableData, _get(this.state, "reSyncTableData", false)) && (_get(this.state, "reSyncTableData", false) === true))
    ) {
      this._fetchTableData();
    }

    if (!_isEqual(prevState.searchText, _get(this.state, "searchText", ""))) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this._fetchTableData();
      }, 500);
    }

    if (!_isEqual(prevState.showCreateModal, _get(this.state, "showCreateModal", false)) && _get(this.state, "showCreateModal", false) === true) {
      this.setState({ errors: {} });
    }
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { createUser: { firstName, lastName, email, password, isNewClient, companyName, roleId, clientId } } = this.state;
    let errors = {};

    if (_isEmpty(firstName)) {
      errors["firstName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "first_name", defaultMessage: "first_name" }),
      });
    }

    if (_isEmpty(lastName)) {
      errors["lastName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "last_name", defaultMessage: "last_name" }),
      });
    }

    if (!isValidEmail(email)) {
      errors["email"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}" }, {
        field: intl.formatMessage({ id: "email", defaultMessage: "email" }),
      });
    }

    if ((_get(roleId, "id", null) === null) || (roleId || null) === null) {
      errors["roleId"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "role", defaultMessage: "Role" }),
      });
    }

    if (
      ((isNewClient === true) && _isEmpty(companyName)) ||
      (
        (isNewClient === false) &&
        ((_get(clientId, "client_id", null) === null) || ((clientId || null) === null))
      )
    ) {
      errors["clientId"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "client", defaultMessage: "Client" }),
      });
    }

    if (_isEmpty(password)) {
      errors["password"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "password", defaultMessage: "password" }),
      });
    }

    if (returnFlag === true) { return !_isEmpty(errors); }

    this.setState({ errors });
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      createUser: {
        ...prevState.createUser,
        [name]: value
      }
    }));
  }

  _fetchClientList = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getClientList();

      let filterData = _get(response, "data", {});
      (filterData || []).unshift(_cloneDeep(defaultClientfilter));

      this.setState({ clientList: filterData });

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching client list.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _fetchTableData = () => {
    const { fetchUserList } = this.props;
    const { filters, searchText } = this.state;

    if (typeof fetchUserList === "function") {
      fetchUserList({
        role_id: _get(filters, "roleId.id", null),
        client_id: _get(filters, "clientId.client_id", null),
        search_text: (searchText || ""),
        is_active: _get(filters, "isActive.id", 1)
      });
    }
  }

  _handleCreateUser = async () => {
    const { updateLoadingState } = this.props;
    const { createUser: { firstName, lastName, email, password, isNewClient, companyName, roleId, clientId }, errors } = this.state;

    if (_isEmpty(email) || _isEmpty(password) || _isEmpty(firstName) || _isEmpty(lastName) || (!roleId) || (!clientId) || !_isEmpty(errors)) {
      this._handleValidation();

      return;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await createUser({
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        role_id: _get(roleId, "id", null),
        is_new_client: (isNewClient || false),
        company_name: ((isNewClient || false) === true) ? (companyName || "") : "",
        client_id: ((isNewClient || false) === true) ? null : _get(clientId, "client_id", null)
      });

      if (_get(response, "data.status", false) === true) {
        showAlertMessage(_get(response, "data.message", "User created successfully."), "success");

        this._fetchTableData();

        if (isNewClient === true) { this._fetchClientList(); }
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while creating user."));
      }

      this.setState({ showCreateModal: false, createUser: _cloneDeep(this.defaultCreateUser) });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching creating user.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleLockUser = async () => {
    const { updateLoadingState } = this.props;
    const { selRecord } = this.state;

    const isUnlocked = (_get(selRecord, "enabled", false) === false) ? true : false;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await lockUnlockUser({ userId: _get(selRecord, "user_id", 0), isunLocked: isUnlocked });

      if (_get(response, "data.status", false) === true) {
        showAlertMessage(_get(response, "data.message", "User locked/unlocked successfully."), "success");

        this._fetchTableData();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while locking/unlocking user."));
      }

      this.setState({ showLockModal: false, showUnlockModal: false, selRecord: {} });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching locking/unlocking user.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleDeleteUser = async () => {
    const { updateLoadingState } = this.props;
    const { selRecord } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await deleteUser(_get(selRecord, "user_id", 0));

      if (_get(response, "data.status", false) === true) {
        showAlertMessage(_get(response, "data.message", "User deleted successfully."), "success");

        this._fetchTableData();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while deleting user."));
      }

      this.setState({ showDeleteModal: false, selRecord: {} });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching deleting user.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleResetUserPassword = async () => {
    const { updateLoadingState } = this.props;
    const { selRecord } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await resetUserPassword({ userId: _get(selRecord, "user_id", 0) });

      if (_get(response, "data.status", false) === true) {
        showAlertMessage(_get(response, "data.message", "Reset Password mail sent successfully."), "success");
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while reseting password."));
      }

      this.setState({ showResetPasswordModal: false, selRecord: {} });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching reseting password.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderTopFilters = () => {
    const { intl } = this.props;
    const { clientList, searchText, filters: { roleId, clientId, isActive } } = this.state;

    return (
      <div className="row mb-5">
        <div className="col-lg-4">
          <div className="position-relative mb-lg-0 mb-15">
            <span className="position-absolute end-0 top-0 mt-3 me-3">
              <IconSearch width="14px" height="14px" color="#9d9b9b" />
            </span>
            <input
              type="text"
              name="search"
              className="form-control"
              placeholder="Search"
              value={(searchText || "")}
              onChange={(e) => {
                const val = _get(e, "target.value", "");

                this.setState({ "searchText": val });
              }}
            />
          </div>
        </div>
        <div className="col-lg">
          <div className="position-relative mb-lg-0 mb-15">
            <Select
              className="text-capitalize form-select-custom"
              placeholder={intl.formatMessage({ id: "status", defaultMessage: "Status" })}
              value={(_get(isActive, "id", null) === null) ? defaultStatusfilter : (isActive || 0)}
              options={(activeFilterList || [])}
              getOptionLabel={(option) => (option.name || "")}
              getOptionValue={(option) => (option.id === null) ? (option.id || null) : (option.id || 0)}
              isOptionDisabled={(option) => (option.isdisabled || false)}
              onChange={(y) => this.setState((prevState) => ({ filters: { ...prevState.filters, "isActive": (y || 0) } }))}
              isMulti={false}
            />
          </div>
        </div>

        <div className="col-lg">
          <div className="position-relative mb-lg-0 mb-15">
            <Select
              className="text-capitalize form-select-custom"
              placeholder={intl.formatMessage({ id: "role", defaultMessage: "Role" })}
              value={(roleId || null)}
              options={(rolesData || [])}
              getOptionLabel={(option) => (option.name || "")}
              getOptionValue={(option) => (option.id || null)}
              isOptionDisabled={(option) => (option.isdisabled || false)}
              onChange={(y) => this.setState((prevState) => ({ filters: { ...prevState.filters, "roleId": (y || null) } }))}
              isMulti={false}
            />
          </div>
        </div>
        <div className="col-lg">
          <div className="position-relative mb-lg-0 mb-15">
            <Select
              className="text-capitalize form-select-custom"
              placeholder={intl.formatMessage({ id: "client", defaultMessage: "client" })}
              value={(clientId || null)}
              options={(clientList || [])}
              getOptionLabel={(option) => (option.client_name || "")}
              getOptionValue={(option) => (option.client_id || null)}
              isOptionDisabled={(option) => (option.isdisabled || false)}
              onChange={(y) => this.setState((prevState) => ({ filters: { ...prevState.filters, "clientId": (y || null) } }))}
              isMulti={false}
            />
          </div>
        </div>
        <div className="col-lg">
          <div className="text-end mb-lg-0 mb-15">
            <button type="button" className="btn btn-outline-primary text-capitalize" onClick={() => this.setState({ filters: { ...this.defaultFilters }, searchText: "" })} >
              <FormattedMessage id="btn.reset" defaultMessage="reset" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  _renderTableData = () => {
    const { tableData } = this.state;

    return (
      <div className="col-lg-12">
        <div className="bg-white border p-6 rounded-4 mb-5">
          <ReactTable tableData={(tableData || [])} columns={this.columns} />
        </div>
      </div>
    );
  }

  _renderCreateUser = () => {
    const { intl } = this.props;
    const { showCreateModal, createUser: { firstName, lastName, email, password, roleId, clientId }, clientList, errors } = this.state;

    let updatedRoleList = _cloneDeep(rolesData);
    (updatedRoleList || []).shift();

    let updatedClientList = _cloneDeep(clientList);
    (updatedClientList || []).shift();

    return (
      <Modal
        size="lg"
        bodyClassName="overflow-unset"
        modalTitle={intl.formatMessage({ id: "user_management.create_user.title", defaultMessage: "Create User" })}
        onHide={() => this.setState({ showCreateModal: false })}
        onClose={() => this.setState({ showCreateModal: false })}
        onSubmit={() => this._handleCreateUser()}
        footerClassName=""
        centered={true}
        showCloseButton={true}
        isOpen={(showCreateModal || false)}
        closeBtnText={intl.formatMessage({ id: "btn.close", defaultMessage: "close" })}
        submitBtnText={intl.formatMessage({ id: "btn.submit", defaultMessage: "submit" })}
      >
        <div className="section-create-user">
          <div className="container">
            <div className="row">
              <Form autoComplete="off">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-5">
                      <label className="form-label mb-2"><FormattedMessage id="first_name" defaultMessage="First Name" /></label>
                      <input type="text" name="firstName" value={(firstName || "")} className="form-control" placeholder="First Name" onChange={(e) => this._handleChange(e)} />
                      {(errors.firstName || "") && (
                        <ErrorMessage message={(errors.firstName || "")} />
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-5">
                      <label className="form-label mb-2"><FormattedMessage id="last_name" defaultMessage="Last Name" /></label>
                      <input type="text" name="lastName" className="form-control" value={(lastName || "")} placeholder="Last Name" onChange={(e) => this._handleChange(e)} />
                      {(errors.lastName || "") && (
                        <ErrorMessage message={(errors.lastName || "")} />
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16">
                      <label className="form-label mb-2 text-capitalize"><FormattedMessage id="email" defaultMessage="Email" /></label>
                      <input type="email" name="email" className="form-control" value={(email || "")} placeholder="Email" onChange={(e) => this._handleChange(e)} />
                      {(errors.email || "") && (
                        <ErrorMessage message={(errors.email || "")} />
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16">
                      <label className="form-label mb-2 text-capitalize"><FormattedMessage id="password" defaultMessage="Password" /></label>
                      <input type="password" name="password" className="form-control" value={(password || "")} placeholder="Password" onChange={(e) => this._handleChange(e)} />
                      {(errors.password || "") && (
                        <ErrorMessage message={(errors.password || "")} />
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">

                    <div className="mt-5">
                      <label className="form-label mb-2"><FormattedMessage id="role" defaultMessage="Role" /></label>
                      <Select
                        className="text-capitalize form-select-custom form-custom-lg-select mb-2"
                        placeholder={intl.formatMessage({ id: "role", defaultMessage: "Role" })}
                        value={(roleId || null)}
                        options={(updatedRoleList || [])}
                        getOptionLabel={(option) => (option.name || "")}
                        getOptionValue={(option) => (option.id || null)}
                        isOptionDisabled={(option) => (option.isdisabled || false)}
                        onChange={(y) => this.setState((prevState) => ({ createUser: { ...prevState.createUser, "roleId": (y || null) } }))}
                        isMulti={false}
                        error={(errors.roleId || "")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mt-5">
                      <label className="form-label  mb-2"><FormattedMessage id="client" defaultMessage="Client" /></label>
                      <Select
                        className="text-capitalize form-select-custom form-custom-lg-select mb-2"
                        placeholder={intl.formatMessage({ id: "client", defaultMessage: "client" })}
                        value={(clientId || null)}
                        options={(updatedClientList || [])}
                        getOptionLabel={(option) => (option.client_name || "")}
                        getOptionValue={(option) => (option.client_id || null)}
                        isOptionDisabled={(option) => (option.isdisabled || false)}
                        getNewOptionData={(inputValue, optionLabel) => ({ client_id: inputValue, client_name: optionLabel, isNewOption: true })}
                        onChange={(y) => {

                          if (_get(y, "isNewOption", false) === true) {

                            this.setState((prevState) => ({
                              createUser: { ...prevState.createUser, isNewClient: true, companyName: _get(y, "client_id", ""), clientId: (y || null) }
                            }));
                          } else {
                            this.setState((prevState) => ({
                              createUser: { ...prevState.createUser, isNewClient: false, companyName: "", clientId: (y || null) }
                            }));
                          }
                        }}
                        isCreatable={true}
                        isMulti={false}
                        error={(errors.clientId || "")}
                      />
                    </div>

                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { intl } = this.props;
    const { showDeleteModal, showEditModal, showLockModal, showUnlockModal, showResetPasswordModal, showCreditModal, selRecord, clientList } = this.state;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.user_management", defaultMessage: "User Management: Enrich B2B" })} </title>
        </Helmet>

        <div>
          <div className="mt-6 my-4 d-flex flex-lg-row flex-column gap-5 align-items-lg-center justify-content-between">
            <h1 className="heading-02 fw-medium">
              <FormattedMessage id="user_management.title" defaultMessage="User Management" />
            </h1>

            <button type="button" className="btn btn-primary text-capitalize" onClick={() => this.setState({ showCreateModal: true })}>
              <img src="images/icons/icon-plus.svg" alt={intl.formatMessage({ id: "btn.create_section", defaultMessage: "create {section}" },
                { section: intl.formatMessage({ id: "user", defaultMessage: "user" }) })} />
              <FormattedMessage id="btn.create_section" defaultMessage="create {section}" values={{ section: intl.formatMessage({ id: "user", defaultMessage: "User" }) }} />
            </button>
          </div>

          {this._renderTopFilters()}

          <div className="row">
            {this._renderTableData()}
          </div>
        </div>

        {/* Confirm Delete User */}
        <ConfirmBox
          isOpen={(showDeleteModal || false)}
          onConfirm={() => this._handleDeleteUser()}
          onClose={() => this.setState({ showDeleteModal: false, selRecord: {} })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_delete", defaultMessage: "Are you sure you want to delete {field}?" }, { field: _get(selRecord, "name", "") })}
        />

        {/* Confirm Lock User */}
        <ConfirmBox
          isOpen={(showLockModal || false)}
          onConfirm={() => this._handleLockUser()}
          onClose={() => this.setState({ showLockModal: false, selRecord: {} })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_lock", defaultMessage: "Are you sure you want to lock {field}?" }, { field: _get(selRecord, "name", "") })}
        />

        {/* Confirm Unlock User */}
        <ConfirmBox
          isOpen={(showUnlockModal || false)}
          onConfirm={() => this._handleLockUser()}
          onClose={() => this.setState({ showUnlockModal: false, selRecord: {} })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_unlock", defaultMessage: "Are you sure you want to unlock {field}?" }, { field: _get(selRecord, "name", "") })}
        />

        {/* Confirm reset password User */}
        <ConfirmBox
          isOpen={(showResetPasswordModal || false)}
          onConfirm={() => this._handleResetUserPassword()}
          onClose={() => this.setState({ showResetPasswordModal: false, selRecord: {} })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_reset_password", defaultMessage: "Are you sure you want to reset password for {field}?" }, { field: _get(selRecord, "name", "") })}
        />

        {this._renderCreateUser()}

        <EditUser
          selRecord={(selRecord || {})}
          showEditModal={(showEditModal || false)}
          clientList={(clientList || [])}
          setState={(args) => this.setState(args)}
        />

        <AddManualCredits
          selRecord={(selRecord || {})}
          showCreditModal={(showCreditModal || false)}
          setState={(args) => this.setState(args)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tableData: _get(state, "user_management.list", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserList: (data) => dispatch(fetchUserList(data)),
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  resetUserList: () => dispatch(resetUserList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserManagement)));