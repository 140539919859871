import React, { createRef, Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import DateRangePickerTmp from "react-bootstrap-daterangepicker";
import moment from "moment";
import { IconCalendar } from "../../../helpers/iconStyles";

class DateRangePicker extends Component {

  constructor(props) {
    super(props);

    const { appConstants: { dateRangeSlots } } = props;

    this.state = {
      selectedRangeLabel: "",
      startDates: _get(dateRangeSlots, "No Date.[0]", moment().toDate()),
      endDates: _get(dateRangeSlots, "No Date.[1]", moment().subtract(1, "days").toDate()),
    };

    this.dateRangeRef = createRef();
  }

  componentDidMount() {
    const { datePickerLabel = "", startDates, endDates, appConstants: { dateRangeSlots, displayDateFormat }, intl } = this.props;

    let stateData = {
      selectedRangeLabel: (!_isEmpty(datePickerLabel)) ? datePickerLabel : intl.formatMessage({ id: "date_range_picker.default_label", defaultMessage: "date range" })
    };

    if (!_isEmpty(startDates) && !_isEmpty(endDates)) {

      stateData = {
        ...stateData,
        selectedRangeLabel: intl.formatMessage({ id: "date_range_picker.date_range", defaultMessage: "{startDate} - {endDate}" }, {
          startDate: moment(startDates).format(displayDateFormat), endDate: moment(endDates).format(displayDateFormat)
        }),
        startDates: !_isEmpty(startDates) ? startDates : _get(dateRangeSlots, "No Date.[0]", moment().toDate()),
        endDates: !_isEmpty(endDates) ? endDates : _get(dateRangeSlots, "No Date.[1]", moment().subtract(1, "days").toDate())
      };
    }

    this.setState(stateData);
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.resetDateRangePicker !== this.props.resetDateRangePicker) && ((this.props.resetDateRangePicker || false) === true)) {
      this._handleReset();
    }

    if (!_isEqual(prevProps.datePickerLabel, this.props.datePickerLabel)) {
      this._handleDatePickeLabelChange();
    }
  }

  _handleDatePickeLabelChange = () => {
    const { appConstants: { dateRangeSlots }, datePickerLabel, intl } = this.props;

    if (datePickerLabel === "No Date") {
      this.setState({
        selectedRangeLabel: intl.formatMessage({ id: "date_range_picker.default_label", defaultMessage: "date range" }),
        startDates: _get(dateRangeSlots, "No Date.[0]", moment().toDate()),
        endDates: _get(dateRangeSlots, "No Date.[1]", moment().subtract(1, "days").toDate())
      });
    }
  }

  _handleReset = () => {
    const { onChange, appConstants: { dateRangeSlots }, intl } = this.props;

    const startDate = _get(dateRangeSlots, "No Date.[0]", moment().toDate());
    const endDate = _get(dateRangeSlots, "No Date.[1]", moment().subtract(1, "days").toDate());

    this.dateRangeRef.current.setStartDate(endDate);
    this.dateRangeRef.current.setEndDate(endDate);
    this.dateRangeRef.current.setStartDate(startDate);

    const picker = (document.getElementsByClassName("daterangepicker")[0] || null);

    if (!_isEmpty(picker)) {
      picker.classList.remove("show-calendar");
    }

    this.setState({
      selectedRangeLabel: intl.formatMessage({ id: "date_range_picker.default_label", defaultMessage: "date range" }),
      startDates: startDate,
      endDates: endDate
    });

    // Callback for props
    if (typeof onChange === "function") {
      onChange(null, null, "No Date");
    }
  }

  _handleUpdateDateRange = (start, end, dtLabel) => {
    const { appConstants: { APIRequestDateFormat, displayDateFormat }, onChange, intl } = this.props;

    let startDate = null;
    let endDate = null;
    let label = intl.formatMessage({ id: "date_range_picker.default_label", defaultMessage: "date range" });

    let state = {};

    if (dtLabel !== "No Date") {
      startDate = start.format(APIRequestDateFormat);
      endDate = end.format(APIRequestDateFormat);

      state = { ...state, startDates: start, endDates: end };

      label = intl.formatMessage({ id: "date_range_picker.date_range", defaultMessage: "{startDate} - {endDate}" }, {
        startDate: start.format(displayDateFormat), endDate: end.format(displayDateFormat)
      });
    }

    state = { ...state, selectedRangeLabel: label };

    // Callback for props
    if (typeof onChange === "function") { onChange(startDate, endDate, dtLabel); }

    if (!_isEmpty(state)) { this.setState(state); }
  }

  render() {
    const { appConstants: { dateRangeSlots }, className, intl } = this.props;
    const { selectedRangeLabel, startDates, endDates } = this.state;

    const truncateClass = (className || "text-truncate");

    return (
      <div className="date-range-picker">
        <div className="custom-select position-relative text-capitalize" onClick={() => this.dateRangeRef.current.ref.click()}>
          <DateRangePickerTmp
            ref={this.dateRangeRef}
            initialSettings={{
              startDate: startDates,
              endDate: endDates,
              ranges: dateRangeSlots,
            }}
            onCallback={this._handleUpdateDateRange}
          >
            <span onClick={(e) => e.stopPropagation()} className={`${(truncateClass || "text-truncate")} ${(selectedRangeLabel === intl.formatMessage({ id: "date_range_picker.default_label", defaultMessage: "date range" })) ? "" : ""}`} >{(selectedRangeLabel || "")}</span>
          </DateRangePickerTmp>

          <div className="position-absolute end-0 top-0 mt-3 me-3">
            <IconCalendar width="14px" height="14px" color="#636E72" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  appConstants: _get(state, "application.constants", {})
});

export default connect(mapStateToProps, null)(injectIntl(DateRangePicker));
