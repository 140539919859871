import { get as _get } from "lodash";
import { objectToParams } from "../helpers/utils";
import api from "../helpers/api";

export const getApplication = (uri) => {
	return api().get("/User/Configuration").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching application configuration."),
			data: {}
		};
	});
}

export const getUserSetting = () => {
	return api().get("/user/user_details").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching user setting details."),
			data: {}
		};
	});
}

export const getAPIKey = () => {
	return api().get("/user/get_api_key").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching API key."),
			data: {}
		};
	});
}

export const resetAPIKey = () => {
	return api().put("/user/reset_api_key", {}).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {

		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong rest API key."),
			data: {}
		};
	});
}

export const updateUserDetails = (variables) => {
	return api().put("/user/update_user_details", (variables || {})).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {

		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while updating user details."),
			data: {}
		};
	});
}
export const getPriceList = () => {
	return api().get("/subscription/credit_packages").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching Price list."),
			data: {}
		};
	});
}

export const addCreditPayment = (variables) => {
	return api().post("/subscription/payment", variables).then((res) => {
		return {
			flag: true,
			message: _get(res, "data.message", ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {

		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while initiating payment process."),
			data: {}
		};
	});
}

export const getPaymentDetails = (type = "failed", variables) => {
	const params = objectToParams(variables);

	return api().get(`/subscription/payment/${type}?${params}`).then((res) => {

		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching payment details."),
			data: {}
		};
	});
}

export const getCreditDetails = () => {
	return api().get("/user/user_credit_info").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching credit details."),
			data: {}
		};
	});
}

export const createBookACall = (variables) => {
	return api().post("/book_call/info", variables).then((res) => {
		return {
			flag: true,
			message: _get(res, "data.message", ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while Book a Call request."),
			data: {}
		};
	});
}
