import { all } from "redux-saga/effects";

import userManagement from "./userManagement";
import application from "./application";
import dashboard from "./dashboard";
import enrich from "./enrich";
import oauth from "./oauth";

export default function* () {
  yield all([
    ...userManagement,
    ...application,
    ...dashboard,
    ...enrich,
    ...oauth,
  ]);
}