import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import config from "../../../helpers/config";

export default React.memo(function Header(props) {

  const { isLoggedIn } = props;
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light bg-white p-0" aria-label="Enrich B2B">
        <div className="container container-1120">
          <Link className="navbar-brand" to="/">
            <img src={require("../../../assets/images/logo.svg")} className="img-fluid" alt="Enrich B2B" />
          </Link>
          <button
            className={`navbar-toggler ${(showMenu === true) ? "" : "collapsed"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mobileMenu"
            aria-controls="navbarTogglerDemo01"
            aria-expanded={(showMenu === true) ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={() => setShowMenu(!showMenu)}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`navbar-collapse collapse ${(showMenu === true) ? "show" : ""}`} id="mobileMenu">
            <ul className="navbar-nav ms-auto align-items-center pt-lg-0 pt-8">
              <li className="nav-item text-capitalize">
                <NavLink activeclassname="active" className="nav-link" to="/" onClick={() => setShowMenu(false)}>
                  <FormattedMessage id="menu.home" defaultMessage="home" />
                </NavLink>
              </li>
              <li className="nav-item text-capitalize">
                <NavLink activeclassname="active" className="nav-link" to="/pricing" onClick={() => setShowMenu(false)}> <FormattedMessage id="menu.pricing" defaultMessage="pricing" /> </NavLink>
              </li>
              {/*<li className="nav-item text-capitalize">
                <NavLink activeclassname="active" className="nav-link" to="/faq"> <FormattedMessage id="menu.faqs" defaultMessage="FAQs" /> </NavLink>
              </li>*/}
              <li className="nav-item text-capitalize">
                <NavLink activeclassname="active" className="nav-link" to="/contact-us" onClick={() => setShowMenu(false)}> <FormattedMessage id="menu.contact" defaultMessage="contact" /> </NavLink>
              </li>
            </ul>

            <ul className="navbar-nav ms-auto align-items-center">
              {(isLoggedIn === false) && (
                <>
                  <li className="nav-item">
                    <NavLink activeclassname="active" className="nav-link" to="/sign-up" onClick={() => setShowMenu(false)}><FormattedMessage id="menu.sign_up" defaultMessage="Sign Up" /> </NavLink>
                  </li>
                  <li className="nav-item text-capitalize">
                    <Link className="btn btn-primary text-transform-none" to="/login" onClick={() => setShowMenu(false)}> <FormattedMessage id="menu.login" defaultMessage="login" /> </Link>
                  </li>
                </>
              )}

              {(isLoggedIn === true) && (
                <li className="nav-item text-capitalize">
                  <a href={config.getAdminURL(true, true, "/enrich/contact")} className="btn btn-primary">
                    <FormattedMessage id="menu.go_to_dashboard" defaultMessage="Dashboard" />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
});