import React, { Component } from "react";
import { forEach as _forEach, get as _get, map as _map, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { matchPath } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";

import { withRouter } from "../../../components/hoc/withRouter";
import { showAlertMessage } from "../controls";
import config from "../../../helpers/config";

import { IconCoin, IconDashboard, IconMagic, IconUser, IconCompany, IconSearch, IconLog, IconApi, IconApiKey, IconDocument, IconTeam, IconInvoice, IconSetting } from "../../../helpers/iconStyles";
import { appRoutes } from "../../../helpers/constants";
import { ucfirst } from "../../../helpers/utils";

import { fetchCreditDetails } from "../../../actions/application";

const iconList = {
  iconCoin: IconCoin,
  iconDashboard: IconDashboard,
  iconMagic: IconMagic,
  iconUser: IconUser,
  iconCompany: IconCompany,
  iconSearch: IconSearch,
  iconLog: IconLog,
  iconApi: IconApi,
  iconApiKey: IconApiKey,
  iconDocument: IconDocument,
  iconTeam: IconTeam,
  iconInvoice: IconInvoice,
  iconSetting: IconSetting
};

class Sidebar extends Component {

  static defaultProps = {
    isPublic: false
  };

  constructor(props) {
    super(props);

    this.state = {
      activeMenu: "query",
      activeSubMenu: "advance_search",
      showConfirmationBox: false,
      redirectRoutePath: "",
      creditDetails: {}
    };

  }

  componentDidMount() {
    const { creditDetails, fetchCreditDetails } = this.props;

    if (typeof fetchCreditDetails === "function") { fetchCreditDetails(); }

    this._setActiveMenu();

    this.setState({ creditDetails: _get(creditDetails, "data", []) });
  }

  componentDidUpdate(prevProps) {

    if (!_isEqual(_get(prevProps, "location.pathname", "/"), _get(this.props, "location.pathname", "/"))) {
      this._setActiveMenu();
    }

    if (!_isEqual(prevProps.creditDetails, this.props.creditDetails) && !_isEmpty(this.props.creditDetails)) {
      const { creditDetails } = this.props;

      if ((creditDetails.status || null) === null) { return false; }

      if ((creditDetails.status || false) === true) {

        this.setState({ creditDetails: _get(creditDetails, "data", []) });
      } else {
        showAlertMessage(creditDetails.message || "Something went wrong while fetching credit details.");
      }
    }
  }

  // Set the active menu based on current route of url || passed from props state
  _setActiveMenu() {
    const { location } = this.props;

    let activeMenu = "";
    let activeSubMenu = "";
    const route = _get(location, "pathname", "/");

    // Check if history has active menu state
    const menuState = _get(location, "state.activeMenu", {});

    if (Object.keys(menuState).length > 0) {
      activeMenu = (menuState.group || "");
      activeSubMenu = (menuState.name || "");
    } else {
      // Match with current url route
      _forEach(appRoutes, (r) => {
        const path = (r.path || "");
        const exact = (r.exact || null);
        const matched = matchPath({ path, exact, strict: false }, route);

        // Break the loop and get the menu
        if (matched) {
          activeMenu = _get(r, "activeMenu.group", "");
          activeSubMenu = _get(r, "activeMenu.name", "");
          return false;
        }
      });
    }

    this.setState({ activeMenu, activeSubMenu });
  }

  // Open parent menu
  _handleActiveParentMenu(e, mainMenu) {
    e.preventDefault();
    const { activeMenu } = this.state;
    const setActiveMenu = ((mainMenu || "") === (activeMenu || "")) ? "" : (mainMenu || "");

    this.setState({ activeMenu: setActiveMenu });
  }

  _redirectToPage = (routePath = "/") => {
    const { navigate } = this.props;

    if (typeof navigate === "function") { navigate(routePath); }
  };

  render() {
    const { menuConfig } = this.props;
    const { activeMenu, activeSubMenu } = this.state;

    return (
      <nav id="navbarSidebar" className="d-flex flex-shrink-0 flex-column sidebar-admin">
        <span className="navbar-brand px-12 py-6 mb-7" onClick={() => window.location = config.getFrontendURL(true, true)}>
          <img src="images/logo.svg" className="img-fluid d-block" alt="Enrich B2B" />
        </span>

        {/*<div className="mx-15 mb-35 credits-total border border-primary rounded-pill py-5 px-10 text-center">
            <IconCoin width="23px" height="23px" color="#12c7ae" />
            <span>
              <FormattedMessage
                id="generic_page.credit_details"
                defaultMessage="{remainCredits} / {totalCredits}"
                values={{ remainCredits: _get(creditDetails, "remaining_credits", 0), totalCredits: _get(creditDetails, "total_credits", 0) }}
              />
            </span>
           </div>*/}

        <ul className="nav flex-column">
          {_map(menuConfig, (conf, i) => {
            if (_isEmpty(conf)) { return null; }

            const IconComponent = iconList[(conf.icon || "iconCoin")];

            if (_get(conf, "containSubMenu", false) === true) {
              const mainMenu = (
                <Nav.Link className={`btn-toggle cursor-pointer ${(i !== activeMenu) ? "collapsed" : "active"}`} key={i} to={"/"} onClick={(e) => this._handleActiveParentMenu(e, i)}>
                  <span className="nav-icon"> <IconComponent width={`${(conf.width || "")}`} height={`${(conf.height || "")}`} /> </span>
                  <span className="nav-text"><FormattedMessage id={`menu.admin.${(conf.menuLanguageKey || "")}`} defaultMessage={ucfirst(i)} /></span>
                </Nav.Link>
              );

              const childMenu = [];
              _map(conf.subMenus, (a, k) => {

                childMenu.push((
                  <li
                    key={k}
                    className="nav-item text-capitalize"
                    onClick={() => (a.name === "api_docs") ? window.open(`${(process.env.REACT_APP_API_DOC_LINK || "")}`, "_blank") : this._redirectToPage(`/${(a.route || "")}`)}
                  >
                    <Nav.Link key={k} className={`nav-link ${(a.name === activeSubMenu) ? "active-child" : ""}`}><FormattedMessage id={`menu.admin.${(a.menuLanguageKey || "")}`} defaultMessage={ucfirst((a.menuLanguageKey || ""))} /></Nav.Link>
                  </li>
                ));
              });

              return (
                <Nav.Item as="li" key={i}>
                  {mainMenu}
                  <div className={`collapse ${(i === activeMenu) ? "show" : ""}`}>
                    <ul className="list-unstyled">
                      {childMenu}
                    </ul>
                  </div>
                </Nav.Item>
              );
            } else {
              let routeUrl = `/${(conf.route || "")}`;

              return (
                <li className="nav-item" key={i}>
                  <div key={i} className={(`nav-link cursor-pointer text-capitalize ${((conf.group || "") === activeMenu) ? "active" : ""}`)} onClick={() => this._redirectToPage(routeUrl)}>
                    <span className="nav-icon"> <IconComponent width={`${(conf.width || "")}`} height={`${(conf.height || "")}`} /> </span>
                    <span className="nav-text"><FormattedMessage id={`menu.admin.${(conf.menuLanguageKey || "")}`} defaultMessage={ucfirst(conf.menuLanguageKey || "")} /></span>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      </nav >
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: _get(state, "oauth.user", {}),
  menuConfig: _get(state, "application.config.menu", {}),
  creditDetails: _get(state, "application.creditDetails", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCreditDetails: () => dispatch(fetchCreditDetails()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Sidebar)));
