import { isEmpty as _isEmpty } from "lodash";
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";

export const encryptData = (arr, isArray = true) => {
  if (_isEmpty(arr)) { return null; }

  if (isArray) {
    return AES.encrypt(JSON.stringify(arr), (process.env.REACT_APP_ENC_KEY || "safG23chgDvkktfs7bwtcd4ghRgde")).toString();
  } else {
    return AES.encrypt(arr, (process.env.REACT_APP_ENC_KEY || "safG23chgDvkktfs7bwtcd4ghRgde")).toString();
  }
};

export const decryptData = (arr, isArray = true) => {
  if (_isEmpty(arr)) { return null; }

  if (isArray) {
    var bytes = AES.decrypt(arr, (process.env.REACT_APP_ENC_KEY || "safG23chgDvkktfs7bwtcd4ghRgde"));
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return AES.decrypt(arr, (process.env.REACT_APP_ENC_KEY || "safG23chgDvkktfs7bwtcd4ghRgde")).toString(CryptoJS.enc.Utf8);
  }
};

export default {
  encryptData,
};
