export const UPDATE_APPLICATION_LOADING = "UPDATE_APPLICATION_LOADING";

export const updateLoadingState = (loading) => {
  return ({
    type: UPDATE_APPLICATION_LOADING,
    loading
  });
}

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const updateLanguage = (language) => {
  return ({
    type: UPDATE_LANGUAGE,
    language
  });
}

export const FETCH_APPLICATION_REQUEST = "FETCH_APPLICATION_REQUEST";
export const FETCH_APPLICATION_SUCCESS = "FETCH_APPLICATION_SUCCESS";

export const fetchApplication = () => {
  return ({
    type: FETCH_APPLICATION_REQUEST,
  });
}

export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";

export const setActiveMenuRequest = (payload) => {
  return ({
    type: SET_ACTIVE_MENU,
    payload
  });
}

export const SET_ADMIN_MENU_COLLAPSE = "SET_ADMIN_MENU_COLLAPSE";
export const setAdminMenuCollapse = (payload) => {
  return ({ type: SET_ADMIN_MENU_COLLAPSE, payload });
}

export const FETCH_USER_SETTINGS_REQUEST = "FETCH_USER_SETTINGS_REQUEST";
export const FETCH_USER_SETTINGS_SUCCESS = "FETCH_USER_SETTINGS_SUCCESS";
export const FETCH_USER_SETTINGS_ERROR = "FETCH_USER_SETTINGS_ERROR";

export const fetchUserSettings = () => {
  return ({
    type: FETCH_USER_SETTINGS_REQUEST
  });
}

export const FETCH_PRICE_LIST_REQUEST = "FETCH_PRICE_LIST_REQUEST";
export const FETCH_PRICE_LIST_SUCCESS = "FETCH_PRICE_LIST_SUCCESS";
export const FETCH_PRICE_LIST_ERROR = "FETCH_PRICE_LIST_ERROR";

export const fetchPriceList = () => {
  return ({
    type: FETCH_PRICE_LIST_REQUEST
  });
}

export const UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR";

export const updateUserDetails = (payload) => {
  return ({
    type: UPDATE_USER_DETAILS_REQUEST,
    payload
  });
}

export const CLEAR_UPDATE_USER_DETAILS_DATA = "CLEAR_UPDATE_USER_DETAILS_DATA";

export const clearUpdateUserDetailsData = () => {
  return ({
    type: CLEAR_UPDATE_USER_DETAILS_DATA
  });
}

export const FETCH_CREDIT_DETAILS_REQUEST = "FETCH_CREDIT_DETAILS_REQUEST";
export const FETCH_CREDIT_DETAILS_SUCCESS = "FETCH_CREDIT_DETAILS_SUCCESS";
export const FETCH_CREDIT_DETAILS_ERROR = "FETCH_CREDIT_DETAILS_ERROR";

export const fetchCreditDetails = () => {
  return ({
    type: FETCH_CREDIT_DETAILS_REQUEST
  });
}

export const CREATE_BOOK_A_CALL_REQUEST = "CREATE_BOOK_A_CALL_REQUEST";
export const CREATE_BOOK_A_CALL_SUCCESS = "CREATE_BOOK_A_CALL_SUCCESS";
export const CREATE_BOOK_A_CALL_ERROR = "CREATE_BOOK_A_CALL_ERROR";

export const createBookACall = (data) => {
  return ({
    type: CREATE_BOOK_A_CALL_REQUEST,
    data
  });
}

export const CLEAR_CREATE_BOOK_A_CALL_RESPONSE = "CLEAR_CREATE_BOOK_A_CALL_RESPONSE";

export const clearCreateBookACallResponse = () => {
  return ({
    type: CLEAR_CREATE_BOOK_A_CALL_RESPONSE
  });
}