import { get as _get } from "lodash";
import api from "../helpers/api";

export const getUserList = (variables) => {
  return api().options("/Admin/users", { data: variables }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching user list."),
      data: {}
    };
  });
}

export const createUser = (variables) => {
  return api().post("/admin/user", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "message", ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while adding new user."),
      data: {}
    };
  });
}

export const getUserDetailsById = (variables) => {
  return api().options("/admin/user/details", { data: variables }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching user details."),
      data: {}
    };
  });
}

export const editUserDetails = (variables) => {
  return api().put("/admin/user/details", (variables || {})).then((res) => {
    return {
      flag: true,
      message: _get(res, "message", ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while updating user details."),
      data: {}
    };
  });
}

export const deleteUser = (userId) => {
  return api().delete(`/admin/user/${(userId || null)}`, { data: userId }).then((res) => {

    return {
      flag: true,
      message: _get(res, "message", ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while deleting selected user."),
      data: {}
    };
  });
}

export const lockUnlockUser = (variables) => {
  const userId = (variables.userId || 0);
  const isunLocked = (variables.isunLocked || false);

  return api().patch(`/admin/user/${userId}/lock_unlock/${isunLocked}`, {}).then((res) => {
    return {
      flag: _get(res, "data.status", false),
      message: _get(res, "data.message", ""),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", false),
      message: _get(err, "response.data.message", "Something went wrong while lock/unlock selected user."),
      data: {},
    };
  });
};

export const resetUserPassword = (variables) => {
  const userId = (variables.userId || 0);

  return api().patch(`/admin/user/${userId}/password/reset`, variables).then((res) => {
    return {
      flag: _get(res, "data.status", false),
      message: _get(res, "data.message", ""),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", false),
      message: _get(err, "response.data.message", "Something went wrong while reseting user password."),
      data: {},
    };
  });
};

export const addManualCredits = (variables) => {
  return api().options("/admin/add_client_credits", { data: variables }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while adding credits."),
      data: {}
    };
  });
}