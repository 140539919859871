import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";

import Root from "./Root";
import "./styles/css/main.css";

const rootEl = document.getElementById("root");

const root = createRoot(rootEl);
root.render(<Root />);

const gaId = (process.env.REACT_APP_ANALYTIC_TRACK_ID || "");

if (gaId) {
  ReactGA.initialize(gaId);
  ReactGA.send("pageview");
}

// hot reloading.
if (module.hot) {
  module.hot.accept("./Root", () => {
    const NextApp = require("./Root").default;

    // const rootEle = document.getElementById("root");

    // const rootHot = createRoot(rootEle);
    root.render(<NextApp />);
  });
}