import { takeLatest, call, fork, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import { getTopTilesData, getLeadsVerifiedChart, getCreditConsumptions } from "../services/dashboard";

import { 
  FETCH_TOP_TILES_DATA_REQUEST, FETCH_TOP_TILES_DATA_SUCCESS, FETCH_TOP_TILES_DATA_ERROR,
  FETCH_LEADS_VERIFIED_CHART_REQUEST, FETCH_LEADS_VERIFIED_CHART_SUCCESS, FETCH_LEADS_VERIFIED_CHART_ERROR,
  FETCH_CREDIT_CONSUMPTIONS_REQUEST, FETCH_CREDIT_CONSUMPTIONS_SUCCESS, FETCH_CREDIT_CONSUMPTIONS_ERROR
 } from "../actions/dashboard";
 import { UPDATE_APPLICATION_LOADING } from "../actions/application";

 function* workerFetchTopTilesData({ payload }) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getTopTilesData, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_TOP_TILES_DATA_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_TOP_TILES_DATA_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching dashboard details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_LEADS_VERIFIED_CHART_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching dashboard details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

 function* workerFetchLeadsVerifiedChart({ payload }) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getLeadsVerifiedChart, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_LEADS_VERIFIED_CHART_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_LEADS_VERIFIED_CHART_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching Searched vs. Matched details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_LEADS_VERIFIED_CHART_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching Searched vs. Matched details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchCreditConsumptionsChart({ payload }) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getCreditConsumptions, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_CREDIT_CONSUMPTIONS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_CREDIT_CONSUMPTIONS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching Credit Available vs. Consumption details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_CREDIT_CONSUMPTIONS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching Credit Available vs. Consumption details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherFetchTopTilesData() {
  yield takeLatest(FETCH_TOP_TILES_DATA_REQUEST, workerFetchTopTilesData);
}

function* watcherFetchLeadsVerifiedChart() {
  yield takeLatest(FETCH_LEADS_VERIFIED_CHART_REQUEST, workerFetchLeadsVerifiedChart);
}

function* watcherFetchCreditConsumptionsChart() {
  yield takeLatest(FETCH_CREDIT_CONSUMPTIONS_REQUEST, workerFetchCreditConsumptionsChart);
}

export default [
  fork(watcherFetchTopTilesData),
  fork(watcherFetchLeadsVerifiedChart),
  fork(watcherFetchCreditConsumptionsChart),
];
