import { ErrorMessage as ErrorMessageTmp, showContentLoader as showContentLoaderTmp } from "./ErrorMessage";
import { EmptyLabel as EmptyLabelTmp,  NoDataMessage as NoDataMessageTmp } from "./EmptyLabel";
import { showAlertMessage as showAlertTmp } from "./AlertMessage";
import { ReactModal as ReactModalTmp } from "./ReactModal";
import { CustomModal as ModalTmp } from "./CustomModal";
import { Textarea as TextareaTmp } from "./Textarea";
import TableHeaderRendererTmp from "./TableHeaderRenderer";
import TableCellRendererTmp from "./TableCellRenderer";
import SingleDropzoneTmp from "./SingleDropzone";
// import { Input as InputTmp } from "./Input";

import DateRangePickerTmp from "./DateRangePicker";
import ConfirmBoxTmp from "./ConfirmBox";
import ReactTableTmp from "./ReactTable";
import HighChartTmp from "./HighChart";
import SelectTmp from "./Select";

export const TableHeaderRenderer = TableHeaderRendererTmp;
export const TableCellRenderer = TableCellRendererTmp;
export const ShowContentLoader = showContentLoaderTmp;
export const DateRangePicker = DateRangePickerTmp;
export const SingleDropzone = SingleDropzoneTmp;
export const NoDataMessage = NoDataMessageTmp;
export const showAlertMessage = showAlertTmp;
export const ErrorMessage = ErrorMessageTmp;
export const ReactModal = ReactModalTmp;
export const EmptyLabel = EmptyLabelTmp;
export const ConfirmBox = ConfirmBoxTmp;
export const ReactTable = ReactTableTmp;
export const HighChart = HighChartTmp;
export const Textarea = TextareaTmp;
export const Select = SelectTmp;
// export const Input = InputTmp;
export const Modal = ModalTmp;
