import { takeLatest, call, fork, select, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import { getApplication, getUserSetting, getPriceList, updateUserDetails, getCreditDetails, createBookACall } from "../services/application";

import {
  UPDATE_APPLICATION_LOADING, FETCH_APPLICATION_REQUEST, FETCH_APPLICATION_SUCCESS,
  FETCH_USER_SETTINGS_REQUEST, FETCH_USER_SETTINGS_SUCCESS, FETCH_USER_SETTINGS_ERROR,
  UPDATE_USER_DETAILS_REQUEST, UPDATE_USER_DETAILS_SUCCESS, UPDATE_USER_DETAILS_ERROR,
  FETCH_PRICE_LIST_REQUEST, FETCH_PRICE_LIST_SUCCESS, FETCH_PRICE_LIST_ERROR,
  FETCH_CREDIT_DETAILS_REQUEST, FETCH_CREDIT_DETAILS_SUCCESS, FETCH_CREDIT_DETAILS_ERROR,
  CREATE_BOOK_A_CALL_REQUEST, CREATE_BOOK_A_CALL_SUCCESS, CREATE_BOOK_A_CALL_ERROR
} from "../actions/application";

function* workerFetchApplication(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const uri = yield select((state) => _get(state, "oauth.uri", ""));

    const response = yield call(getApplication, uri);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_APPLICATION_SUCCESS,
        payload: (response.data || {})
      });
    }

  } catch (error) {

  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerUserSettingData() {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getUserSetting);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_USER_SETTINGS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_USER_SETTINGS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching user settings.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_USER_SETTINGS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching user settings.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchPriceList() {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getPriceList);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_PRICE_LIST_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_PRICE_LIST_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching Price list.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_PRICE_LIST_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching Price list.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerUpdateUserDetails({ payload }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(updateUserDetails, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: UPDATE_USER_DETAILS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: UPDATE_USER_DETAILS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while updating user details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: UPDATE_USER_DETAILS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while updating user details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchCreditDetails() {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getCreditDetails);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_CREDIT_DETAILS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_CREDIT_DETAILS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching credit details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_CREDIT_DETAILS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching credit details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerCreateBookACall(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(createBookACall, _get(action, "data", {}));

    if ((response.flag || false) === true) {
      yield put({
        type: CREATE_BOOK_A_CALL_SUCCESS,
        data: (response.flag || false),
        message: {
          title: _get(response, "title", "Success"),
          message: (response.message || "Your request has been submitted successfully.")
        },
      });
    } else {
      yield put({
        type: CREATE_BOOK_A_CALL_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while submitting book a call request."),
        },
      });
    }

  } catch (error) {
    yield put({
      type: CREATE_BOOK_A_CALL_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong while submitting book a call request."),
      },
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherFetchApplication() {
  yield takeLatest(FETCH_APPLICATION_REQUEST, workerFetchApplication);
}

function* watcherUserSettingData() {
  yield takeLatest(FETCH_USER_SETTINGS_REQUEST, workerUserSettingData);
}

function* watcherFetchPriceList() {
  yield takeLatest(FETCH_PRICE_LIST_REQUEST, workerFetchPriceList);
}

function* watcherUpdateUserDetails() {
  yield takeLatest(UPDATE_USER_DETAILS_REQUEST, workerUpdateUserDetails);
}

function* watcherFetchCreditDetails() {
  yield takeLatest(FETCH_CREDIT_DETAILS_REQUEST, workerFetchCreditDetails);
}

function* watcherCreateBookACall() {
  yield takeLatest(CREATE_BOOK_A_CALL_REQUEST, workerCreateBookACall);
}

export default [
  fork(watcherFetchCreditDetails),
  fork(watcherUpdateUserDetails),
  fork(watcherFetchApplication),
  fork(watcherUserSettingData),
  fork(watcherFetchPriceList),
  fork(watcherCreateBookACall),
];
