import React from "react";
import { toast, Slide } from "react-toastify";
import { isEmpty as _isEmpty } from "lodash";
import parse from "html-react-parser";

const defaultConfig = {
  transition: Slide,
  position: "top-right",
  autoClose: 10000,
  hideProgressBar: false,
  closeButton: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false
};

export const showAlertMessage = (message = "", type = "error", configuration = {}) => {
  if (_isEmpty(message) || _isEmpty(type)) { return; }

  return toast((typeof message === "string") ? <div>{parse(message)}</div> : message, {
    type,
    ...defaultConfig,
    ...configuration,
  });
};