import React from "react";
import { omit as _omit, get as _get } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { useTable, useSortBy } from "react-table";
import { IconCaretDown, IconCaretUp } from "../../../helpers/iconStyles";


function Table({ columns, data, ...restProps }) {
  const defaultColumn = React.useMemo(() => ({ minWidth: 30, width: 150, maxWidth: 400 }), []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data, defaultColumn }, useSortBy);

  const restProp = _omit(restProps, ["intl"]);

  return (
    <div className="table-responsive max-h-500 vh-100" {...restProp}>
      <table {...getTableProps()} className="table table-bordered">
        <thead>
          {(headerGroups || []).map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} className="tr">
              {(headerGroup.headers || []).map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="position-relative th">
                  {column.render("Header")}
                  <span className="position-absolute top-50 end-0 translate-middle-y pe-2">
                    {(column.isSorted) ? (column.isSortedDesc) ? <IconCaretDown width='16px' height='16px' color='#3b3b3b' /> : <IconCaretUp width='16px' height='16px' color='#3b3b3b' /> : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(rows.length === 0) && (
            <tr className="text-center tr">
              <td className="text-capitalize td" colSpan={_get(headerGroups, "[0]headers", []).length}>
                <FormattedMessage id="message.no_found_data" defaultMessage="No data found." />
              </td>
            </tr>
          )}
          {(rows.length > 0) && (rows || []).map(
            (row, i) => {

              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="tr">
                  {(row.cells || []).map(cell => {

                    return (
                      <td {...cell.getCellProps({ style: { maxWidth: cell.column.maxWidth } })} className="td">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  )
}

const ReactTable = (props) => {
  const { tableData, columns, ...restProps } = props;

  const tableCol = React.useMemo(() => columns);
  const data = React.useMemo(() => tableData)

  return (
    <Table columns={tableCol} data={data} {...restProps} />
  );
}

export default injectIntl(ReactTable);
