import React, { Component } from "react";
import addNoDataModule from "highcharts/modules/no-data-to-display";
import HighchartsReact from "highcharts-react-official";
import MapsHighcharts from "highcharts/highmaps";
import Highcharts from "highcharts";
import PropTypes from "prop-types";

addNoDataModule(Highcharts);

class HighChart extends Component {

	static defaultProps = {
		type: "chart",
		options: {},
		enableExport: false,
		enableDrillDown: false,
	};

	constructor(props) {
		super(props);

		if ((props.enableExport || false)) {
			require("highcharts/modules/exporting")(Highcharts);
		}

		if ((props.enableDrillDown || false)) {
			require("highcharts/modules/drilldown")(Highcharts);
		}
	}

	render() {
		const { type, options, getChartRef } = this.props;
		let chartInstance = Highcharts;

		if (type === "mapChart") {
			chartInstance = MapsHighcharts;
		}

		return (
			<HighchartsReact
				constructorType={(type || "chart")}
				highcharts={chartInstance}
				options={(options || {})}
				callback={(chart) => {
					if (typeof getChartRef === "function") { getChartRef(chart); }
				}}
			/>
		);
	}
}

HighChart.propTypes = {
	type: PropTypes.string,
	options: PropTypes.object,
};

export default HighChart;
