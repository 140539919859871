import { get as _get } from "lodash";
import api from "../helpers/api";

export const getTopTilesData = (variables) => {
  return api().options("/dashboard/lead_numbers", { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching dashboard details."),
      data: {}
    };
  });
}

export const getLeadsVerifiedChart = (variables) => {
  return api().options("/dashboard/lead_chart", { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching Searched vs. Matched details."),
      data: {}
    };
  });
}

export const getCreditConsumptions = (variables) => {
  return api().options("/dashboard/credit_consumption_chart", { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching Credit Available vs. Consumption details."),
      data: {}
    };
  });
}

export const getClientList = () => {
  return api().get("/Admin/clients").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching client list."),
      data: {}
    };
  });
}

export const getClientUserList = (variables) => {
  return api().options("/Admin/users", { data: variables }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching user list."),
      data: {}
    };
  });
}