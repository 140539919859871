export default {
	url: {
		frontend: `#{account}.${process.env.REACT_APP_FRONTEND_DOMAIN}`,
		admin: `#{account}.${process.env.REACT_APP_FRONTEND_DOMAIN}`,
		backend: `#{account}.${process.env.REACT_APP_BACKEND_DOMAIN}`
	},
	getFrontendURL(slashes = false, protocol = false, endpoint = "", port = true) {

		let url = (protocol === true) ? process.env.REACT_APP_FRONTEND_HTTP_PROTOCOL : "";
		url += slashes ? "//" : "";
		url += this.url.frontend.replace("#{account}", process.env.REACT_APP_FRONTEND_SUBDOMAIN);
		url += process.env.REACT_APP_FRONTEND_DOMAIN_PORT;
		url += endpoint;

		return url;
	},
	getAdminURL(slashes = false, protocol = false, endpoint = "", port = true) {

		let url = (protocol === true) ? process.env.REACT_APP_ADMIN_HTTP_PROTOCOL : "";
		url += slashes ? "//" : "";
		url += this.url.admin.replace("#{account}", process.env.REACT_APP_ADMIN_SUBDOMAIN);
		url += process.env.REACT_APP_FRONTEND_DOMAIN_PORT;
		url += endpoint;

		return url;
	},
	getBackendURL(slashes = false, protocol = false, endpoint = "") {

		let url = (protocol === true) ? process.env.REACT_APP_BACKEND_HTTP_PROTOCOL : "";
		url += slashes ? "//" : "";

		url += this.url.backend.replace("#{account}", (process.env.REACT_APP_BACKEND_SUBDOMAIN || ""));

		url += endpoint;

		return url;
	},
	store_name: process.env.REACT_APP_REDUX_STORE || "redux-store",
};
