import React from "react";
import { NavLink, /* Link*/ } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import moment from "moment";

// import { IconLinkedin } from "../../../helpers/iconStyles";

export default React.memo(function Footer(props) {

  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg">
            <div className="footer-copyright text-center">
              <p>
                <FormattedMessage
                  id="footer.copyright_text"
                  defaultMessage="Copyright © {year} Webstem LTD. All rights reserved | "
                  values={{ year: moment().year() }}
                />
                <NavLink to="/terms-and-conditions">
                  <FormattedMessage id="footer.terms_and_conditions" defaultMessage="Terms & Conditions" />
                </NavLink>
              </p>
            </div>
          </div>
          {/* <div className="col-lg-auto">
            <div className="footer-social">
              <Link to="#">
                <IconLinkedin width="23px" height="23px" color="#12C7AE" />
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
});