import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Collapse } from "react-bootstrap";
import Helmet from "react-helmet";

import { IconClose } from "../../helpers/iconStyles";

class MyTeam extends Component {

  constructor(props) {
    super(props);

    this.state = {
      collapseMenu: true
    };

    this.showHide = this.showHide.bind(this);
  }

  showHide(e) {
    e.preventDefault();

    this.setState({
      collapseMenu: !this.state.collapseMenu
    });
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.my_team", defaultMessage: "My Team: Enrich B2B" })} </title>
        </Helmet>

        <div>
          <div className="section-heading mb-12">
            <h1 className="heading-04 fw-medium">
              <FormattedMessage id="my_team.title" defaultMessage="My Team" />
            </h1>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <form autoComplete="off">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-18">
                      <input type="text" className="form-control" name="companyname" placeholder="Company" defaultValue="High Digital" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row ps-lg-45 s-lg-15">
                      <div className="col-lg-12">
                        <div className="row gx-18 align-items-center">
                          <div className="col-lg-3">
                            <div className="mb-18">
                              <input type="text" className="form-control" name="firstname" placeholder="First Name" defaultValue="Jason" />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="mb-18">
                              <input type="text" className="form-control" name="lastname" placeholder="Last Name" defaultValue="Netwon" />
                            </div>
                          </div>
                          <div className="col-lg-5 col-10">
                            <div className="mb-18">
                              <input type="text" className="form-control" name="email" placeholder="Email" defaultValue="Jason@highdigital.co.uk" />
                            </div>
                          </div>
                          <div className="col-lg-1 col-2">
                            <div className="mb-18">
                              <span> <IconClose width="24px" height="24px" color="#9d9b9b" /> </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <button type="button" className="btn btn-sm btn-primary text-uppercase">
                              <FormattedMessage id="btn.add_member" defaultMessage="ADD MEMBER" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-18">
                      <div className="col-lg-12">
                        <button type="button" className="btn btn-sm btn-primary text-uppercase" onClick={this.showHide} aria-expanded="false">Add New</button>

                        <Collapse in={!this.state.collapseMenu}>
                          <div className="row mt-18">
                            <div className="col-lg-3">
                              <div className="mb-18">
                                <input type="text" className="form-control" name="firstname" placeholder="First Name" />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-18">
                                <input type="text" className="form-control" name="lastname" placeholder="Last Name" />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-18">
                                <input type="email" className="form-control" name="emailid" placeholder="Email" />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-18">
                                <input type="password" className="form-control" name="password" placeholder="Password" />
                              </div>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(MyTeam);