import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { showAlertMessage, Modal } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { addManualCredits } from "../../../services/userManagement";

import { updateLoadingState } from "../../../actions/application";

class AddManualCredits extends Component {

  constructor(props) {
    super(props);

    this.state = {
      credits: 0
    };
  }

  _closeModal = () => {
    const { setState } = this.props;

    if (typeof setState === "function") {
      setState({ showCreditModal: false, selRecord: {} });
    }

    this.setState({ credits: 0 });
  }

  _handleAddCredits = async () => {
    const { updateLoadingState, selRecord, setState } = this.props;
    const { credits } = this.state;

    if (isNaN(credits) || (credits <= 0)) {
      showAlertMessage("Please add a valid number.");
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await addManualCredits({ client_id: _get(selRecord, "client_id", 0), credits: credits });

      if (_get(response, "flag", false) === true) {
        showAlertMessage(_get(response, "message", "Credits added successfully."), "success");

        if (typeof setState === "function") { setState({ selRecord: {}, showCreditModal: false, reSyncTableData: true }); }

        this.setState({ credits: 0 });

      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while adding credits."));
      }
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching adding credits.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderAddCredits = () => {
    const { showCreditModal, intl } = this.props;
    const { credits } = this.state;

    return (
      <Modal
        size="md"
        centered={true}
        footerClassName=""
        showCloseButton={true}
        isOpen={(showCreditModal || false)}
        modalTitle={intl.formatMessage({ id: "user_management.add_credits.title", defaultMessage: "Add credits" })}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
        onSubmit={() => this._handleAddCredits()}
        closeBtnText={intl.formatMessage({ id: "btn.close", defaultMessage: "close" })}
        submitBtnText={intl.formatMessage({ id: "btn.submit", defaultMessage: "submit" })}
      >
        <div>
          <Form autoComplete="off">
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-16">
                  <input
                    type="number"
                    name="credits"
                    placeholder="Credits"
                    value={credits}
                    className="form-control"
                    onChange={(e) => {
                      const point = _get(e, "target.value", "");
                      this.setState({ credits: (point !== "") ? parseInt(point) : point })
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }

  render() {
    const { showCreditModal } = this.props;

    return (
      <>
        {(showCreditModal === true) && this._renderAddCredits()}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default withRouter(connect(null, mapDispatchToProps)(injectIntl(AddManualCredits)));