export default {
  //Site
  "enrich.logo_small_title": "E",
  "enrich.logo_big_title": "Enrich B2B",

  // Page Titles
  "enrich.home": "Enrich B2B: Real-Time linkedin lookup tool that validates B2B Data",
  "enrich.login": "Login: Enrich B2B",
  "enrich.sign_up": "Sign Up: Enrich B2B",
  "enrich.book_a_call": "Book a Call: Enrich B2B",
  "enrich.pricing": "Pricing: Enrich B2B",
  "enrich.faq": "FAQs: Enrich B2B",
  "enrich.terms_and_conditions": "Terms & Conditions: Enrich B2B",
  "enrich.contact": "Contact: Enrich B2B",
  "enrich.forgot_password": "Forgot Password: Enrich B2B",
  "enrich.reset_password": "Reset Password: Enrich B2B",
  "enrich.generic_page.comming_soon": "Comming soon: Enrich B2B",
  "enrich.generic_page.under_maintenance": "Under Maintenance: Enrich B2B",
  "enrich.generic_page.not_found": "Page not found: Enrich B2B",
  "enrich.dashboard": "Dashboard: Enrich B2B",
  "enrich.enrich_contact": "Contact: Enrich B2B",
  "enrich.enrich_company": "Company: Enrich B2B",
  "enrich.enrich_company_employees": "Company Employees: Enrich B2B",
  "enrich.searches": "Searches: Enrich B2B",
  "enrich.logs_analytics": "Logs & Analytics: Enrich B2B",
  "enrich.api_keys": "API Keys: Enrich B2B",
  "enrich.api_docs": "API Docs: Enrich B2B",
  "enrich.my_team": "My Team: Enrich B2B",
  "enrich.my_invoice": "My Invoice: Enrich B2B",
  "enrich.settings": "Settings: Enrich B2B",
  "enrich.add_credits": "Add Credits: Enrich B2B",
  "enrich.user_management": "User Management: Enrich B2B",

  "email": "email",
  "password": "password",
  "first_name": "First Name",
  "last_name": "Last Name",
  "name": "name",
  "team": "team",
  "company_name": "Company Name",
  "old_password": "old password",
  "new_password": "new password",
  "confirm_password": "confirm password",
  "change_password": "change password",
  "logout": "logout",
  "or": "or",
  "success": "success",
  "message": "message",
  "phone_number": "phone number",
  "file": "file",
  "fields": "fields",
  "filter": "filter",
  "empty": "empty",
  "to": "to",
  "here": "here",
  "type": "Type",
  "method": "Method",
  "role": "Role",
  "client": "Client",
  "user": "User",
  "users": "Users",
  "status": "Status",
  "dropdown.dots": "•••",
  "recipient": "Recipient",

  // Common labels
  "label.select_field": "select {field}",
  "label.yes": "yes",
  "label.no": "no",

  // Modal
  "modal.confirm": "confirm",
  "modal.alert": "alert !",

  //Menu
  "menu.home": "home",
  "menu.pricing": "pricing",
  "menu.faqs": "FAQs",
  "menu.contact": "contact",
  "menu.login": "login",
  "menu.sign_up": "Sign Up",
  "menu.go_to_dashboard": "Dashboard",

  // Admin Menu
  "menu.admin.dashboard": "Dashboard",
  "menu.admin.enrich": "Enrich",
  "menu.admin.enrich_contact": "Contact",
  "menu.admin.enrich_company": "Company",
  "menu.admin.enrich_company_employees": "Company Employees",
  "menu.admin.searches": "Searches",
  "menu.admin.logs_analytics": "Logs & Analytics",
  "menu.admin.api": "API",
  "menu.admin.api_keys": "API Keys",
  "menu.admin.api_docs": "API Docs",
  "menu.admin.my_team": "My Team",
  "menu.admin.my_invoice": "My Invoice",
  "menu.admin.settings": "Settings",
  "menu.admin.user_management": "User Management",

  //Footer
  "footer.copyright_text": "Copyright © {year} Webstem LTD. All rights reserved | ",
  "footer.terms_and_conditions": "Terms & Conditions",
  "footer.admin.copyright_text": "Copyright © {year} Webstem LTD. All rights reserved | ",
  "footer.admin.terms_and_conditions": "Terms & Conditions",

  // error validation messages
  "error.required": "{field} is required.",
  "error.are_required": "{field} are required.",
  "error.select": "Please select {field}.",
  "error.select_valid": "Please select valid {field}.",
  "error.select_atleast_one": "Please select at least one {field}.",
  "error.enter": "Please enter {field}.",
  "error.valid": "Please enter valid {field}.",
  "error.invalid": "Invalid {field}.",
  "error.password_not_match": "Confirm password does not match.",
  "error.password_min_char": "Password should contains minimum 8 characters.",
  "error.password_valid_char": "Password should contain at least 3 of a-z or A-Z and a number and a special character.",
  "error.exact_number_field": "Please enter {value} number {field}.",
  "error.less_than_value": "{field} should be less than {value}.",
  "error.atleast_value": "{field} should be atleast {value}.",
  "error.something_error": "There is some error inside the fields.",
  "error.less_than_value_character": "{field} should be less than {value} characters.",
  "error.after_start_date": "End date field should be after start date",
  "error.after_start_time": "End time should be after start time",
  "error.after_today": "End date should be today or future date.",
  "error.start_after_today": "Start date should be today or future date.",
  "error.date_after_today": "Date should be today or future date.",
  "error.greatereq_than_value": "{field} should be greater than or equal to {value}.",
  "error.image_upload": "Image not uploaded successfully",
  "error.after_date": "{field} should be greater than or equal to current date",
  "error.any_one_option_required": "Anyone option should be enable for {field}",
  "error.not_matched_with_val": "{field} not matched with {value}",
  "error.please_upload_file": "Please upload a file.",
  "error.max_file_size": "File size should be less than {maxFileSize} MB.",

  // Success message
  "success.updated_successfully": "{field} updated successfully.",
  "success.lead_status_updated_success": "Lead {status} successfully",

  //Placeholder
  "placeholder.select_field": "Select {field}",
  "placeholder.enter_field": "Enter {field}",
  "placeholder.please_enter_field": "Please enter {field}",
  "placeholder.fix_field": "Fix {field}",
  "placeholder.search": "search",

  // Message
  "message.no_found_data": "No data found.",
  "message.file_removed_successfully": "File removed successfully.",
  "message.file_removed_error": "Something went wrong while removing file.",
  "message.error_worksheet": "Please select worksheet first.",
  "message.this_action_will_reset_all_your_form_data": "Note: This action will reset all your form data",

  // Confirm message
  "confirm.are_you_sure": "Are you sure want to {field}?",
  "confirm.are_you_sure_delete": "Are you sure want to delete {field}?",
  "confirm.are_you_sure_lock": "Are you sure you want to lock {field}?",
  "confirm.are_you_sure_unlock": "Are you sure you want to unlock {field}?",
  "confirm.are_you_sure_reset_password": "Are you sure you want to reset password for {field}?",
  "confirm.are_you_sure_want_to_change_upload_type": "Are you sure want to change upload type?",

  //Dropzone
  "dropzone.file_name": "file name",
  "dropzone.title": "Click or drag file to this area to upload",
  "dropzone.allowed_files": "Supported file types : {allowedExtensions}",
  "dropzone.max_file_size": "Maximum file size {maxFilesize} MB or maximum number of rows up to 25K.",
  "dropzone.browse_files": "Browse Files",
  "dropzone.file_process_message": "Your file is being processed and will be ready soon, please check your searches to get an update.",
  "dropzone.file_delete_error": "Something went wrong while removing file.",
  "dropzone.file_upload_error": "Something went wrong while uploading file.",

  // Date Range picker
  "date_range_picker.default_label": "date range",
  "date_range_picker.date_range": "{startDate} - {endDate}",

  // Buttons
  "btn.close": "close",
  "btn.cancel": "cancel",
  "btn.submit": "submit",
  "btn.update": "update",
  "btn.reset": "reset",
  "btn.reset_section": "reset {section}",
  "btn.create_section": "create {section}",
  "btn.next": "next",
  "btn.back": "back",
  "btn.skip": "skip",
  "btn.save": "save",
  "btn.ok": "ok",
  "btn.reject": "reject",
  "btn.delete": "delete",
  "btn.yes": "yes",
  "btn.no": "no",
  "btn.buy_now": "buy now",
  "btn.searches": "Searches",
  "btn.continue": "continue",
  "btn.add_member": "ADD MEMBER",
  "btn.book_a_call": "book a call",
  "btn.experience_enrich": "Experience Enrich B2B Today",
  "btn.request_demo": "Request a Demo",
  "btn.just_me_please": "just me, please",
  "btn.sign_up": "Sign Up",
  "btn.sign_in": "sign in",
  "btn.home": "home",

  //Home page
  "home.heading": "Revolutionize Your <br />Data-Driven Strategies",
  "home.subheading": "Unlock the Power of Live LinkedIn <br />Data for Enterprise Data-Leaders",
  "home.master_data": "Master Your Data Universe with Enrich B2B",
  "home.master_data.content": "<p>Data-leaders in today's competitive landscape require accurate, reliable, and scalable data to drive strategic decision-making. Enrich B2B is specifically designed for Enterprise data-leaders who demand the best from their data, empowering them with live LinkedIn data at scale for a targeted, data-driven approach.</p><p>Embrace the future of data validation with Enrich B2B - the key to unlocking the potential of LinkedIn data for Enterprise data-leaders.</p>",
  "home.data_challenges": "How Enrich B2B Tackles Your Data Challenges",
  "home.feature1.title": "Streamline and Optimize Sales Processes",
  "home.feature1.description": "Automate data validation to increase efficiency and revenue. With real-time LinkedIn data, focus on strategic analysis, driving growth and long-term success.",
  "home.feature2.title": "Analyze Sales Performance Data",
  "home.feature2.description": "Identify trends and opportunities for improvement with enriched data, providing comprehensive insights that empower data-driven decisions.",
  "home.feature3.title": "Enhance Data Integrity and Accuracy",
  "home.feature3.description": "Automate data validation to increase efficiency and revenue. With real-time LinkedIn data, focus on strategic analysis, driving growth and long-term success.",
  "home.feature4.title": "Gather and Analyze Data from Multiple Sources",
  "home.feature4.description": "Automate data validation to increase efficiency and revenue. With real-time LinkedIn data, focus on strategic analysis, driving growth and long-term success.",
  "home.feature5.title": "Develop and Maintain Data Models and Databases",
  "home.feature5.description": "Automate data validation to increase efficiency and revenue. With real-time LinkedIn data, focus on strategic analysis, driving growth and long-term success.",
  "home.feature6.title": "Collaborate with Cross-Functional Teams",
  "home.feature6.description": "Automate data validation to increase efficiency and revenue. With real-time LinkedIn data, focus on strategic analysis, driving growth and long-term success.",
  "home.unlock_data_leaders.title": "Unlock the Potential of Enrich B2B for Data-Leaders",
  "home.unlock_data_leaders.description": "Save time and resources with automated B2B data validation at scale. Minimise errors and ensure compliance with data privacy regulations. Gain a competitive edge with clean, fresh, and accurate data.<br /> Make data-driven business decisions and optimise your sales funnel.",

  //Pricing page
  "pricing.title": "Pricing",
  "pricing.sub_title": "Each credit is a verified lead, where data has been returned from LinkedIn.",
  "pricing.call_title": "Not sure about how many leads you'll need?",
  "pricing.call_sub_title": "Book a call and see if we can help you simplify your workflow and decrease costs.",

  // FAQs page
  "faq.title": "FAQs",
  "faq.sub_title": "If you have further questions, please get in touch!",

  // Contact-US page
  "contact.title": "Contact Us",
  "contact.sub_title": "Contact us to enquire or ask any questions – we are here to help at all times.",
  "contact.success": "Success",
  "contact.success_message": "Your message has been sent and we'll be in contact shortly",

  //Login page
  "login.title": "Login",
  "login.subtitle": "Welcome back, please login to your account.",
  "login.remember_me": "Remember me",
  "login.forgot_link": "forgot password?",
  "login.create_account_label": "Don’t you have an account? ",
  "login.sign_up_link": "Not registered? Create an account",
  "login.form.email": "Email :",
  "login.form.password": "Password :",
  "login.dont_have_account": "Don’t you have an account? ",

  // SignUp page
  "signup.title": "Sign Up",
  "signup.subtitle": "Sign up for access",
  "signup.first_name": "First Name",
  "signup.last_name": "Last Name",
  "signup.email": "Email",
  "signup.company_name": "Company Name",
  "signup.password": "Password",
  "signup.confirm_password": "Confirm Password",
  "signup.already_have_account": "Already have an account? ",
  "signup.login_link": "Already registerd? Login here",

  // Book a call page
  "book_a_call.title": "Book a Call",
  "book_a_call.first_name": "First Name :",
  "book_a_call.last_name": "Last Name :",
  "book_a_call.phone": "Phone :",
  "book_a_call.email": "Email :",
  "book_a_call.message": "Message :",

  //Forgot Password Page
  "forgot_password.title": "Forgot password",
  "forgot_password.login": " login",
  "forgot_password.e-mail_you_instructions": "We’ll e-mail you instructions on how to reset your password.",
  "forgot_password.email": "Email :",
  "forgot_password.go_back_to": "Go back to",
  "forgot_password.reset_password": "Reset Password",

  //Reset Password Page
  "reset_password.title": "Reset password",
  "reset_password.show_password": "show password",
  "reset_password.hide_password": "hide password",

  //Generic page
  "generic_page.coming_soon.title": "coming soon",
  "generic_page.coming_soon.sub_title": "This page is under construction.",
  "generic_page.not_found.title": "not found",
  "generic_page.not_found.sub_title": "The requested page is not found.",
  "generic_page.credit_details": "{remainCredits} / {totalCredits}",

  //Under Maintenance
  "generic_page.under_maintenance.title": "Under Maintenance",
  "generic_page.under_maintenance.sub_title": "This page you're looking for is currently under maintenance and will be back soon.",

  // Admin: Dashboard
  "dashboard.title": "Dashboard",
  "dashboard.leads_searched": "Items Searched",
  "dashboard.leads_matched": "Items Matched",
  "dashboard.match_per": "Match (%)",
  "dashboard.leads_verified": "Searched vs. Matched",
  "dashboard.credit_consumptions": "Credit Available vs. Consumption",

  // Enrich: Contact
  "enrich_contact.title": "Enrich - Contact",
  "enrich_contact.title_mapping": "Enrich - Mapping",
  "enrich_contact.title_data": "Enrich - Data",
  "enrich_contact.modal_title.select_worksheet": "Select Worksheet",
  "enrich.contact.error_select_file": "please select file to complete request.",
  "enrich.contact.error_data_field": "please select at least one contact or company fields.",
  "enrich.contact.error_data_source": "please select at least one data source.",
  "enrich.contact.error_select_field": "Please select at least one field from the list.",
  "enrich_contact.required_fields": "Required Fields",
  "enrich_contact.invalid_action": "Invalid Action",
  "enrich_contact.job_title": "Select which jobs you'd like to run",
  "enrich_contact.select_all": "Select All",
  "enrich_contact.step_title": "Select the data that you want to add",
  "enrich_contact.file_name": "File Name:",
  "enrich_contact.your_matching": "Your Matching %",
  "enrich_contact.cost": "cost",
  "enrich_contact.upload_records": "Records",
  "enrich_contact.total_credits": "Credits",
  "enrich_contact.insufficient_credit_message": "You have insufficient credits",
  "enrich_contact.add_credits": "add credits",
  "enrich_contact.find_result_anyway": "Extended Search",
  "enrich_contact.include_extendedsearch": "Include Extended Search",
  "enrich_contact.find_result_anyway.note": "This option will consume extra credits but will try to find results by using various combinations of the values available in your file.",
  "enrich_contact.instruction.title": "Import Instruction: Contact Search",
  "enrich_contact.instruction.one": "If you are searching contact using LinkedIn URL, please make sure the column is available and values are well populated in your file.",
  "enrich_contact.instruction.two": "If the search is based on other criteria, the file should have all these fields and values available: First Name, Last Name, and Company for the best search result.",
  "enrich_contact.instruction.three": "Any of your column header names in the file must not have the prefix 'Enrich_' as this is a reserved prefix for result columns.",
  "enrich_contact.instruction.company_website_note": "Company Website will cost additional 1 credit if selected.",
  "enrich_contact.include_fields.title": "Enrich Matching Validity and Score/Rank: Original vs. LI Values",
  "enrich_contact.include_fields.include_validity_check": "Include Validity Check",
  "enrich_contact.include_fields.include_company_matching_score": "Include Company Matching Score/Rank",
  "enrich_contact.include_fields.include_jobtitle_score": "Include Job Title Matching Score/Rank",
  "enrich_contact.receipt_modal.title": "Recipient List",
  "enrich_contact.receipt_modal.note": "You can enter additional email addresses(comma separated) to receive the notification email after the search completes.",
  "enrich_contact.file_upload": "file upload",
  "enrich_contact.upload_your_leads_file": "upload your leads file",
  "enrich_contact.mapping": "mapping",
  "enrich_contact.map_your_columns": "map your columns",
  "enrich_contact.data": "data",
  "enrich_contact.add_data": "add data",

  // Enrich: Company
  "enrich_company.title": "Enrich - Company",
  "enrich_company.title_mapping": "Enrich - Mapping",
  "enrich_company.title_data": "Enrich - Data",

  // Enrich: Company Employees
  "enrich_company_employees.title": "Enrich - Company Employees",
  "enrich_company_employees.title_mapping": "Enrich - Mapping",
  "enrich_company_employees.title_data": "Enrich - Data",
  "enrich_company_employees.max_employees_per_company": "Max Employees Per Company",

  // Admin: Searches
  "searches.title": "Searches",
  "searches.table.file_name": "Name",
  "searches.table.upload_date": "Date",
  "searches.table.status": "Status",
  "searches.table.type": "Type",
  "searches.table.records_uploaded": "Records Searched",
  "searches.table.records_updated": "Records Matched",
  "searches.table.credits": "Credits Spent",
  "searches.table.time_taken": "Time Taken",
  "searches.table.download": "Download",
  "searches.something_went_wrong_while_fetching_user_list": "Something went wrong while fetching user list.",

  // Admin: Logs & analytics
  "logs_analytics.title": "Logs & Analytics",
  "logs_analytics.table.file_name": "File Name",
  "logs_analytics.table.upload_date": "Upload Date",
  "logs_analytics.table.progress": "Progress",
  "logs_analytics.table.records_uploaded": "Records Uploaded",
  "logs_analytics.table.records_updated": "Records Updated",
  "logs_analytics.table.credits": "Credits",
  "logs_analytics.table.download": "Download",

  // Admin: Invoices
  "invoices.title": "Invoices",
  "invoices.table.invoice_name": "Invoice Name",
  "invoices.table.download": "Download",
  "invoices.table.invoice_date": "Invoice Date",
  "invoices.table.amount": "Amount",
  "invoices.table.credits": "# of Credits",

  // Admin: API keys
  "api_keys.title": "API Keys",
  "api_keys.api_key": "API Key",
  "api_keys.add_a_card": "Add a card",
  "api_keys.add_a_card_details": " to your account. Our system will then apply test credits so you can trial our API before you make any commitment.",

  // Admin: My Team
  "my_team.title": "My Team",

  // Admin : Add Credit
  "credit.title": "Add Credits",
  "credit.add_credits": "Add Credits",
  "credit.total_cost": "Total Cost: ",
  "credit.add_card": "Add Card",
  "credit.credit_amount": "{currency}{price} /CREDIT",
  "credit.card_information": "We won't touch your card details. Your payment method is securely stored and handled by our payment partner Stripe. By adding your card here, you allow us to use your payment method for qualified recurring payments.",
  "credit.fetch_price_list_error": "Something went wrong while fetching Price list.",

  // Admin : Settings
  "settings.title": "Settings",
  "settings.add_a_card": "Add a card",
  "settings.add_a_card_details": " to your account. Our system will then apply test credits so you can trial our API before you make any commitment.",
  "settings.set_your_matching": "Set your Matching %",
  "settings.accepted": "Accepted",
  "settings.warning": "Warning",
  "settings.rejected": "Rejected",
  "settings.account_details": "account details",

  "payment.payment_successful": "Payment Successful!",
  "payment.payment_failed": "Payment Failed!",
  "payment.subscription_id": "Subscription ID: ",
  "payment.transaction_id": "Transaction ID: ",
  "payment.credit": "Credit: ",
  "payment.amount": "Amount: ",
  "payment.reference_id": "Reference ID: ",
  "payment.transaction_date": "Transaction Date: ",

  "user_management.title": "User Management",
  "user_management.table.name": "Name",
  "user_management.table.email": "Email",
  "user_management.table.organization": "Organization",
  "user_management.table.available_credits": "Available credits",
  "user_management.table.role": "Role",
  "user_management.table.action": "Action",
  "user_management.action.lock_user": "Lock User",
  "user_management.action.unlock_user": "Unlock User",
  "user_management.action.edit": "Edit",
  "user_management.action.delete": "Delete",
  "user_management.action.reset_password": "Reset Password",
  "user_management.action.add_credits": "Add credits",
  "user_management.create_user.title": "Create User",
  "user_management.edit_user.title": "Edit User",
  "user_management.add_credits.title": "Add credits",

  "terms_conditions.title": "Terms and Conditions of Use",
};