import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { showAlertMessage, ErrorMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";

import ChangePassword from "../ChangePassword";
import { fetchUserSettings, updateUserDetails, clearUpdateUserDetailsData } from "../../actions/application";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: {},
      errors: {},
      matchingPer: []
    }
  }

  componentDidMount() {
    const { fetchUserSettings, userDetails } = this.props;

    if (typeof fetchUserSettings === "function") { fetchUserSettings(); }

    this.setState({
      userDetails: _get(userDetails, "data.user_details", {}),
      matchingPer: _get(userDetails, "data.search_matching_percentage", []),
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.userDetails, this.state.userDetails)) { this._handleValidation(); }

    if (!_isEqual(prevProps.userDetails, this.props.userDetails) && !_isEmpty(this.props.userDetails)) {
      const { userDetails } = this.props;

      if ((userDetails.status || null) === null) { return false; }

      if ((userDetails.status || false) === true) {

        this.setState({
          userDetails: _get(userDetails, "data.user_details", {}),
          matchingPer: _get(userDetails, "data.search_matching_percentage", []),
        });
      } else {
        showAlertMessage(_get(userDetails, "message", "Something went wrong while fetching user details."));
      }
    }

    if (!_isEqual(prevProps.updatedUserDetails, this.props.updatedUserDetails) && !_isEmpty(this.props.updatedUserDetails)) {
      const { updatedUserDetails, clearUpdateUserDetailsData } = this.props;

      if (_get(updatedUserDetails, "status", false) === true) {
        showAlertMessage(_get(updatedUserDetails, "data.message", "User details updated successfully."), "success");
      } else {
        showAlertMessage(_get(updatedUserDetails, "message", "Something went wrong while updating user details."));
      }

      if (typeof clearUpdateUserDetailsData === "function") { clearUpdateUserDetailsData(); }
    }
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      userDetails: { ...prevState.userDetails, [name]: value }
    }));
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { userDetails } = this.state;

    let errors = {};

    if (_isEmpty(_get(userDetails, "first_name", ""))) {
      errors["firstname"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "first_name", defaultMessage: "First name" })
      });
    }

    if (_isEmpty(_get(userDetails, "last_name", ""))) {
      errors["lastname"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "last_name", defaultMessage: "Last name" })
      });
    }

    this.setState({ errors });
  }

  _onUpdateUserDetails() {
    const { updateUserDetails } = this.props;
    const { userDetails, errors } = this.state;

    if (
      _isEmpty(_get(userDetails, "first_name", "")) ||
      _isEmpty(_get(userDetails, "last_name", "")) ||
      !_isEmpty(errors)
    ) {
      this._handleValidation();
      return;
    }

    if (typeof updateUserDetails === "function") {
      updateUserDetails({
        first_name: _get(userDetails, "first_name", ""),
        last_name: _get(userDetails, "last_name", ""),
      });
    }
  }

  _renderMatchingPer = () => {
    const { matchingPer } = this.state;

    return (
      <>
        <div className="col-lg-12 mt-30">
          <h3 className="heading-04 fw-medium mb-7">
            <FormattedMessage id="settings.set_your_matching" defaultMessage="Set your Matching %" />
          </h3>

          {_map((matchingPer), (m, i) => {
            const perType = _get(m, "search_matching_text", "");

            return (
              <div className="setting-level d-flex align-items-center" key={`matching-per-${i}`}>
                <p className={`border bg-opacity-10 rounded-2 ${(perType === "Rejected") ? "bg-danger border-danger text-danger" : ((perType === "Warning") ? "bg-warning border-warning text-warning" : "bg-secondary border-secondary text-secondary")}`}>{perType}</p>
                <p className="position-relative">
                  <input type="text" className="form-control form-control-sm px-2 text-center w60" defaultValue={_get(m, "min_percentage", "")} readOnly />
                  <span className="position-absolute top-50 end-0 translate-middle-y me-2">%</span>
                </p>
                <p className="text-center"><FormattedMessage id="to" defaultMessage="to" /></p>
                <p className="position-relative">
                  <input type="text" className="form-control form-control-sm px-2 text-center w60" defaultValue={_get(m, "max_percentage", "")} readOnly />
                  <span className="position-absolute top-50 end-0 translate-middle-y me-2">%</span>
                </p>
              </div>
            )
          })}
        </div>
        {/* <div className="col-lg-12">
          <div className="text-end mt-30">
            <button type="button" className="btn btn-sm btn-primary">
              <FormattedMessage id="btn.update" defaultMessage="Update" />
            </button>
          </div>
        </div> */}
      </>
    );
  }

  render() {
    const { intl } = this.props;
    const { userDetails, errors } = this.state;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.settings", defaultMessage: "Settings: Enrich B2B" })} </title>
        </Helmet>

        <div>
          <div className="mt-6 my-4">
            <h1 className="heading-02 fw-medium">
              <FormattedMessage id="settings.title" defaultMessage="Settings" />
            </h1>
          </div>
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="alert alert-primary text-dark heading-06" role="alert">
                <Link to="add-credits" className="text-primary">
                  <FormattedMessage id="settings.add_a_card" defaultMessage="Add a card" />
                </Link>
                <FormattedMessage id="settings.add_a_card_details" defaultMessage=" to your account. Our system will then apply test credits so you can trial our API before you make any commitment." />
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-12">
              <form autoComplete="off">
                <div className="bg-white border p-6 rounded-4 mb-5">
                  <h2 className="heading-04 fw-medium mb-7 text-capitalize">
                    <FormattedMessage id="settings.account_details" defaultMessage="account details" />
                  </h2>

                  <div className="row">
                    <div className="col-lg-9">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-5">
                            <label className="form-label heading-07 mb-2 text-capitalize"><FormattedMessage id="first_name" defaultMessage="First Name" /></label>
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              placeholder="First Name"
                              defaultValue={_get(userDetails, "first_name", "")}
                              onChange={(e) => this._handleChange(e)}
                            />
                            {(errors.firstname || "") && (
                              <ErrorMessage message={(errors.firstname || "")} />
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-5">
                            <label className="form-label heading-07 mb-2 text-capitalize"><FormattedMessage id="last_name" defaultMessage="Last Name" /></label>
                            <input
                              type="text"
                              className="form-control"
                              name="last_name"
                              placeholder="Last Name"
                              defaultValue={_get(userDetails, "last_name", "")}
                              onChange={(e) => this._handleChange(e)}
                            />
                            {(errors.lastname || "") && (
                              <ErrorMessage message={(errors.lastname || "")} />
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-5">
                            <label className="form-label heading-07 mb-2 text-capitalize"><FormattedMessage id="email" defaultMessage="Email" /></label>
                            <input type="text" className="form-control" name="email" placeholder="Email" defaultValue={_get(userDetails, "email", "")} disabled={true} />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-5">
                            <label className="form-label heading-07 mb-2 text-capitalize"><FormattedMessage id="password" defaultMessage="Password" /></label>
                            <div className="position-relative">
                              <input type="text" className="form-control" name="pasword" placeholder="Password" defaultValue="*****************" disabled={true} />
                              <img src="images/icons/icon-password.svg" className="position-absolute end-0 top-0 me-3 mt-3" alt="Password" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="text-end mt-3 mb-7 d-flex gap-3 justify-content-lg-end">
                            <ChangePassword setState={(args) => this.setState(args)} />

                            <button type="button" className="btn btn-primary text-capitalize" onClick={() => this._onUpdateUserDetails()}>
                              <FormattedMessage id="btn.update" defaultMessage="Update" />
                            </button>
                          </div>
                        </div>

                        {this._renderMatchingPer()}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: _get(state, "application.settings.data", {}),
  updatedUserDetails: _get(state, "application.updateUserDetails", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserSettings: () => dispatch(fetchUserSettings()),
  clearUpdateUserDetailsData: () => dispatch(clearUpdateUserDetailsData()),
  updateUserDetails: (data) => dispatch(updateUserDetails(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Settings)));