import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { get as _get } from "lodash";
import Responsive from "react-responsive";

import { EmptyLabel } from "../components/common/controls";
import config from "./config";

export const arrReorderIndex = (arr, takeIndex, putIndex) => {
  let result = Array.from(arr);
  const [removed] = result.splice(takeIndex, 1);
  result.splice(putIndex, 0, removed);

  return result;
};

export const copyToClipboard = (text = "") => {
  var textArea = document.createElement("textarea");

  textArea.value = text;
  document.body.appendChild(textArea);

  textArea.focus();
  textArea.select();

  document.execCommand("copy");

  document.body.removeChild(textArea);
};

export const requireAll = requireContext =>
  requireContext.keys().map(requireContext);

export const _getSubdomain = (hostname = window.location.hostname) => {
  if (["127.0.0.1", "localhost"].includes(hostname)) {
    return "";
  }

  return (window.location.host || "").substring(0, (window.location.host || "").indexOf(config.getFrontendURL()));
}

export const ucfirst = string =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const isValidEmail = (email = "") =>
  new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email);

export const isValidPassword = (password = "") =>
  /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\dX])(?=.*[!@#$%]).*$/.test(
    password
  );

export const isValidPhone = phone =>
  /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g.test(phone);

export const isValidCheckNumber = (check = "") =>
  new RegExp(
    /^[a-zA-Z0-9]{0,15}$/
  ).test(check);

export const isValidAccountNumber = (number = "") =>
  new RegExp(
    /^[0-9a-zA-Z]{3,17}$/
  ).test(number);

export const isValidRoutingNumber = (number = "") =>
  new RegExp(
    /^[0-9]{9,9}$/
  ).test(number);

//eslint-disable-next-line
export const isValidDomainName = name => (/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&"\(\)\*\+,;=.]+$/.test(name));

/**
 * Check passed URL is valid or not
 *
 * @param String 
 * @return Boolean true/false 
 */
export const isValidURL = (str) => new RegExp("^(https?:\\/\\/)?" + // protocol
  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
  "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
  "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
  "(\\#[-a-z\\d_]*)?$", "i").test(str); // fragment locator

export const getInitials = (string, divider = " ", limit = 2) =>
  (string || "")
    .split(divider)
    .map(
      (a, i) => {
        if (i < limit) return a.charAt(0);

        return null;
      }
    )
    .join("")
    .toUpperCase();

// function uses to grab the query parameters from the url in object.
export const getParams = (url) => {
  const params = {};
  const parser = document.createElement("a");
  parser.href = url;

  const query = parser.search.substring(1);
  const vars = query.split("&");

  (vars || []).forEach((item) => {
    const pair = (item || "").split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  })

  return params;
};

export const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  function (f) {
    return setTimeout(f, 1000 / 60);
  }; // simulate calling code 60

export const cancelAnimationFrame =
  window.cancelAnimationFrame ||
  window.mozCancelAnimationFrame ||
  function (requestID) {
    clearTimeout(requestID);
  }; //fall back

export const getDeviceMode = (width) => {
  let mode = "desktop";

  if (width <= 1750) {
    mode = "laptop";
  }

  if (width <= 1499) {
    mode = "tablet";
  }

  if (width <= 768) {
    mode = "mobile";
  }

  return mode;
};

export const scrollTo = (element, change, duration, callback = () => { }) => {
  var start = element.scrollLeft;
  var currentTime = 0;
  var increment = 20;

  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  var animateScroll = () => {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollLeft = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    } else {
      callback(element.scrollLeft);
    }
  };
  animateScroll();
};

export const Desktop = props => <Responsive {...props} minWidth={1200} />;
export const Tablet = props => (
  <Responsive {...props} minWidth={768} maxWidth={1499} />
);
export const Mobile = props => <Responsive {...props} maxWidth={767} />;
export const Default = props => <Responsive {...props} minWidth={768} />;

export const isJSON = (str) => {
  try {
    var obj = JSON.parse(str);
    if (obj && typeof obj === "object" && obj !== null) {
      return true;
    }
  } catch (err) { }
  return false;
}

/**
 * Convert Object key value pair to URL query params
 *
 * @param Object Key Value pair object
 */
export const objectToParams = (object) => {

  Object.entries(object).map(([key, val]) => val === null && delete object[key]);

  if (Object.keys(object).length > 0) {
    return new URLSearchParams(object).toString();
  } else {
    return "";
  }

}

/**
* Display toast message on screen
*
* @param  String message
* @param  ENUM success|error|warning|info
* @return void
*/
export const toastMessage = (message, type) => {

  switch (type) {
    case "success":
      toast.success(message)
      break;
    case "error":
      toast.error(message)
      break;
    case "warning":
      toast.warning(message)
      break;
    case "info":
      toast.info(message)
      break;
    default:
      toast.success(message)
      break;
  }

}

export const sortIcon = () => <i className="fa fa-sort-amount-down ml-1" />
/**
* String to Array buffer
*
* @param  String s
* @return Array buffer
*/
function s2ab(s) {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

/**
* Download an Excel file
*
* @param  String Content type of the excel file
* @param  String String which can be convert into Array buffer
* @param  String Name of the file
* @return void
*/
export const downloadExcelFile = (contentType, contents, filename) => {

  let bin = atob(contents);
  let ab = s2ab(bin);
  let blob = new Blob([ab], { type: contentType });

  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

}

/**
* Download file from URL
*
* @param  String url
* @param  String filename
* @return void
*/
export const downloadFileFromUrl = (url, fileName) => {

  if ((url) && (fileName)) {
    fetch(url).then(resp => resp.blob()).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch((err) => {
      console.error(err.message);
    });
  }
}

export const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref"s element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It"s worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It"s not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

/**
* Render table cell value, if its empty string, will rendered <Empty /> component
*
* @param  String row of rowData
* @param  String path of key
* @return String|Component
*/
export const renderCellValue = (row, path) => {
  const celValue = _get((row || {}), (path || ""), "");
  return (celValue || <EmptyLabel />);
}

/**
* Check the passed variable's value is integer or not.
*
* @param  String Value
* @return Bool
*/
export const isInt = (value) => {
  if (isNaN(value)) {
    return false;
  }

  const x = parseFloat(value);

  return (x | 0) === x;
};

export default {
  requireAll,
  _getSubdomain,
  isValidEmail,
  isValidPassword,
  getInitials,
  isJSON,
  objectToParams,
  toastMessage,
  sortIcon,
  downloadExcelFile,
  renderCellValue,
  isInt
};
