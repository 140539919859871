import React, { Component } from "react";
import { isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { NavLink, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { withRouter } from "../../hoc/withRouter";
import { showAlertMessage } from "../controls";

import { setAdminMenuCollapse, fetchCreditDetails } from "../../../actions/application";

// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <NavLink
    className="d-flex"
    to={"/"}
    ref={ref}
    onClick={(e) => { e.preventDefault(); onClick(e); }}
  >
    {children}
  </NavLink>
));

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creditDetails: {}
    };

  }

  componentDidMount() {
    const { creditDetails, fetchCreditDetails } = this.props;

    if (typeof fetchCreditDetails === "function") { fetchCreditDetails(); }

    this.setState({ creditDetails: _get(creditDetails, "data", []) });
  }

  componentDidUpdate(prevProps) {

    if (!_isEqual(prevProps.creditDetails, this.props.creditDetails) && !_isEmpty(this.props.creditDetails)) {
      const { creditDetails } = this.props;

      if ((creditDetails.status || null) === null) { return false; }

      if ((creditDetails.status || false) === true) {

        this.setState({ creditDetails: _get(creditDetails, "data", []) });
      } else {
        showAlertMessage(creditDetails.message || "Something went wrong while fetching credit details.");
      }
    }
  }

  render() {
    const { setAdminMenuCollapse, adminMenuCollapse, userEmail } = this.props;
    const { creditDetails } = this.state;

    return (
      <header className="header-admin mb-4">
        <nav className="navbar navbar-expand navbar-light bg-white">
          <button
            className="navbar-toggler p-0"
            type="btn toggle-sidebar"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSidebar"
            aria-controls="navbarAdmin"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => { if (typeof setAdminMenuCollapse === "function") { setAdminMenuCollapse(!adminMenuCollapse) } }}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <ul className="d-flex list-unstyled w-100  align-items-center">
            <li className="nav-item nav-credits-mobile">
              <span>
                <img src="images/icons/icon-euro.svg" alt="Euro" />
              </span>
            </li>
            <li className="nav-item nav-credits">
              <span className="nav-link-credits">
                <img src="images/icons/icon-euro.svg" alt="Credits" />
                <FormattedMessage
                  id="generic_page.credit_details"
                  defaultMessage="{remainCredits} / {totalCredits}"
                  values={{ remainCredits: _get(creditDetails, "remaining_credits", 0), totalCredits: _get(creditDetails, "total_credits", 0) }}
                />
              </span>

              <Link to="/add-credits" className="btn btn-primary">
                <img src="images/icons/icon-plus.svg" alt="Add Credits" />
                <FormattedMessage id="credit.title" defaultMessage="Add Credits" />
              </Link>
            </li>
            <li className="nav-item dropdown ms-auto">
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                  <div className="dropdown-toggle d-block py-2">
                    <span className="me-5">{(userEmail || "")}</span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="nav-rightside-menu">
                  <Dropdown.Item href="/logout" className="text-capitalize">
                    <FormattedMessage id="logout" defaultMessage="Logout" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setAdminMenuCollapse: (payload) => dispatch(setAdminMenuCollapse(payload)),
  fetchCreditDetails: () => dispatch(fetchCreditDetails()),
});

const mapStateToProps = (state) => ({
  userEmail: _get(state, "oauth.user.email", ""),
  creditDetails: _get(state, "application.creditDetails", {}),
  adminMenuCollapse: _get(state, "application.adminMenuCollapse", false)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
