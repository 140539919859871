import { takeLatest, call, fork, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import { doLogin, doSignUp, doForgotPassword, checkResetPassword, doResetPassword, doChangePassword, createContactUs } from "../services/oauth";

import {
  OAUTH_LOGIN_REQUEST, OAUTH_LOGIN_SUCCESS, OAUTH_LOGIN_ERROR,
  OAUTH_SIGNUP_REQUEST, OAUTH_SIGNUP_SUCCESS, OAUTH_SIGNUP_ERROR,
  FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR,
  CHECK_RESET_PASSWORD_CRITERIA, RESET_PASSWORD_VALID, RESET_PASSWORD_INVALID, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_ERROR,
  CREATE_CONTACT_US_REQUEST, CREATE_CONTACT_US_SUCCESS, CREATE_CONTACT_US_ERROR
} from "../actions/oauth";

import { UPDATE_APPLICATION_LOADING } from "../actions/application";

function* workerDoLogin(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(doLogin, action.data);

    //This is to check if error or not
    if ((response.flag || false) === true) {
      yield put({
        type: OAUTH_LOGIN_SUCCESS,
        token: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: OAUTH_LOGIN_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong, Try again later."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: OAUTH_LOGIN_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong, Try again later."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerDoSignUp(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(doSignUp, action.data);

    if ((response.flag || false) === true) {
      yield put({
        type: OAUTH_SIGNUP_SUCCESS,
        data: _get(response, "data", {}),
        message: {
          title: _get(response, "title", "Success"),
          message: _get(response, "data.message", "You have signed up successfully.")
        }
      });
    } else {
      yield put({
        type: OAUTH_SIGNUP_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong, Try again later."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: OAUTH_SIGNUP_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong, Try again later."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerDoForgot(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(doForgotPassword, action.data);

    if ((response.flag || false) === true) {
      yield put({
        type: FORGOT_PASSWORD_SUCCESS,
        data: (response.flag || {}),
        message: {
          title: _get(response, "title", "Success"),
          message: (response.message || "Something went wrong while generating forgot password link.")
        },
      });
    } else {
      yield put({
        type: FORGOT_PASSWORD_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while generating forgot password link."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: FORGOT_PASSWORD_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong, Try again later."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerCheckReset(action) {
  try {
    const response = yield call(checkResetPassword, action.data);

    if ((response.flag || false) === true) {
      yield put({
        type: RESET_PASSWORD_VALID,
        resetToken: _get(response, "data.data.id", "Alert"),
        data: (response.flag || false),
      });
    } else {
      yield put({
        type: RESET_PASSWORD_INVALID,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong, Try again later."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_INVALID,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong, Try again later."),
      },
    });
  }
}

function* workerDoReset(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(doResetPassword, action.data);

    if ((response.flag || false) === true) {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        data: (response.flag || false),
        message: {
          title: _get(response, "title", "Success"),
          message: (response.message || "Password reset successfully.")
        },
      });
    } else {
      yield put({
        type: RESET_PASSWORD_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong, Try again later."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong, Try again later."),
      },
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerDoChange(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(doChangePassword, action.data);

    if ((response.flag || false) === true) {
      yield put({
        type: CHANGE_PASSWORD_SUCCESS,
        data: (response.flag || false),
        message: {
          title: _get(response, "title", "Success"),
          message: (response.message || "Password changed successfully.")
        },
      });
    } else {
      yield put({
        type: CHANGE_PASSWORD_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong, Try again later."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: CHANGE_PASSWORD_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong, Try again later."),
      },
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerCreateContactUs(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(createContactUs, _get(action, "data", {}));

    if ((response.flag || false) === true) {
      yield put({
        type: CREATE_CONTACT_US_SUCCESS,
        data: (response.flag || false),
        message: {
          title: _get(response, "title", "Success"),
          message: _get(response, "data.message", "Your request has been submitted successfully.")
        },
      });
    } else {
      yield put({
        type: CREATE_CONTACT_US_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "data.message", "Something went wrong while submitting contact us request."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_CONTACT_US_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong while submitting contact us request."),
      },
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherDoLogin() {
  yield takeLatest(OAUTH_LOGIN_REQUEST, workerDoLogin);
}

function* watcherDoSignUp() {
  yield takeLatest(OAUTH_SIGNUP_REQUEST, workerDoSignUp);
}

function* watcherDoForgot() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, workerDoForgot);
}

function* watcherCheckReset() {
  yield takeLatest(CHECK_RESET_PASSWORD_CRITERIA, workerCheckReset);
}

function* watcherDoReset() {
  yield takeLatest(RESET_PASSWORD_REQUEST, workerDoReset);
}

function* watcherDoChange() {
  yield takeLatest(CHANGE_PASSWORD_REQUEST, workerDoChange);
}

function* watcherCreateContactUs() {
  yield takeLatest(CREATE_CONTACT_US_REQUEST, workerCreateContactUs);
}

export default [
  fork(watcherDoLogin),
  fork(watcherDoSignUp),
  fork(watcherDoForgot),
  fork(watcherCheckReset),
  fork(watcherDoReset),
  fork(watcherDoChange),
  fork(watcherCreateContactUs),
];
