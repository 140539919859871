import api from "../helpers/api";
import { get as _get, omit as _omit } from "lodash";

export const combinedLookupData = (source = "") => {
  return api().get(`/search/lookup_data/${source}`).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong to fetch fields."),
      data: {}
    };
  });
}

//Upload and read file and fetch data mapping columns
export const uploadReadFile = (variables, setParentState = null) => {

	const config = {
		onUploadProgress: (data) => {
			if (typeof setParentState === "function") {
				setParentState({
					progress: Math.round((100 * (data.loaded || 0)) / (data.total || 0))
				});
			}
		}
	};

	let url = `/search/upload?uploadType=${(variables.uploadType || "contact")}`;
  
  if ((variables.default_sheet_name || null) !== null) {
    url = `${url}&default_sheet_name=${(variables.default_sheet_name || "")}`;
  }

	return api().post(url, (variables.formfile || {}), config).then((res) => {

		return {
			flag: true,
			message: _get(res, "data.message", ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		const statusCode = _get(err, "response.status", 422);

		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong."),
			error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
			statusCode: (statusCode === 413) ? statusCode : 422,
			data: _get(err, "response.data.data.work_sheet_names", {})
		};
	});
}

export const removeUploadedFile = (variables) => {
	const payload = _omit(variables, "uploadType");

	return api().delete("/search/delete_uploaded_file", { data: payload }).then((res) => {

		return {
			flag: true,
			message: _get(res, "data.message", ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		const statusCode = _get(err, "response.status", 403);

		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong."),
			error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
			data: {}
		};
	});
}

//Pass mapping data and store in DB
export const saveMappingData = (variables) => {
	return api().post("/search", variables).then((res) => {

		return {
			flag: true,
			message: _get(res, "data.message", ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong."),
			error: _get(err, "response.data.data.errors", {}),
			data: {}
		};
	});
}

//Submit selected data to server for calculate estimate cost
export const submitCalculateEstimateData = (variables) => {

  return api().options("/search/calculate_estimate", { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 403);

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while calculating estimation cost."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      data: {}
    };
  });
}

export const fetchSearchData = (variables) => {

  return api().options("/search/saved_search", { data: variables.payload }).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching search records."),
      data: {}
    };
  });
}

export const getUserFilterData = () => {

  return api().get("/search/user_filter_data").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while filtering data."),
      data: {}
    };
  });
}

export const getInvoices = () => {
  return api().get("/user/user_payment_receipt").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching invoices."),
      data: {}
    };
  });
}