import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { showAlertMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";

import { getAPIKey, resetAPIKey } from "../../services/application";
import { updateLoadingState } from "../../actions/application";

class APIKeys extends Component {
  constructor(props) {
    super(props);

    this.state = { apiKey: "" }
  }

  componentDidMount() {
    this._fetchAPIKeys();
  }

  _fetchAPIKeys = async () => {
    const { updateLoadingState } = this.props;

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await getAPIKey();

      if ((response.flag || false) === true) {

        this.setState({ apiKey: _get(response, "data.api_key", "") });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while fetching API key."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while fetching API key."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _resetAPIKey = async () => {
    const { updateLoadingState } = this.props;

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await resetAPIKey();

      if ((response.flag || false) === true) {
        showAlertMessage(_get(response, "data.message", "API key has been reset successfully.", "success"));

        this.setState({ apiKey: _get(response, "data.data.api_key", "") });
      } else {
        showAlertMessage(_get(response, "data.message", "Something went wrong while reseting API key."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while reseting API key."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  render() {
    const { intl } = this.props;
    const { apiKey } = this.state;

    const isAPIConfigured = !_isEmpty(apiKey);

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.api_keys", defaultMessage: "API Keys: Enrich B2B" })} </title>
        </Helmet>
        <div>
          <div className="mt-6 my-4">
            <h1 className="heading-02 fw-medium">
              <FormattedMessage id="api_keys.title" defaultMessage="API Keys" />
            </h1>
          </div>
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="alert alert-primary text-dark heading-06" role="alert">
                <Link to="add-credit" className="text-primary">
                  <FormattedMessage id="api_keys.add_a_card" defaultMessage="Add a card" />
                </Link>
                <FormattedMessage id="api_keys.add_a_card_details" defaultMessage=" to your account. Our system will then apply test credits so you can trial our API before you make any commitment." />
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="bg-white border p-6 rounded-4 mb-5 mh-600">
                <h2 htmlFor="apikey" className="heading-03 fw-medium mb-7">
                  <FormattedMessage id="api_keys.api_key" defaultMessage="API Key" />
                </h2>
                <div className="row">
                  <div className="col-xl-9">
                    <form autoComplete="off">
                      <div className="row">
                        <div className="col-xl-6 col-lg-7">
                          <div className="mb-5">
                            <label class="form-label heading-07 mb-2"><FormattedMessage id="api_keys.api_key" defaultMessage="API Key" /></label>
                            <input type="text" className="form-control" name="apikey" placeholder="API key" defaultValue={(apiKey || "")} readOnly />
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="mt-6">
                            <button type="button" className="btn btn-outline-primary text-capitalize" onClick={() => this._resetAPIKey()}>
                              {(isAPIConfigured) ? (
                                <FormattedMessage id="btn.reset_section" defaultMessage="reset {section}" values={{ section: intl.formatMessage({ id: "api_keys.api_key", defaultMessage: "API Key" }) }} />
                              ) : (
                                <FormattedMessage id="btn.create_section" defaultMessage="create {section}" values={{ section: intl.formatMessage({ id: "api_keys.api_key", defaultMessage: "API Key" }) }} />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader))
});

export default withRouter(connect(null, mapDispatchToProps)(injectIntl(APIKeys)));