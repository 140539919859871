import { combineReducers } from "redux";
import { get as _get } from "lodash";

import actionTypes from "../actions";

const initialTopTilesData = { status: null, message: "", data: {} };

const topTilesData = (state = initialTopTilesData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TOP_TILES_DATA_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.FETCH_TOP_TILES_DATA_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_TOP_TILES_DATA:
      return initialTopTilesData;

    default:
      return state;
  }
}

const initialLeadsVerifiedChart = { status: null, message: "", data: {} };

const leadsVerifiedChart = (state = initialLeadsVerifiedChart, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LEADS_VERIFIED_CHART_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.FETCH_LEADS_VERIFIED_CHART_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_LEADS_VERIFIED_CHART_DATA:
      return initialLeadsVerifiedChart;

    default:
      return state;
  }
}

const initialCreditConsumptionsChart = { status: null, message: "", data: {} };

const creditConsumptionsChart = (state = initialCreditConsumptionsChart, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CREDIT_CONSUMPTIONS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.FETCH_CREDIT_CONSUMPTIONS_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_CREDIT_CONSUMPTIONS_CHART_DATA:
      return initialCreditConsumptionsChart;

    default:
      return state;
  }
}

export default {
  dashboard: combineReducers({
    topTilesData,
    leadsVerifiedChart,
    creditConsumptionsChart
  })
}
