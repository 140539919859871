import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { get as _get } from "lodash";

import storage from "redux-persist/lib/storage";

import { DEFAULT_LANGUAGE, sidebarMenuConfig, tmpConstant } from "../helpers/constants";
import { getDeviceMode } from "../helpers/utils";
import configs from "../helpers/config";

import actionTypes from "../actions";

const persistConfig = {
  key: `${configs.store_name}__language`,
  storage,
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_APPLICATION_LOADING:
      return action.loading;

    default:
      return state;
  }
}

const language = (state = DEFAULT_LANGUAGE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LANGUAGE:
      return action.language;

    default:
      return state;
  }
}

const defaultConfig = {
  menu: sidebarMenuConfig,
}

const config = (state = defaultConfig, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATION_SUCCESS:
      return (action.payload.config || {});

    default:
      return state;
  }
}

const initialSize = {
  width: window.innerWidth,
  height: window.innerHeight,
  mode: getDeviceMode(window.innerWidth)
}

const viewport = (state = initialSize, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_VIEWPORT:
      return ({
        ...action.size,
        mode: getDeviceMode(action.size.width)
      });

    default:
      return state;
  }
}

const languages = combineReducers({
  defaultLang: language
});

const constants = (state = tmpConstant, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATION_SUCCESS:
      // return action.payload.constants || {};
      return state;

    default:
      return state;
  }
}

const appActiveMenu = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_MENU:
      return (action.payload || "");

    default:
      return state;
  }
}

const adminMenuCollapse = (state = false, action) => {
  switch (action.type) {
    case actionTypes.SET_ADMIN_MENU_COLLAPSE:
      return (action.payload || false);

    default:
      return state;
  }
}

const initialSettings = { status: null, message: "", data: {} };

const settingData = (state = initialSettings, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_SETTINGS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.FETCH_USER_SETTINGS_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    default:
      return state;
  }
}

const initialUserDetails = { status: null, message: "", data: {} };

const updateUserDetails = (state = initialUserDetails, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_DETAILS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.UPDATE_USER_DETAILS_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.CLEAR_UPDATE_USER_DETAILS_DATA:
      return initialUserDetails;

    default:
      return state;
  }
}

const initialCreditDetails = { status: null, message: "", data: {} };

const creditDetails = (state = initialCreditDetails, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CREDIT_DETAILS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.FETCH_CREDIT_DETAILS_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    default:
      return state;
  }
}

const settings = combineReducers({
  data: settingData,
});

const initialPriceList = { status: null, message: "", data: {} };

const priceList = (state = initialPriceList, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRICE_LIST_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.FETCH_PRICE_LIST_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    default:
      return state;
  }
};

export default {
  application: combineReducers({
    loading,
    language: persistReducer(persistConfig, languages),
    config,
    viewport,
    constants,
    appActiveMenu,
    adminMenuCollapse,
    updateUserDetails,
    creditDetails,
    settings,
    priceList
  })
}
