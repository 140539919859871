import { combineReducers } from "redux";
import { get as _get, cloneDeep as _cloneDeep } from "lodash";

import actionTypes from "../actions";

const fields = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COMBINED_LOOKUP_DATA_SUCCESS:
      return (action.data || {});

    case actionTypes.FETCH_COMBINED_LOOKUP_DATA_ERROR:
      return {};

    default:
      return state;
  }
}

const initialMapping = { showModal: false, data: {}, success: {}, successMessage: "" };

const dataMapping = (state = initialMapping, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_READ_FILE_SUCCESS:
      const showModal = _get(action, "payload.showModel", true)
      return { showModal, data: _get(action, "payload.data", {}), success: {}, successMessage: "" };

    case actionTypes.SAVE_UPLOAD_MAPPING_SUCCESS:
      return { showModal: false, data: {}, success: _get(action, "payload.data", {}), successMessage: _get(action, "payload.message", {}) };

    case actionTypes.RESET_DATA_MAPPING_CONFIG:
      return initialMapping;

    default:
      return state;
  }
}

const dataMappingErrors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_READ_FILE_ERROR:
      return _get(action, "payload.error", {});

    case actionTypes.SAVE_UPLOAD_MAPPING_ERROR:
      return _get(action, "error", {});

    case actionTypes.RESET_DATA_MAPPING_CONFIG:
    case actionTypes.RESET_DATA_MAPPING_ERROR:
      return {};

    default:
      return state;
  }
}

const workSheets = (state = [], action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_READ_FILE_ERROR:
      return _get(action, "payload.error.data", []);

    default:
      return state;
  }
}

const calculateEstimate = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_CALCULATE_ESTIMATE_DATA_SUCCESS:
      return (action.data || {});

    case actionTypes.SUBMIT_CALCULATE_ESTIMATE_DATA_ERROR:
    case actionTypes.CLEAR_ESTIMATION_COST_DATA:
    case actionTypes.RESET_DATA_MAPPING_CONFIG:
      return {};

    default:
      return state;
  }
}

const initialRemoveUploadState = { status: null, message: "" };

const removeUpload = (state = initialRemoveUploadState, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_UPLOADED_FILE_SUCCESS:
      return { status: true, message: _get(action, "payload.message", "") };

    case actionTypes.REMOVE_UPLOADED_FILE_ERROR:
      return { status: false, message: _get(action, "error.message", "") };

    case actionTypes.RESET_REMOVE_UPLOADED_FILE:
      return initialRemoveUploadState;

    default:
      return state;
  }
}

const fileUpload = combineReducers({
  dataMapping,
  errors: dataMappingErrors
});

const searchData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_DATA_SUCCESS:
      const overwriteData = _get(action, "overwriteData", true);

      if (overwriteData === false) {

        const oldData = _cloneDeep(state);
        const newData = _get(action, "data.saved_search_file_upload", {});

        return {
          ...state,
          "saved_search_file_upload": [...oldData.saved_search_file_upload, ...newData],
          status: action.status
        };
      } else {

        return (action.data || {});
      }

    case actionTypes.FETCH_SEARCH_DATA_ERROR:
      return {};

    default:
      return state;
  }
}

const searchErrors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_DATA_ERROR:
      return (action.error || {});

    case actionTypes.RESET_SEARCH_DATA_ERROR:
      return {};

    default:
      return state;
  }
}

const searches = combineReducers({
  data: searchData,
  errors: searchErrors
});

const initialInvoices = { status: null, data: [], message: "" };

const invoicesData = (state = initialInvoices, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INVOICES_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_INVOICES_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_INVOICES_ERROR:
      return initialInvoices;

    default:
      return state;
  }
}

export default {
  enrich: combineReducers({
    fields,
    fileUpload,
    removeUpload,
    workSheets,
    calculateEstimate,
    searches,
    invoices: invoicesData
  })
}