import { takeLatest, call, fork, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import { removeUploadedFile, saveMappingData, combinedLookupData, fetchSearchData, getInvoices } from "../services/enrich";

import {
	FETCH_COMBINED_LOOKUP_DATA, FETCH_COMBINED_LOOKUP_DATA_SUCCESS, FETCH_COMBINED_LOOKUP_DATA_ERROR,
	SAVE_UPLOAD_MAPPING_DATA_REQUEST, SAVE_UPLOAD_MAPPING_SUCCESS, SAVE_UPLOAD_MAPPING_ERROR,
	REMOVE_UPLOADED_FILE_REQUEST, REMOVE_UPLOADED_FILE_SUCCESS, REMOVE_UPLOADED_FILE_ERROR,
	FETCH_SEARCH_DATA_REQUEST, FETCH_SEARCH_DATA_SUCCESS, FETCH_SEARCH_DATA_ERROR,
	FETCH_INVOICES_REQUEST, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_ERROR
} from "../actions/enrich";
import { UPDATE_APPLICATION_LOADING } from "../actions/application";

function* workerCombinedLookupData({ payload }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(combinedLookupData, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_COMBINED_LOOKUP_DATA_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_COMBINED_LOOKUP_DATA_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong, Try again later.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_COMBINED_LOOKUP_DATA_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong, Try again later.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerSaveUploadMappingData({ payload }) {

	try {
		yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

		const response = yield call(saveMappingData, payload);

		if ((response.flag || false) === true) {
			yield put({
				type: SAVE_UPLOAD_MAPPING_SUCCESS,
				payload: {
					data: _get(response, "data", {}),
					message: (response.message || ""),
					payload: payload
				}
			});
		} else {
			yield put({
				type: SAVE_UPLOAD_MAPPING_ERROR,
				error: {
					title: _get(response, "title", "Alert"),
					message: _get(response, "message", "Something went wrong, Try again later."),
					errors: _get(response, "error", {})
				}
			});
		}

	} catch (error) {

		yield put({
			type: SAVE_UPLOAD_MAPPING_ERROR,
			error: {
				title: _get(error, "title", "Alert"),
				message: _get(error, "message", "Something went wrong, Try again later.")
			}
		});
	} finally {

		yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
	}
}

function* workerRemoveUploadedFile({ payload = {} }) {

	try {
		yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

		const response = yield call(removeUploadedFile, payload);

		if ((response.flag || false) === true) {
			yield put({
				type: REMOVE_UPLOADED_FILE_SUCCESS,
				payload: {
					data: _get(response, "data", {}),
					message: (response.message || ""),
					uploadType: (payload.uploadType || "")
				}
			});
		} else {
			yield put({
				type: REMOVE_UPLOADED_FILE_ERROR,
				error: {
					title: _get(response, "title", "Alert"),
					message: _get(response, "message", "Something went wrong, Try again later."),
					errors: (response.error || {})
				}
			});
		}

	} catch (error) {

		yield put({
			type: REMOVE_UPLOADED_FILE_ERROR,
			error: {
				title: _get(error, "title", "Alert"),
				message: _get(error, "message", "Something went wrong, Try again later.")
			}
		});
	} finally {
		yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
	}
}

function* workerFetchSavedSearchById({ payload }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchSearchData, payload);
    
    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_SEARCH_DATA_SUCCESS,
        data: _get(response, "data", {}),
        historyPayload: (payload.payload || {}),
        overwriteData: _get(payload, "overwriteData", true),
      });
    } else {

      yield put({
        type: FETCH_SEARCH_DATA_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong, Try again later.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_SEARCH_DATA_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong, Try again later.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }

}

function* workerFetchInvoices() {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getInvoices);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_INVOICES_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_INVOICES_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching invoices.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_INVOICES_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching invoices.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherCombinedLookupData() {
  yield takeLatest(FETCH_COMBINED_LOOKUP_DATA, workerCombinedLookupData);
}

function* watcherSaveUploadMappingData() {
	yield takeLatest(SAVE_UPLOAD_MAPPING_DATA_REQUEST, workerSaveUploadMappingData);
}

function* watcherRemoveUploadedFile() {
	yield takeLatest(REMOVE_UPLOADED_FILE_REQUEST, workerRemoveUploadedFile);
}

function* watcherFetchSavedSearchById() {
  yield takeLatest(FETCH_SEARCH_DATA_REQUEST, workerFetchSavedSearchById);
}

function* watcherFetchInvoices() {
  yield takeLatest(FETCH_INVOICES_REQUEST, workerFetchInvoices);
}

export default [
	fork(watcherCombinedLookupData),
	fork(watcherSaveUploadMappingData),
	fork(watcherRemoveUploadedFile),
	fork(watcherFetchSavedSearchById),
	fork(watcherFetchInvoices),
];