import React, { Component } from "react";
import { cloneDeep as _cloneDeep, get as _get, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";

import { Modal, showAlertMessage, ErrorMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";
import { IconPassword, IconEye } from "../../helpers/iconStyles";
import config from "../../helpers/config";

import { doChangePassword, clearChangePasswordResponse, clearChangePasswordError } from "../../actions/oauth";

class ChangePasswordUser extends Component {

  constructor(props) {
    super(props);

    this.defaultCredentials = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    };

    this.state = {
      credentials: _cloneDeep(this.defaultCredentials),
      showChangePasswordModal: false,
      errors: {},
      successAlert: {},
      errorAlert: {},
      disabled: true,
      showPasswordType: "password"
    };

    this.legalUrl = config.getFrontendURL();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.credentials, this.state.credentials)) { this._handleValidation(); }

    if (!_isEqual(prevProps.error, this.props.error) && !_isEmpty(this.props.error)) {
      const { clearChangePasswordError, error } = this.props;
      showAlertMessage(_get(error, "message", "Something went wrong while changing password."));

      if (typeof clearChangePasswordError === "function") { clearChangePasswordError(); }
    }

    if (!_isEqual(prevProps.success, this.props.success) && !_isEmpty(this.props.success)) {
      const { clearChangePasswordResponse, success } = this.props;
      showAlertMessage(_get(success, "message", "Password changed successfully."), "success");

      this.setState({
        credentials: _cloneDeep(this.defaultCredentials),
        errors: {},
        showChangePasswordModal: false
      });

      if (typeof clearChangePasswordResponse === "function") { clearChangePasswordResponse(); }
    }
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      credentials: {
        ...prevState.credentials,
        [name]: value,
      },
      errorAlert: {},
      successAlert: {}
    }));
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { credentials: { oldPassword, newPassword, confirmPassword } } = this.state;

    let errors = {};

    if (_isEmpty(oldPassword)) {
      errors["oldPassword"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "old_password", defaultMessage: "Old Password" })
      });
    }

    if (_isEmpty(newPassword)) {
      errors["newPassword"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "new_password", defaultMessage: "New Password" })
      });
    }

    if (!_isEqual(newPassword, confirmPassword)) {
      errors["confirmPassword"] = intl.formatMessage({ id: "error.password_not_match", defaultMessage: "{field} is no matched" }, {
        field: intl.formatMessage({ id: "confirm_password", defaultMessage: "Confirm Password", })
      });
    }

    this.setState({
      errorAlert: {},
      successAlert: {},
      disabled: !_isEmpty(errors),
      errors,
    });
  }

  _doChangePassword = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { doChangePassword, user } = this.props;
    const { credentials: { oldPassword, newPassword, confirmPassword }, errors } = this.state;

    if (
      _isEmpty(oldPassword) ||
      _isEmpty(newPassword) ||
      _isEmpty(confirmPassword) ||
      !_isEmpty(errors)
    ) {
      this._handleValidation();
      return;
    }

    if (typeof doChangePassword === "function") { doChangePassword({ user_id: user.user_id, old_password: oldPassword, new_password: newPassword, confirm_password: confirmPassword, subdomain: this.legalUrl }); }

  }

  _showPassword = () => {
    const { showPasswordType } = this.state;

    if (showPasswordType === "password") {
      this.setState({ showPasswordType: "text" });
    } else {
      this.setState({ showPasswordType: "password" });
    }
  }

  _closePopover() {

    this.setState({
      credentials: _cloneDeep(this.defaultCredentials),
      errors: {},
      showChangePasswordModal: false
    });
  }

  _renderModal() {
    const { intl } = this.props;
    const { showChangePasswordModal, errors, showPasswordType } = this.state;

    return (
      <Modal
        isOpen={(showChangePasswordModal || false)}
        bodyClassName="overflow-unset modal-change-password"
        modalTitle={(<FormattedMessage id="change_password" defaultMessage="change password" />)}
        onHide={() => this._closePopover()}
        onClose={() => this._closePopover()}
        onSubmit={(e) => this._doChangePassword(e)}
        closeBtnText={intl.formatMessage({ id: "btn.cancel", defaultMessage: "cancel" })}
        submitBtnText={intl.formatMessage({ id: "change_password", defaultMessage: "Change Password" })}
      >
        <Form className="form-change-password">
          <div className="tab-content">
            <div className="cardBox">
              <div className="cardBox-content">
                <div>
                  <div className="form-group required">
                    <label htmlFor="UserOldPassword" className="form-label mb-2 text-capitalize">{intl.formatMessage({ id: "old_password", defaultMessage: "Old Password" })}</label>
                    <input
                      type="password"
                      name="oldPassword"
                      className="form-control"
                      id="oldPassword"
                      placeholder={intl.formatMessage({ id: "old_password", defaultMessage: "Old Password" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.oldPassword || "") && (
                      <ErrorMessage message={(errors.oldPassword || "")} />
                    )}
                  </div>
                  <div className="form-group required mt-5">
                    <label className="form-label mb-2 text-capitalize" htmlFor="UserNewPassword">{intl.formatMessage({ id: "new_password", defaultMessage: "password" })}</label>
                    <div className="position-relative">
                      <span className="cursor-pointer" onClick={() => this._showPassword()}>
                        {(showPasswordType === "text") && (
                          <IconEye width="16px" height="16px" color="#636E72" />
                        )}
                        {(showPasswordType === "password") && (
                          <IconPassword size="16px" />
                        )}
                      </span>
                      <input
                        type={(showPasswordType || "")}
                        name="newPassword"
                        className="form-control"
                        id="newPassword"
                        placeholder={intl.formatMessage({ id: "new_password", defaultMessage: "New Password" })}
                        onChange={(e) => this._handleChange(e)}
                      />
                    </div>
                    {(errors.newPassword || "") && (
                      <ErrorMessage message={(errors.newPassword || "")} />
                    )}
                  </div>
                  <div className="form-group required  mt-5">
                    <label className="form-label mb-2 text-capitalize" htmlFor="UserConfirmPassword">{intl.formatMessage({ id: "confirm_password", defaultMessage: "password" })}</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                      id="confirmPassword"
                      placeholder={intl.formatMessage({ id: "confirm_password", defaultMessage: "Confirm Password" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.confirmPassword || "") && (
                      <ErrorMessage message={(errors.confirmPassword || "")} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }

  render() {

    return (
      <>
        <button type="button" className="btn btn-outline-primary text-capitalize" onClick={() => this.setState({ showChangePasswordModal: true })}>
          <FormattedMessage id="change_password" defaultMessage="change password" />
        </button>

        {this._renderModal()}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  error: _get(state, "oauth.error", {}),
  success: _get(state, "oauth.success", {}),
  user: _get(state, "oauth.user", {}),
  changePasswordResponse: _get(state, "oauth.changePasswordResponse", true)
});

const mapDispatchToProps = (dispatch) => ({
  doChangePassword: (data) => dispatch(doChangePassword(data)),
  clearChangePasswordError: () => dispatch(clearChangePasswordError()),
  clearChangePasswordResponse: () => dispatch(clearChangePasswordResponse())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangePasswordUser)));