import React, { PureComponent } from "react";
import { isEmpty as _isEmpty } from "lodash";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import { IconClose } from "../../../helpers/iconStyles";

export class CustomModal extends PureComponent {

  static defaultProps = {
    baseClassName: "Modal",
    modalTitleClassName: "text-capitalize-first",
    showCloseButton: true,
    additionalButton: null,
    onClose: () => { },
    onSubmit: () => { },
    onEntered: () => { },
    onExited: () => { },
  }

  render() {
    const {
      baseClassName = "",
      className = "",
      bodyClassName = "",
      footerClassName = "",
      modalTitle = "",
      additionalButton,
      modalTitleClassName = "",
      showCloseButton = false,
      children,
      closeBtnText,
      submitBtnText,
      isOpen = false,
      onClose = () => { },
      onSubmit = () => { },
      onEntered = () => { },
      onExited = () => { },
      center = true,
      ...props
    } = this.props;

    return (
      <Modal
        dialogClassName={`custom-modal ${baseClassName}`}
        className={className}
        onHide={onClose}
        centered={center}
        show={isOpen}
        onEntered={onEntered}
        onExited={onExited}
        onClose={() => onClose()}
        {...props}
      >
        <Modal.Header>
          <Modal.Title className={`heading-06 fw-semibold text-capitalize-first ${modalTitleClassName}`}>{(modalTitle || "")}</Modal.Title>
          {showCloseButton && (<span className="cursor-pointer" onClick={() => this.props.onClose()}><IconClose height="20px" width="20px" color="#FFFFFA" /></span>)}
        </Modal.Header>
        <Modal.Body className={bodyClassName}>
          {children}
        </Modal.Body>
        <Modal.Footer className={footerClassName}>
          {(closeBtnText) && (
            <div>
              <button className="btn text-capitalize" onClick={onClose}>
                {(closeBtnText) && (closeBtnText)}
              </button>
            </div>
          )}

          {(!_isEmpty(additionalButton)) && (additionalButton)}

          {(submitBtnText) && (
            <div>
              <button className="btn btn-primary text-capitalize-first text-capitalize" onClick={onSubmit}>
                {(submitBtnText || "")}
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

CustomModal.propTypes = {
  portalClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
  baseClassName: PropTypes.string,
  additionalButton: PropTypes.node,
  showCloseButton: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onEntered: PropTypes.func,
  onExited: PropTypes.func,
  customCloseButton: PropTypes.node,
  center: PropTypes.bool,
};

export default CustomModal;
