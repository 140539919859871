import React, { Component } from "react";
import { get as _get, map as _map, isEqual as _isEqual, isEmpty as _isEmpty, groupBy as _groupBy, forEach as _forEach } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { showAlertMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";

import { addCreditPayment } from "../../services/application";
import { fetchPriceList, updateLoadingState } from "../../actions/application";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class AddCredits extends Component {
  constructor(props) {
    super(props);

    this.defaultCredit = {
      selPackageId: 1,
      creditPoint: 0,
      creditAmount: 0,
      isValidCreditPoint: false
    };

    this.state = {
      ...this.defaultCredit,
      priceList: [],
    };
  }

  componentDidMount() {
    const { fetchPriceList, priceList } = this.props;

    if (typeof fetchPriceList === "function") { fetchPriceList(); }

    this.setState({
      priceList: _groupBy(_get(priceList, "data", []), "package_id"),
    });
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevProps.priceList, this.props.priceList) && !_isEmpty(this.props.priceList)) {
      const { priceList, intl } = this.props;

      if ((priceList.status || null) === null) { return false; }

      if ((priceList.status || false) === true) {

        this.setState({ priceList: _groupBy(_get(priceList, "data", []), "package_id"), });
      } else {
        showAlertMessage((priceList.message || intl.formatMessage({ id: "credit.fetch_price_list_error", defaultMessage: "Something went wrong while fetching Price list." })));
      }
    }
  }

  _updateCreditPoints = (e) => {
    e.preventDefault();
    const { priceList } = this.state;

    const tmpPoint = parseInt(_get(e, "target.value", 0));
    let tmpPackageId = null;

    _forEach(priceList, (p, k) => {

      if ((tmpPoint >= _get(p, "[0].min_credits", 0)) && (tmpPoint <= _get(p, "[0].max_credits", 0))) {
        tmpPackageId = _get(p, "[0].package_id", 0);
        return;
      }
    });

    if (tmpPackageId !== null) {
      const creditAmount = (tmpPoint * _get(priceList, `[${tmpPackageId}][0].price`, 0));

      this.setState({ selPackageId: tmpPackageId, creditPoint: tmpPoint, creditAmount: creditAmount, isValidCreditPoint: true });
    } else if (tmpPoint > 0) {
      showAlertMessage("Please add credit amount between 10 to 10M.");
      this.setState({ ...this.defaultCredit });
    } else {
      this.setState({ creditPoint: (tmpPoint || "") });
    }
  }

  _buyCredits = async () => {
    const { updateLoadingState } = this.props;
    const { isValidCreditPoint, selPackageId, creditPoint, creditAmount } = this.state;

    if ((isValidCreditPoint === false) || (isNaN(selPackageId)) || (creditPoint < 10) || (creditAmount <= 0.30)) {
      showAlertMessage("Please add credit amount between 10 to 10M.");
      return false;
    }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    const activateResp = await addCreditPayment({ credit: creditPoint });

    const activateResStatus = _get(activateResp, "flag", false);
    const isRequiredtoRedirect = _get(activateResp, "data.is_required_to_redirect_checkout", false);

    if (activateResStatus === true) {

      // Do not redirect on checkout page, if its upgradation request
      if (isRequiredtoRedirect === false) {
        if (typeof updateLoadingState === "function") { updateLoadingState(false); }
        showAlertMessage(_get(activateResp, "message", "Payment successful."), "success");

        setTimeout(() => {
          this.setState({ ...this.defaultCredit });
        }, 3000);
        return false;
      }

      const stripe = await stripePromise;

      const sessionId = _get(activateResp, "data.session_id", null);

      if (_isEmpty(sessionId)) {
        if (typeof updateLoadingState === "function") { updateLoadingState(false); }
        showAlertMessage("Something went wrong generating payment session.");
        return false;
      }

      const result = await stripe.redirectToCheckout({ sessionId: sessionId });

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }

      if (result.error) {

        // If `redirectToCheckout` fails due to a browser or network
        showAlertMessage(_get(result, "error.message", "Something went wrong loading the payment gateway."));
      }

      this.setState({ ...this.defaultCredit });
    } else {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
      showAlertMessage(_get(activateResp, "message", "Subscription error."));
      this.setState({ ...this.defaultCredit });
    }
  }

  _renderPlans = () => {
    const { priceList } = this.state;

    return (
      <div className="row">
        {_map((priceList), (p, i) => {
          const plan = _get(p, "[0]", {});

          return (
            <div className="col-xl-3 col-lg-6" key={i}>
              <div className="card-credit bg-white p-6 gap-4 rounded-4 shadow mb-5">
                <p className="heading-07 fw-bold mb-4">{_get(plan, "name", "")}</p>
                <h4 className="fw-bold mb-2">{_get(plan, "currency_symbol", "")} {_get(plan, "price", "")}</h4>
                <p className="heading-07 text-grey-b2 fw-bold">{_get(plan, "price_type", "")}</p>
              </div>
            </div>
          )
        })}
      </div>
    );
  }

  _renderAddCredits = () => {
    const { intl } = this.props;
    const { priceList, creditPoint, selPackageId, creditAmount } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="bg-white border p-6 rounded-4 mb-5">
            <div className="row">
              <div className="col-xl-9">
                <p className="heading-04 fw-medium mb-7">
                  <FormattedMessage id="credit.add_credits" defaultMessage="Add Credits" />
                </p>

                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-group mb-3">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Add a Credits amount"
                          value={creditPoint}
                          onChange={(e) => this._updateCreditPoints(e)}
                        />
                        <button className="input-group-text border-primary bg-primary text-white" type="button">
                          <FormattedMessage
                            id="credit.credit_amount"
                            defaultMessage="{currency}{price} /CREDIT"
                            values={{ currency: _get(priceList, `[${selPackageId}][0].currency_symbol`, ""), price: _get(priceList, `[${selPackageId}][0].price`, 0) }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="mb-lg-0 mb-15">
                        <p className="total-credit mb-lg-0 mb-3">
                          <img src="images/icons/icon-euro.svg" alt={intl.formatMessage({ id: "credit.total_cost", defaultMessage: "Total Cost" })} />
                          <FormattedMessage id="credit.total_cost" defaultMessage="Total Cost: " />
                          <span className="fw-semibold">{_get(priceList, `[${selPackageId}][0].currency_symbol`, "")}{(creditAmount || 0).toFixed(2)}</span></p>
                      </div>
                    </div>

                    <div className="col-lg-auto">
                      <button className="btn btn-primary text-capitalize" onClick={() => this._buyCredits()}>
                        <FormattedMessage id="btn.buy_now" defaultMessage="buy now" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderAddCard = () => {
    return (
      <div className="row mt-35">
        <div className="col-lg-12">
          <p className="heading-05 fw-semibold mb-15">
            <FormattedMessage id="credit.add_card" defaultMessage="Add Card" />
          </p>
        </div>

        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-5">
              <div className="mb-lg-0 mb-15">
                <input type="text" className="form-control" placeholder="Add a Credits number" />
              </div>
            </div>

            <div className="col-lg">
              <div className="mb-lg-0 mb-15">
                <input type="text" className="form-control" placeholder="Expire Date" />
              </div>
            </div>

            <div className="col-lg">
              <div className="mb-lg-0 mb-15">
                <input type="text" className="form-control" placeholder="CVV Number" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <p className="small text-grey-9d my-15">
                <FormattedMessage id="credit.card_information" defaultMessage="We won't touch your card details. Your payment method is securely stored and handled by our payment partner Stripe. By adding your card here, you allow us to use your payment method for qualified recurring payments." />
              </p>
            </div>
            <div className="col-lg-12">
              <div className="text-end mt-20">
                <button type="button" className="btn btn-sm btn-primary">
                  <FormattedMessage id="btn.update" defaultMessage="update" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.add_credits", defaultMessage: "Add Credits: Enrich B2B" })} </title>
        </Helmet>
        <div>
          <div className="mt-6 my-4">
            <h1 className="heading-02 fw-medium">
              <FormattedMessage id="credit.title" defaultMessage="Add Credits" />
            </h1>
          </div>

          {this._renderPlans()}
          {this._renderAddCredits()}
          {/* {this._renderAddCard()} */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  priceList: _get(state, "application.priceList", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPriceList: () => dispatch(fetchPriceList()),
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddCredits)));