import { takeLatest, call, fork, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import { getUserList } from "../services/userManagement";

import { 
  FETCH_USER_LIST_REQUEST, FETCH_USER_LIST_SUCCESS, FETCH_USER_LIST_ERROR
  } from "../actions/userManagement";
 import { UPDATE_APPLICATION_LOADING } from "../actions/application";

 function* workerFetchUserList({ payload }) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getUserList, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_USER_LIST_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_USER_LIST_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching users list.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_USER_LIST_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching users list.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherFetchUserList() {
  yield takeLatest(FETCH_USER_LIST_REQUEST, workerFetchUserList);
}

export default [
  fork(watcherFetchUserList),
];
