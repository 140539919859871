import { combineReducers } from "redux";
import { get as _get } from "lodash";

import actionTypes from "../actions";

const initialUserList = { status: null, message: "", data: [] };

const list = (state = initialUserList, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_LIST_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_USER_LIST_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.resetUserList:
      return initialUserList;

    default:
      return state;
  }
}

export default {
  user_management: combineReducers({
    list
  })
}
