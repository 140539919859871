import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual, map as _map, cloneDeep as _cloneDeep } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { HighChart, ShowContentLoader, showAlertMessage, DateRangePicker, Select } from "../../components/common/controls";

import { withRouter } from "../../components/hoc/withRouter";

import { getClientList, getClientUserList } from "../../services/dashboard";

import {
  fetchLeadsVerifiedChart, fetchCreditConsumptionsChart, resetLeadsVerifiedChartData, resetCreditConsumptionsChartData,
  fetchTopTilesData, resetTopTilesData
} from "../../actions/dashboard";
import { updateLoadingState } from "../../actions/application";

const defaultClient = { client_id: null, client_name: "Client", isdisabled: false };
const defaultUser = { user_id: null, name: "Users", isdisabled: true };

const searchTypeIds = [
  { id: null, name: "Type", isdisabled: false },
  { id: 1, name: "Contact", isdisabled: false },
  { id: 2, name: "Company", isdisabled: false },
];

const methodTypeIds = [
  { id: null, name: "Method", isdisabled: false },
  { id: 1, name: "File", isdisabled: false },
  { id: 2, name: "API", isdisabled: false },
];

class Dashboard extends Component {

  constructor(props) {
    super(props);

    const { roleId, clientId } = props;

    this.defaultFilter = {
      startDate: null,
      endDate: null,
      searchType: { id: null, name: "Type", isdisabled: false },
      methodType: { id: null, name: "Method", isdisabled: false },
      clientIds: (roleId === 2) ? [clientId] : null,
      userIds: null,
      resetDateRangePicker: false,
      datePickerLabel: ""
    }

    this.leadsVerifiedOptions = { title: { text: null }, series: [], xAxis: { categories: [] }, yAxis: { categories: [] }, credits: { enabled: false } };
    this.creditConsumptionOptions = { title: { text: null }, series: [], xAxis: { categories: [] }, yAxis: { categories: [] }, credits: { enabled: false } };

    this.state = {
      filter: { ...this.defaultFilter },
      allLeadsCount: 0,
      verifiedLeadsCount: 0,
      validCount: 0,
      leadsVerifiedLoader: true,
      leadsVerifiedOptions: { ...this.leadsVerifiedOptions },
      creditConsumptionLoader: true,
      creditConsumptionOptions: { ...this.creditConsumptionOptions },
      clientList: [],
      userList: [],
    };
  }

  componentDidMount() {
    const { roleId } = this.props;

    // If loggedIn using client role
    if (roleId !== 2) { this._fetchClientList(); }

    this._fetchClientUserList();
    this._fetchTopTilesData();
    this._fetchLeadsVerifiedChartData();
    this._fetchCreditConsumptionsChartData();

    this._setTopTilesData();
    this._setLeadsVerifiedChartData();
    this._setCreditConsumptionsChartData();
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(_get(prevStates, "filter.clientIds", null), _get(this.state, "filter.clientIds", null))) {
      this._fetchClientUserList();
    }

    if (!_isEqual(prevStates.filter, this.state.filter) && !_isEmpty(this.state.filter)) {
      this._fetchTopTilesData();
      this._fetchLeadsVerifiedChartData();
      this._fetchCreditConsumptionsChartData();
    }

    if (!_isEqual(prevProps.topTilesData, this.props.topTilesData) && !_isEmpty(this.props.topTilesData)) {
      const { topTilesData, resetTopTilesData } = this.props;

      if ((topTilesData.status || null) === null) { return false; }

      if ((topTilesData.status || false) === true) {
        this._setTopTilesData();
      } else {
        showAlertMessage(topTilesData.message || "Something went wrong while fetching dashboard details.");
      }

      if (typeof resetTopTilesData === "function") { resetTopTilesData(); }
    }

    if (!_isEqual(prevProps.leadsVerifiedChart, this.props.leadsVerifiedChart) && !_isEmpty(this.props.leadsVerifiedChart)) {
      const { leadsVerifiedChart, resetLeadsVerifiedChartData } = this.props;

      if ((leadsVerifiedChart.status || null) === null) { return false; }

      if ((leadsVerifiedChart.status || false) === true) {
        this._setLeadsVerifiedChartData();
      } else {
        showAlertMessage(leadsVerifiedChart.message || "Something went wrong while fetching Searched vs. Matched details.");
      }

      if (typeof resetLeadsVerifiedChartData === "function") { resetLeadsVerifiedChartData(); }
    }

    if (!_isEqual(prevProps.creditConsumptionsChart, this.props.creditConsumptionsChart) && !_isEmpty(this.props.creditConsumptionsChart)) {
      const { creditConsumptionsChart, resetCreditConsumptionsChartData } = this.props;

      if ((creditConsumptionsChart.status || null) === null) { return false; }

      if ((creditConsumptionsChart.status || false) === true) {
        this._setCreditConsumptionsChartData();
      } else {
        showAlertMessage(creditConsumptionsChart.message || "Something went wrong while fetching Credit Available vs. Consumption details.");
      }

      if (typeof resetCreditConsumptionsChartData === "function") { resetCreditConsumptionsChartData(); }
    }
  }

  _fetchClientList = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getClientList();

      let filterData = _get(response, "data", []);
      (filterData || []).unshift(_cloneDeep(defaultClient));

      this.setState({ clientList: filterData });

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching client list.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _fetchClientUserList = async () => {
    const { roleId, clientId, updateLoadingState } = this.props;
    const { filter: { clientIds } } = this.state;

    const tmpClientId = (roleId === 2) ? clientId : (((clientIds !== null) && (_get(clientIds, "client_id", null) !== null)) ? _get(clientIds, "client_id", null) : null);

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getClientUserList({ client_id: tmpClientId, search_text: "", is_active: 1 });

      let filterData = _get(response, "data", []);
      (filterData || []).unshift(_cloneDeep(defaultUser));

      this.setState({ userList: filterData });

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching client's user list.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _fetchTopTilesData = () => {
    const { roleId, clientId, fetchTopTilesData } = this.props;
    const { filter: { startDate, endDate, searchType, methodType, clientIds, userIds } } = this.state;

    const tmpClientId = (roleId === 2) ? [clientId] : (((clientIds !== null) && (_get(clientIds, "client_id", null) !== null)) ? [_get(clientIds, "client_id", null)] : null);

    if (typeof fetchTopTilesData === "function") {
      fetchTopTilesData({
        user_ids: (userIds !== null) ? _map(userIds, "user_id") : null,
        client_ids: (tmpClientId || null),
        start_date: (startDate || null),
        end_date: (endDate || null),
        search_type: (searchType !== null) ? _get(searchType, "id", null) : null,
        method_type: (methodType !== null) ? _get(methodType, "id", null) : null
      });
    }
  }

  _fetchLeadsVerifiedChartData = () => {
    const { roleId, clientId, fetchLeadsVerifiedChart } = this.props;
    const { filter: { startDate, endDate, searchType, methodType, clientIds, userIds } } = this.state;

    const tmpClientId = (roleId === 2) ? [clientId] : (((clientIds !== null) && (_get(clientIds, "client_id", null) !== null)) ? [_get(clientIds, "client_id", null)] : null);

    if (typeof fetchLeadsVerifiedChart === "function") {
      fetchLeadsVerifiedChart({
        user_ids: (userIds !== null) ? _map(userIds, "user_id") : null,
        client_ids: (tmpClientId || null),
        start_date: (startDate || null),
        end_date: (endDate || null),
        search_type: (searchType !== null) ? _get(searchType, "id", null) : null,
        method_type: (methodType !== null) ? _get(methodType, "id", null) : null
      });
    }
  }

  _fetchCreditConsumptionsChartData = () => {
    const { roleId, clientId, fetchCreditConsumptionsChart } = this.props;
    const { filter: { startDate, endDate, searchType, methodType, clientIds, userIds } } = this.state;

    const tmpClientId = (roleId === 2) ? [clientId] : (((clientIds !== null) && (_get(clientIds, "client_id", null) !== null)) ? [_get(clientIds, "client_id", null)] : null);

    if (typeof fetchCreditConsumptionsChart === "function") {
      fetchCreditConsumptionsChart({
        user_ids: (userIds !== null) ? _map(userIds, "user_id") : null,
        client_ids: (tmpClientId || null),
        start_date: (startDate || null),
        end_date: (endDate || null),
        search_type: (searchType !== null) ? _get(searchType, "id", null) : null,
        method_type: (methodType !== null) ? _get(methodType, "id", null) : null
      });
    }
  }

  _setTopTilesData = () => {
    const { topTilesData } = this.props;

    this.setState({
      allLeadsCount: _get(topTilesData, "data.lead_count", 0),
      verifiedLeadsCount: _get(topTilesData, "data.verified_leads", 0),
      validCount: _get(topTilesData, "data.valid_percent", 0),
    });
  }

  _setLeadsVerifiedChartData = () => {
    const { leadsVerifiedChart } = this.props;
    const { leadsVerifiedOptions } = this.state;

    const trackerOptions = {
      ...leadsVerifiedOptions,
      series: [
        {
          name: "Searched",
          color: "#FF7518",
          data: _get(leadsVerifiedChart, "data.uploaded", [])
        },
        {
          name: "Matched",
          color: "#85D1E2",
          data: _get(leadsVerifiedChart, "data.matched", [])
        }
      ],
      xAxis: { categories: _get(leadsVerifiedChart, "data.xAxis.categories", []) },
      yAxis: { min: 0, showEmpty: false, tickInterval: 50 },
    };

    this.setState({ leadsVerifiedLoader: false, leadsVerifiedOptions: trackerOptions });
  };

  _setCreditConsumptionsChartData = () => {
    const { creditConsumptionsChart } = this.props;
    const { creditConsumptionOptions } = this.state;

    const trackerOptions = {
      ...creditConsumptionOptions,
      series: [
        {
          name: "Available",
          color: "#FF7518",
          data: _get(creditConsumptionsChart, "data.purchase", [])
        },
        {
          name: "Consumed",
          color: "#85D1E2",
          data: _get(creditConsumptionsChart, "data.consumption", [])
        }
      ],
      xAxis: { categories: _get(creditConsumptionsChart, "data.xAxis.categories", []) },
      yAxis: { min: 0, showEmpty: false, tickInterval: 50 },
    };

    this.setState({ creditConsumptionLoader: false, creditConsumptionOptions: trackerOptions });
  };

  _renderTopFilters = () => {
    const { roleId, intl } = this.props;
    const { clientList, userList, filter: { resetDateRangePicker, datePickerLabel, searchType, methodType, clientIds, userIds } } = this.state;

    return (
      <div className="row">
        <div className="col-lg-4">
          <div className="position-relative mb-5">
            <DateRangePicker
              resetDateRangePicker={(resetDateRangePicker || false)}
              datePickerLabel={(datePickerLabel || "date range")}
              className="form-control"
              onChange={(start, end) => this.setState((prevState) => ({
                filter: { ...prevState.filter, resetDateRangePicker: false, "startDate": (start || null), "endDate": (end || null) }
              }))}
            />
          </div>
        </div>
        <div className="col-lg">
          <div className="position-relative mb-5">
            <Select
              className="form-select-custom"
              placeholder={intl.formatMessage({ id: "type", defaultMessage: "Type" })}
              value={(searchType || null)}
              options={(searchTypeIds || [])}
              getOptionLabel={(option) => (option.name || "")}
              getOptionValue={(option) => (option.id || null)}
              isOptionDisabled={(option) => (option.isdisabled || false)}
              onChange={(y) => this.setState((prevState) => ({ filter: { ...prevState.filter, "searchType": (y || null) } }))}
              isMulti={false}
            />
          </div>
        </div>

        {(roleId !== 2) && (
          <div className="col-lg">
            <div className="position-relative mb-5">
              <Select
                className="form-select-custom"
                placeholder={intl.formatMessage({ id: "client", defaultMessage: "Client" })}
                value={(clientIds || null)}
                options={(clientList || [])}
                getOptionLabel={(option) => (option.client_name || "")}
                getOptionValue={(option) => (option.client_id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(y) => this.setState((prevState) => ({ filter: { ...prevState.filter, "clientIds": (y || null) } }))}
                isMulti={false}
              />
            </div>
          </div>
        )}
        <div className="col-lg">
          <div className="position-relative mb-5">
            <Select
              className="form-select-custom"
              placeholder={intl.formatMessage({ id: "users", defaultMessage: "Users" })}
              value={(userIds || null)}
              options={(userList || [])}
              getOptionLabel={(option) => (option.name || "")}
              getOptionValue={(option) => (option.user_id || null)}
              isOptionDisabled={(option) => (option.isdisabled || false)}
              onChange={(y) => this.setState((prevState) => ({ filter: { ...prevState.filter, "userIds": (y || null) } }))}
              isMulti={true}
            />
          </div>
        </div>
        <div className="col-lg">
          <div className="position-relative mb-5">
            <Select
              className="form-select-custom"
              placeholder={intl.formatMessage({ id: "method", defaultMessage: "Method" })}
              value={(methodType || null)}
              options={(methodTypeIds || [])}
              getOptionLabel={(option) => (option.name || "")}
              getOptionValue={(option) => (option.id || null)}
              isOptionDisabled={(option) => (option.isdisabled || false)}
              onChange={(y) => this.setState((prevState) => ({ filter: { ...prevState.filter, "methodType": (y || null) } }))}
              isMulti={false}
            />
          </div>
        </div>
        <div className="col-lg-auto">
          <div className="text-end mb-5">
            <button
              type="button"
              className="btn btn-outline-primary text-capitalize"
              onClick={() => this.setState({
                filter: { ...this.defaultFilter, resetDateRangePicker: true, datePickerLabel: "date range" }
              })}
            >
              <FormattedMessage id="btn.reset" defaultMessage="reset" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  _renderLeadsVerifiedChart = () => {
    const { leadsVerifiedLoader } = this.props;
    const { leadsVerifiedOptions } = this.state;

    return (
      <div className="col-lg-6">
        <div className="bg-white border p-6 gap-4 rounded-4 mb-5 h-lg-100">
          <h5 className="heading-06 fw-bold"> <FormattedMessage id="dashboard.leads_verified" defaultMessage="Searched vs. Matched" /> </h5>

          <div className="mt-8">
            {((leadsVerifiedLoader || false) === false) ? (
              <HighChart options={(leadsVerifiedOptions || {})} />
            ) : (
              <ShowContentLoader />
            )}
          </div>
        </div>
      </div>
    );
  };

  _renderCreditConsumptionsChart = () => {
    const { creditConsumptionLoader } = this.props;
    const { creditConsumptionOptions } = this.state;

    return (
      <div className="col-lg-6">
        <div className="bg-white border p-6 gap-4 rounded-4 mb-5 h-lg-100">
          <h5 className="heading-06 fw-bold"><FormattedMessage id="dashboard.credit_consumptions" defaultMessage="Credit Available vs. Consumption" /></h5>

          <div className="mt-8">
            {((creditConsumptionLoader || false) === false) ? (
              <HighChart options={(creditConsumptionOptions || {})} />
            ) : (
              <ShowContentLoader />
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { intl } = this.props;
    const { allLeadsCount, verifiedLeadsCount, validCount } = this.state;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.dashboard", defaultMessage: "Dashboard: Enrich B2B" })} </title>
        </Helmet>
        <div>
          <div className="mt-6 my-4">
            <h1 className="heading-02 fw-medium">
              <FormattedMessage id="dashboard.title" defaultMessage="Dashboard" />
            </h1>
          </div>

          {this._renderTopFilters()}

          <div className="row">
            <div className="col-lg-4">
              <div className="bg-white border p-6 d-flex gap-4 rounded-4 mb-5">
                <div>
                  <img src="images/icons/icon-circle-search.svg" className="img-fluid" alt={intl.formatMessage({ id: "dashboard.leads_searched", defaultMessage: "Items Searched" })} />
                </div>
                <div>
                  <p className="heading-02 fw-medium mb-1">{(allLeadsCount || 0)}</p>
                  <p className="text-grey-63"><FormattedMessage id="dashboard.leads_searched" defaultMessage="Items Searched" /></p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="bg-white border p-6 d-flex gap-4 rounded-4 mb-5">
                <div>
                  <img src="images/icons/icon-circle-leads.svg" className="img-fluid" alt={intl.formatMessage({ id: "dashboard.leads_matched", defaultMessage: "Items Matched" })} />
                </div>
                <div>
                  <p className="heading-02 fw-medium mb-1">{(verifiedLeadsCount || 0)}</p>
                  <p className="text-grey-63"><FormattedMessage id="dashboard.leads_matched" defaultMessage="Items Matched" /></p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="bg-white border p-6 d-flex gap-4 rounded-4 mb-5">
                <div>
                  <img src="images/icons/icon-circle-match.svg" className="img-fluid" alt={intl.formatMessage({ id: "dashboard.match_per", defaultMessage: "Match (%)" })} />
                </div>
                <div>
                  <p className="heading-02 fw-medium mb-1">{(validCount || 0)}</p>
                  <p className="text-grey-63"><FormattedMessage id="dashboard.match_per" defaultMessage="Match (%)" /></p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {this._renderLeadsVerifiedChart()}
            {this._renderCreditConsumptionsChart()}
          </div>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => ({
  topTilesData: _get(state, "dashboard.topTilesData", {}),
  leadsVerifiedChart: _get(state, "dashboard.leadsVerifiedChart", {}),
  creditConsumptionsChart: _get(state, "dashboard.creditConsumptionsChart", {}),
  clientId: _get(state, "oauth.user.client_id", null),
  roleId: _get(state, "oauth.user.role_id", null)
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  fetchTopTilesData: (payload) => dispatch(fetchTopTilesData(payload)),
  resetTopTilesData: () => dispatch(resetTopTilesData()),
  fetchLeadsVerifiedChart: (payload) => dispatch(fetchLeadsVerifiedChart(payload)),
  resetLeadsVerifiedChartData: () => dispatch(resetLeadsVerifiedChartData()),
  fetchCreditConsumptionsChart: (payload) => dispatch(fetchCreditConsumptionsChart(payload)),
  resetCreditConsumptionsChartData: () => dispatch(resetCreditConsumptionsChartData()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dashboard)));