export const FETCH_USER_LIST_REQUEST = "FETCH_USER_LIST_REQUEST";
export const FETCH_USER_LIST_SUCCESS = "FETCH_USER_LIST_SUCCESS";
export const FETCH_USER_LIST_ERROR = "FETCH_USER_LIST_ERROR";

export const fetchUserList = (payload) => {
  return ({
    type: FETCH_USER_LIST_REQUEST,
    payload
  });
}

export const RESET_USER_LIST = "RESET_USER_LIST";

export const resetUserList = () => {
  return ({
    type: RESET_USER_LIST
  });
}
