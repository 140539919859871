import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import moment from "moment";

import { ReactTable, TableHeaderRenderer, TableCellRenderer, showAlertMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";
import { IconDownload } from "../../helpers/iconStyles";

import { fetchInvoices, resetInvoiceError } from "../../actions/enrich";

class Invoices extends Component {

  constructor(props) {
    super(props);

    const { intl } = props;

    this.columns = [
      {
        ID: "receipt_number",
        accessor: "receipt_number",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "invoices.table.invoice_name", defaultMessage: "Invoice Name" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "receipt_date",
        accessor: "receipt_date",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "invoices.table.invoice_date", defaultMessage: "Invoice Date" })} />),
        Cell: (row) => {
          const { displayDateTimeFormat } = this.props;
          const date = _get(row, "value", null);

          return (<TableCellRenderer value={moment(date).format(displayDateTimeFormat)} tooltip={true} />)
        },
      },
      {
        ID: "amount",
        accessor: "amount",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "invoices.table.amount", defaultMessage: "Amount" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "credits_purchased",
        accessor: "credits_purchased",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "invoices.table.credits", defaultMessage: "# of Credits" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "payment_receipt_url",
        accessor: "payment_receipt_url",
        Header: () => (<TableHeaderRenderer sortable={true} className="text-center" value={intl.formatMessage({ id: "invoices.table.download", defaultMessage: "Download" })} />),
        Cell: (row) => {
          const downloadLink = _get(row, "value", "");

          if (!downloadLink) { return null; }

          return (
            <div className="text-center">
              <a href={downloadLink} className="span-link cursor-pointer" target="_blank" rel="noopener noreferrer">
                <IconDownload width="14px" height="14px" />
              </a>
            </div>
          );
        }
      },
    ];

    this.state = { tableData: [] };
  }

  componentDidMount() {
    const { fetchInvoices, tableData } = this.props;

    if (typeof fetchInvoices === "function") { fetchInvoices(); }

    this.setState({ tableData: _get(tableData, "data", []) });
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevProps.tableData, this.props.tableData) && !_isEmpty(this.props.tableData)) {
      const { tableData, resetInvoiceError } = this.props;

      if ((tableData.status || null) === null) { return false; }

      if ((tableData.status || false) === true) {

        this.setState({ tableData: _get(tableData, "data", []) });
      } else {
        showAlertMessage((tableData.message || "Something went wrong while fetching invoices."));

        if (typeof resetInvoiceError === "function") { resetInvoiceError(); }
      }
    }
  }

  _renderTableData = () => {
    const { tableData } = this.state;

    return (
      <div className="col-lg-12">
        <div className="bg-white border p-6 rounded-4 mb-5">
          <ReactTable tableData={(tableData || [])} columns={this.columns} className="max-h-700 vh-100 table-responsive" />
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.my_invoice", defaultMessage: "My Invoice: Enrich B2B" })} </title>
        </Helmet>

        <div>
          <div className="mt-6 my-4">
            <h1 className="heading-02 fw-medium">
              <FormattedMessage id="invoices.title" defaultMessage="Invoices" />
            </h1>
          </div>

          <div className="row">
            {this._renderTableData()}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  displayDateTimeFormat: _get(state, "application.constants.displayDateTimeFormat", ""),
  tableData: _get(state, "enrich.invoices", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoices: () => dispatch(fetchInvoices()),
  resetInvoiceError: () => dispatch(resetInvoiceError()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Invoices)));