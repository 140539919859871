import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get as _get } from "lodash";
import Helmet from "react-helmet";

import { SingleDropzone } from "../../components/common/controls";

class Contact extends Component {

  render() {
    const { allowedUploadFiles, allowedUploadFilesLabel, intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.enrich_contact", defaultMessage: "Contact: Enrich B2B" })} </title>
        </Helmet>
        <div>

          <SingleDropzone
            source="contact"
            displayUploadedFiles={true}
            acceptedFormat={allowedUploadFiles}
            allowedExtensions={allowedUploadFilesLabel}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  allowedUploadFiles: _get(state, "application.constants.allowedUploadFiles", ""),
  allowedUploadFilesLabel: _get(state, "application.constants.allowedUploadFilesLabel", ""),
});

export default connect(mapStateToProps, null)(injectIntl(Contact));