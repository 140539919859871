import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { get as _get } from "lodash";
import Helmet from "react-helmet";
import moment from "moment";

import { EmptyLabel, showAlertMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";

import { getPaymentDetails } from "../../services/application";
import { fetchCreditDetails } from "../../actions/application";

class PaymentSuccess extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sessionId: _get(this.props, "params.sessionId", null),
      paymentDetails: {}
    };
  }

  componentDidMount() {
    this._fetchPaymentDetails();
  }

  _fetchPaymentDetails = async () => {
    const { updateLoadingState, fetchCreditDetails } = this.props;
    const { sessionId } = this.state;

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await getPaymentDetails("success", { id: sessionId });

      if ((response.flag || false) === true) {

        if (typeof fetchCreditDetails === "function") { fetchCreditDetails(); }

        this.setState({ paymentDetails: _get(response, "data", "") });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while fetching payment details."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while fetching payment details."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  render() {
    const { isLoggedIn, displayDateTimeFormat, intl } = this.props;
    const { paymentDetails } = this.state;

    if (isLoggedIn === false) { return (<Navigate to="/" />); }

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.add_credits", defaultMessage: "Add Credits: Enrich B2B" })} </title>
        </Helmet>

        <div className="row">
          <div className="col-lg-12">
            <div className="bg-white border p-6 rounded-4 mb-5 mh-600">
              <div className="text-center mt-12">
                <h4 className="heading-02 fw-bold mt-8 mb-3 text-success text-capitalize">
                  <FormattedMessage id="payment.payment_successful" defaultMessage="Payment Successful!" />
                </h4>

                <p className="text-grey-63 mb-6">
                  <FormattedMessage id="payment.transaction_id" defaultMessage="Transaction ID: " />
                  <span className="font-weight-bold">
                    {_get(paymentDetails, "payment_id", <EmptyLabel />)}
                  </span>
                </p>
                <p className="text-grey-63 mb-6">
                  <FormattedMessage id="payment.credit" defaultMessage="Credit: " />
                  <span className="font-weight-bold">
                    {_get(paymentDetails, "credits_added", <EmptyLabel />)}
                  </span>
                </p>
                <p className="text-grey-63 mb-6">
                  <FormattedMessage id="payment.amount" defaultMessage="Amount: " />
                  <span className="font-weight-bold">
                    {_get(paymentDetails, "amount", <EmptyLabel />)}
                  </span>
                </p>
                <p className="text-grey-63 mb-6">
                  <FormattedMessage id="payment.transaction_date" defaultMessage="Transaction Date: " />
                  <span className="font-weight-bold">
                    {(_get(paymentDetails, "transaction_date", "") !== "") ? (moment(_get(paymentDetails, "transaction_date", "")).format(displayDateTimeFormat)) : <EmptyLabel />}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: (_get(state, "oauth.token.access_token", "") !== "") ? true : false,
  displayDateTimeFormat: _get(state, "application.constants.displayDateTimeFormat", ""),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCreditDetails: () => dispatch(fetchCreditDetails()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentSuccess)));