import React from "react";

export const ErrorMessage = ({ message }) => {
  return (
    <p className="error text-danger text-capitalize-first">{(message || "")}</p>
  );
};

export const showContentLoader = (props) => {
  return (
    <div className={`loader ${(props.className || "")}`} />
  );
};