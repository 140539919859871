import React, { PureComponent } from "react";
import { isEmpty as _isEmpty } from "lodash";
import PropTypes from "prop-types";

import { EmptyLabel } from ".";

import { isInt } from "../../../helpers/utils";

class TableCellRenderer extends PureComponent {

	static defaultProps = {
		value: "",
		className: "text-truncate",
		tooltip: false
	};

	render() {
		const { value = "", tooltip = true, ...restProps } = this.props;

		return (
			<div
				{...(((tooltip === true) && (typeof value === "string") && !_isEmpty(value)) ? { title: value } : {})}
				{...restProps}
			>
				{(isInt(value) === true) ? (value || 0) : (value || <EmptyLabel />)}
			</div>
		)
	}
};

TableCellRenderer.propTypes = {
	className: PropTypes.string,
	tooltip: PropTypes.bool,
};

export default TableCellRenderer;