export const FETCH_COMBINED_LOOKUP_DATA = "FETCH_COMBINED_LOOKUP_DATA";
export const FETCH_COMBINED_LOOKUP_DATA_SUCCESS = "FETCH_COMBINED_LOOKUP_DATA_SUCCESS";
export const FETCH_COMBINED_LOOKUP_DATA_ERROR = "FETCH_COMBINED_LOOKUP_DATA_ERROR";

export const combinedLookupData = (payload) => {
  return ({
    type: FETCH_COMBINED_LOOKUP_DATA,
    payload
  });
}

export const UPLOAD_READ_FILE_SUCCESS = "UPLOAD_READ_FILE_SUCCESS";

export const uploadReadFileSuccess = (payload) => {
  return ({
    type: UPLOAD_READ_FILE_SUCCESS,
    payload
  });
}

export const UPLOAD_READ_FILE_ERROR = "UPLOAD_READ_FILE_ERROR";

export const uploadReadFileError = (payload) => {
  return ({
    type: UPLOAD_READ_FILE_ERROR,
    payload
  });
}

export const SAVE_UPLOAD_MAPPING_DATA_REQUEST = "SAVE_UPLOAD_MAPPING_DATA_REQUEST";
export const SAVE_UPLOAD_MAPPING_SUCCESS = "SAVE_UPLOAD_MAPPING_SUCCESS";
export const SAVE_UPLOAD_MAPPING_ERROR = "SAVE_UPLOAD_MAPPING_ERROR";

export const saveUploadMappingData = (payload) => {
  return ({
    type: SAVE_UPLOAD_MAPPING_DATA_REQUEST,
    payload
  });
}

export const RESET_DATA_MAPPING_ERROR = "RESET_DATA_MAPPING_ERROR";

export const resetDataMappingError = () => {
  return ({
    type: RESET_DATA_MAPPING_ERROR
  });
}

export const RESET_DATA_MAPPING_CONFIG = "RESET_DATA_MAPPING_CONFIG";

export const resetDataMappingConfig = () => {
  return ({
    type: RESET_DATA_MAPPING_CONFIG
  });
}

export const REMOVE_UPLOADED_FILE_REQUEST = "REMOVE_UPLOADED_FILE_REQUEST";
export const REMOVE_UPLOADED_FILE_SUCCESS = "REMOVE_UPLOADED_FILE_SUCCESS";
export const REMOVE_UPLOADED_FILE_ERROR = "REMOVE_UPLOADED_FILE_ERROR";

export const removeUploadedFile = (payload) => {
  return ({ type: REMOVE_UPLOADED_FILE_REQUEST, payload });
}

export const RESET_REMOVE_UPLOADED_FILE = "RESET_REMOVE_UPLOADED_FILE";

export const resetRemoveUploadedFile = () => {
  return ({
    type: RESET_REMOVE_UPLOADED_FILE,
  });
}

//Enrich History Data
export const FETCH_SEARCH_DATA_REQUEST = "FETCH_SEARCH_DATA_REQUEST";
export const FETCH_SEARCH_DATA_SUCCESS = "FETCH_SEARCH_DATA_SUCCESS";
export const FETCH_SEARCH_DATA_ERROR = "FETCH_SEARCH_DATA_ERROR";

export const fetchSearchData = (payload) => {

  return ({
    type: FETCH_SEARCH_DATA_REQUEST,
    payload
  });
}

export const RESET_SEARCH_DATA_ERROR = "RESET_SEARCH_DATA_ERROR";

export const resetSearchDataError = () => {
  return ({ type: RESET_SEARCH_DATA_ERROR });
}

//Enrich Invoice Data
export const FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_ERROR = "FETCH_INVOICES_ERROR";

export const fetchInvoices = (payload) => {

  return ({
    type: FETCH_INVOICES_REQUEST,
    payload
  });
}

export const RESET_INVOICES_ERROR = "RESET_INVOICES_ERROR";

export const resetInvoiceError = () => {
  return ({ type: RESET_INVOICES_ERROR });
}