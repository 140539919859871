export const FETCH_TOP_TILES_DATA_REQUEST = "FETCH_TOP_TILES_DATA_REQUEST";
export const FETCH_TOP_TILES_DATA_SUCCESS = "FETCH_TOP_TILES_DATA_SUCCESS";
export const FETCH_TOP_TILES_DATA_ERROR = "FETCH_TOP_TILES_DATA_ERROR";

export const fetchTopTilesData = (payload) => {
  return ({
    type: FETCH_TOP_TILES_DATA_REQUEST,
    payload
  });
}

export const RESET_TOP_TILES_DATA = "RESET_TOP_TILES_DATA";

export const resetTopTilesData = () => {
  return ({
    type: RESET_TOP_TILES_DATA
  });
}

export const FETCH_LEADS_VERIFIED_CHART_REQUEST = "FETCH_LEADS_VERIFIED_CHART_REQUEST";
export const FETCH_LEADS_VERIFIED_CHART_SUCCESS = "FETCH_LEADS_VERIFIED_CHART_SUCCESS";
export const FETCH_LEADS_VERIFIED_CHART_ERROR = "FETCH_LEADS_VERIFIED_CHART_ERROR";

export const fetchLeadsVerifiedChart = (payload) => {
  return ({
    type: FETCH_LEADS_VERIFIED_CHART_REQUEST,
    payload
  });
}

export const RESET_LEADS_VERIFIED_CHART_DATA = "RESET_LEADS_VERIFIED_CHART_DATA";

export const resetLeadsVerifiedChartData = () => {
  return ({
    type: RESET_LEADS_VERIFIED_CHART_DATA
  });
}

export const FETCH_CREDIT_CONSUMPTIONS_REQUEST = "FETCH_CREDIT_CONSUMPTIONS_REQUEST";
export const FETCH_CREDIT_CONSUMPTIONS_SUCCESS = "FETCH_CREDIT_CONSUMPTIONS_SUCCESS";
export const FETCH_CREDIT_CONSUMPTIONS_ERROR = "FETCH_CREDIT_CONSUMPTIONS_ERROR";

export const fetchCreditConsumptionsChart = (payload) => {
  return ({
    type: FETCH_CREDIT_CONSUMPTIONS_REQUEST,
    payload
  });
}

export const RESET_CREDIT_CONSUMPTIONS_CHART_DATA = "RESET_CREDIT_CONSUMPTIONS_CHART_DATA";

export const resetCreditConsumptionsChartData = () => {
  return ({
    type: RESET_CREDIT_CONSUMPTIONS_CHART_DATA
  });
}