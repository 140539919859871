import React, { PureComponent } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { isEqual as _isEqual } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";

export class Textarea extends PureComponent {
  static defaultProps = {
    baseClassName: "Textarea",
    labelClassName: "",
    inputClassName: "",
    value: "",
    forwardedRef: () => { },
    onChange: () => { },
    onTypingdone: () => { },
    insertVar: null,
    minHeight: 28,
  }

  constructor(props) {
    super(props);

    this._textAreaRef = null;

    this.timer = null;

    [
      "_handleChange",
      "_handleHeight",
      "_handleRef"
    ].map((fn) => this[fn] = this[fn].bind(this));
  }


  componentDidMount() {
    if (this._textAreaRef !== null) {
      this._handleHeight(this._textAreaRef);
    }
  }

  componentDidUpdate(prevProps) {

    // handle height of textarea with input change.
    if (prevProps.value !== this.props.value) {
      if (this._textAreaRef !== null) {
        this._handleHeight(this._textAreaRef);
      }
    }

    if (!_isEqual(prevProps.insertVar, this.props.insertVar) && (this.props.insertVar !== null)) {

      setTimeout(() => {
        if (this._textAreaRef !== null) {
          //IE support
          if (document.selection) {
            this._textAreaRef.focus();
            const sel = document.selection.createRange();
            sel.text = ` ${this.props.insertVar} `;
          }
          //MOZILLA and others
          else if (this._textAreaRef.selectionStart || this._textAreaRef.selectionStart === "0") {
            const startPos = this._textAreaRef.selectionStart;
            const endPos = this._textAreaRef.selectionEnd;
            this._textAreaRef.value = `${this._textAreaRef.value.substring(0, startPos)
              } ${this.props.insertVar} ${this._textAreaRef.value.substring(endPos, this._textAreaRef.value.length)}`;
          }
          else {
            this._textAreaRef.value += ` ${this.props.insertVar} `;
          }
        }
      }, 100);
    }
  }

  _handleHeight(el) {
    const { value } = this.props;

    if (el === null)
      return;

    if (
      (value === "") ||
      (el.scrollHeight <= this.props.minHeight)
    ) {
      el.style.cssText = `height: ${this.props.minHeight}px`;
      return;
    }

    if (typeof this.props.maxHeight !== "undefined" && el.scrollHeight > this.props.maxHeight) {
      el.style.cssText = `height: ${this.props.maxHeight}px`;
      return;
    }

    el.style.cssText = "height: auto";
    el.style.cssText = `height: ${el.scrollHeight + 2}px`;
  }

  _handleRef(node) {
    this._textAreaRef = node;
    if (typeof this.props.forwardedRef !== "undefined") this.props.forwardedRef(node);
  }

  _handleChange(e) {
    const value = typeof e !== "undefined" ? e.target.value : "";
    const { onChange, onTypingdone } = this.props;

    if (typeof onChange === "function") { onChange(value); }

    clearTimeout(this.timer);
    if (typeof onTypingdone === "function") {
      this.timer = setTimeout(() => onTypingdone(value), 500);
    };
  }

  render() {
    const {
      id = "id",
      baseClassName,
      className,
      labelClassName,
      inputClassName,
      clearClassName,
      type = "text",
      label,
      value = "",
      helpText = "",
      onChange,
      onTypingdone,
      forwardedRef,
      placeholder,
      error,
      minHeight,
      maxHeight,
      insertVar,
      ...props
    } = this.props;

    return (
      <div
        className={classNames(
          {
            [`${baseClassName}__root`]: true,
            [`${baseClassName}--error`]: error,
            [`${baseClassName}--placeholder`]: placeholder,
            [`${baseClassName}--value`]: value
          },
          className
        )}
      >
        <textarea
          id={id}
          className={`${baseClassName}__textarea ${inputClassName}`}
          placeholder={placeholder || ""}
          type={type}
          value={value}
          onChange={this._handleChange}
          ref={this._handleRef}
          {...props}
        />

        {(!!label) && (
          <label htmlFor={id} className={`${baseClassName}__label m-0 text-break ${labelClassName}`}>
            {label}
            {(helpText !== "") && (
              <OverlayTrigger
                overlay={(<Tooltip className="title title--smx tooltip-cls">{helpText}</Tooltip>)} placement="top">
                <span className="sub-title sub-title--lgx sub-title--bold op-4 pl-2 cursor-pointer">
                  <i className="fas fa-info-circle sub-title sub-title--lgx sub-title--bold op-4 pl-2 cursor-pointer"></i>
                </span>
              </OverlayTrigger>
            )}
          </label>
        )}

        {(!!error) && (
          <p className={`${baseClassName}__error text-capitalize-first`}>{error}</p>
        )}
      </div>
    )
  }
}

Textarea.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  onTypingdone: PropTypes.func,
  id: PropTypes.string,
  baseClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  clearClassName: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string
};

export default Textarea;
