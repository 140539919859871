import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Helmet from "react-helmet";

class TermsConditionsInternal extends Component {

  _renderTC1 = () => {

    return (
      <li>
        <strong className="m-r-2">APPLICATION</strong>
        <ol>
          <li>These Terms and Conditions of Use (this "<strong>Agreement</strong>") apply to your use of and/or access to the Site (as defined below) that is owned, operated or controlled by the Company and is intended for and directed to Customers of the Company only.</li>
          <li>The Company provides the Site to you subject to this Agreement. By accessing and/or using the Site, you (the "<strong>Customer</strong>") agree to be bound by these Terms and Conditions of Use. If you do not accept these Terms and Conditions of Use, you are not authorised to use and/or access the Site.</li>
          <li>This Agreement may be modified from time to time. The Company has the right at any time in its entire discretion to change and/or modify these Terms and Conditions of Use and such changes and/or modifications are to be effective immediately upon posting to the Site. Please read this Agreement carefully to ensure you understand the Terms and Conditions that apply at that time. Your continued use of the Site after such posting will signify your acceptance of such changes and/or modifications.</li>
        </ol>
      </li>
    );
  }

  _renderTC2 = () => {

    return (
      <li><strong>DEFINITIONS AND INTERPRETATION</strong>
        <ol>
          <li>
            Definitions
            <p>In this Agreement, unless the context indicates otherwise:</p>
            <p><strong>Applicable Law</strong>, means any and all applicable laws, statuses, orders, rules, treaties, decree, regulations, directives, edicts, bylaws, schemes, warrants, other instruments made under or to be made under any statute, any exercises of the royal prerogative and codes of conduct and regulatory rules or guidelines, whether local, national, international or otherwise existing from time to time, together with any other similar instrument having legal effect in the relevant circumstances;</p>
            <p><strong>Company</strong>, The company means WebStemLtd., a Limited Liability Company with Company Number 12323064, having its registered address at 182 Creek Road, March, England, PE15 8RN;</p>
            <p><strong>Customer</strong>, means the Party who is permitted to receive the Data and Services from the Company as specified in this Agreement;</p>
            <p><strong>Data</strong>, means all data, content, information and/or materials provided by the Company to the Customer through the Site, from the Company’s Enrich B2B’s Linkedin API service and/or via electronic mail communication between the Company and the Customer for the purposes of this Agreement;</p>
            <p><strong>Force Majeure</strong>, means an event or sequence of events beyond a Party's reasonable control preventing or delaying it from performing its obligations under this Agreement save that an inability to pay is not a Force Majeure event;</p>
            <p><strong>Party and Parties</strong>, means the Company and the Customer, and ‘Party’ shall mean either one of them;</p>
            <p><strong>Personnel</strong>, means a Party’s affiliates, officers, agents, partners and employees, staff, professional advisers or appointed individuals who are key to or engaged in or in relation to the performance or management of this Agreement;</p>
            <p><strong>Service(s)</strong>, means, as the context permits, the service or services provided by the Company to the Customer in relation to the use of the Site or to access the Data including but not limited to the Company’s API service; and</p>
            <p><strong>Site</strong>, means the website with domain name https://www.enrichb2bdata.com/and nubela.co or such other name which the Company provides to the Customer in respect of this Agreement.</p>
          </li>
          <li>
            Interpretation
            <p>In this Agreement, unless the contrary intention appears:</p>
            <p>words importing a gender include any other gender;</p>
            <p>words in the singular include the plural and words in the plural include the singular;</p>
            <p>clause headings are for convenient reference only and have no effect in the interpretation of this Agreement or limiting or extending the language of provisions to which they refer;</p>
            <p>words importing a person includes a partnership and a body whether corporate or otherwise;</p>
            <p>a reference to pounds is a reference to £ pounds sterling (GBP);</p>
            <p>references to time will mean UK time, unless otherwise stated;</p>
            <p>a reference to any legislation or legislative provision includes any statutory modification, substitution or re-enactment of that legislation or legislative provision;</p>
            <p>if any word or phrase is given a defined meaning, any other part of speech or other grammatical form of that word or phrase has a corresponding meaning;</p>
            <p>references to books, records or other information include paper, electronically or magnetically stored data, film, microfilm, and information in any other form;</p>
            <p>a reference to writing is a reference to any representation of words, figures or symbols, whether or not in a visible form; and</p>
            <p>if any conflict arises between the terms and conditions contained in the clauses of this Agreement, the terms and conditions of the Agreement prevail.</p>
          </li>
        </ol>
      </li>
    );
  }

  _renderTC3 = () => {

    return (
      <li><strong className="m-r-2">OWNERSHIP, ACCESS AND LICENSE</strong>
        <ol>
          <li>The Site and all designs, names, logos and get-up within the Site are under common control and operated by the Company and/or its licensors. You may only take copies of any part of the Site for the purpose of using the Site in accordance with Article 4.</li>
          <li>The Company grants you a non-exclusive and non-transferable limited license to access and utilise the Site and Service and make use of the Data in accordance with and subject to the provisions contained in this Agreement.</li>
          <li>Subject to Article 4 in this Agreement, this license does not include any resale or commercial use of the Site, Service or the Data, any derivative use of the Site, Service or the Data or any use of data mining, scraping, robots, or similar data gathering and extraction software, technology or tools.</li>
          <li>Any unauthorised use of the Site, Service or the Data terminates your limited license with immediate effect. Notwithstanding this, the Company may revoke this limited license at any time for any or no reason.</li>
        </ol>
      </li>
    );
  }

  _renderTC4 = () => {

    return (
      <li><strong className="m-r-2">PERMITTED USE</strong>
        <ol>
          <li>You may only access and utilise the Site, Service and Data for your internal business purposes only and in accordance with the applicable law or regulation(s) as required by the state, governmental or regulatory authority.</li>
          <li>In the event of a bulk Data sale by the Company to the Customer, the Customer’s terms of use of the Site, Service and Data shall be subject to and governed by the Data License Agreement as entered into between the Company and the Customer.</li>
        </ol>
      </li>
    );
  }

  _renderTC5 = () => {

    return (
      <li><strong className="m-r-2">PROHIBITED USE</strong>
        <ol>
          <li>
            Subject to Article 4 herein and unless expressly authorised by the Company in writing, you may not do, permit, or authorise the doing of any of the following acts in relation to the Site or Services:
            <p>in a way that is unlawful;</p>
            <p>copy, reformat, reprocess, distribute, dispose of, duplicate, transmit, display, revise, perform, reproduce, publish, license, hyperlink, deep-link, create derivative works from, transfer, trade, sell or re-sell;</p>
            <p>download (other than page caching) or modify any portion of it;</p>
            <p>use in connection with any material which contains computer viruses or spyware or malware of any description or with any material which is designed to adversely affect the operation of any computer hardware or software or any communications network;</p>
            <p>use in any manner in which simultaneous access by multiple unauthorised users is permitted;</p>
            <p>use a personal or proxy account to register an account with the Company to access and utilise the Site for the purposes of downloading, transferring or copying Data for the benefit of another merchant or business;</p>
            <p>frame, inline link or utilise other techniques to associate or juxtapose any of the Company’s name, logos, or other materials with advertisements and/or other information not originating from the Site;</p>
            <p>translate, edit, reverse-engineer, decompile, or disassemble the Site or attempt to do so.</p>
          </li>
          <li>The Company reserves the right to prevent or suspend your access to the Site and/or the Services immediately without notice if you do not comply with any part of this Agreement and/or and conditions or any applicable law.</li>
          <li>Notwithstanding Clause 5.2, the Company retains the right at its sole discretion to suspend or cease the operation of the Site from time to time or deny access to you or anyone to this Site, at any time and for any or no reason.</li>
        </ol>
      </li>
    );
  }

  _renderTC6 = () => {

    return (
      <li><strong className="m-r-2">DISCLAIMERS</strong>
        <ol>
          <li>The Site, Services and all Data, information, content, materials included on or otherwise made available to you by the Company are provided on an ‘as is’ and ‘as available’ basis, unless otherwise specified in writing.</li>
          <li>While the Company will use reasonable efforts to include accurate and up-to-date information on the Site, the Company makes no representations or warranties of any kind, express or implied, as to the operation of the Site, provision of the Service or that the Data remains accurate, complete and up-to-date, or fit or suitable for any particular purpose. Any reliance you place on the Data is at your own sole risk.</li>
          <li>The Company does not warrant that the Site, Service or the Data or any information, content, materials or services included on or otherwise made available to you through the Site, Service their servers, or electronic mails sent from the Company are free of viruses or other harmful components.</li>
          <li>The Company makes no representations or warranties of any kind, express or implied, that it is in any way an official LinkedIn API.</li>
          <li>Nothing in the Site or the Data constitutes technical, financial, legal advice or any other type of advice and should not be relied on for any purposes.</li>
          <li>The Site may contain hyperlinks or references to external third-party websites. Any such hyperlinks or reference is provided for your convenience only. The Company has no control over third-party websites and accept no responsibility for any content, material or information contained in them. The display of any hyperlink and reference to any third-party website does not constitute an endorsement of such third party's website, products or services. Your use of a third-party site may be governed by the terms and conditions of that third-party site.</li>
        </ol>
      </li>
    );
  }

  _renderTC7 = () => {

    return (
      <li><strong className="m-r-2">LIMITATION OF LIABILITY</strong>
        <ol>
          <li>Under no circumstances shall the Company be liable to you for any loss or damage suffered (including without limitation direct or indirect losses) arising from your use of, access to, or reliance on, the Data, Service and the Site (including the downloading of any software, program or information).</li>
          <li>
            To the maximum extent permitted by applicable law, the Company disclaims and excludes all liability (whether direct or indirect or consequential or economic incidental or special and whether arising in contract, tort, breach of statutory duty or otherwise) arising out of or in connection with your use of the Site, Service or the Data including without limitation any and all losses relating to or resulting from:
            <p>the accuracy, reliability, completeness, suitability, merchantability or fitness for purpose of the Site or the Data;</p>
            <p>any error or inaccuracies in any information or material within or relating to the Data or the Site;</p>
            <p>the inability at any time to obtain access to any part of the Site or Service or the unavailability of the Data for whatsoever reason;</p>
            <p>any reliance upon or use of or actions taken or not taken or decisions made on the basis of anything contained in the Site or the Data;</p>
            <p>any computer viruses or spyware or malware of any description or any material which might adversely affect;</p>
            <p>the operation of any computer hardware or software or any communications network which affects you as a result of you accessing the Site or the Data; and</p>
            <p>any representation or statement (whether express or implied) made in the Site or the Data.</p>
          </li>
        </ol>
      </li>
    );
  }

  _renderTC8 = () => {

    return (
      <li><strong className="m-r-2">WARRANTY AND INDEMNITY</strong>
        <ol>
          <li>
            As a condition of your use of the Service and access to the Site, you agree:
            <p>not to use the Site for any purpose that is unlawful under applicable law, or prohibited by this Agreement;</p>
            <p>not to defame or disparage anybody in a manner which is obscene, derogatory or offensive; and</p>
            <p>to be responsible for ensuring that your use of the Site, Service and Data is consistent and compliant with all the Company’s policies and the applicable laws and regulations in your country or territory.</p>
            <p>to waive and release your rights against the Company and undertake not to commence, maintain, initiate, or prosecute, or cause, encourage, assist, volunteer, advise or cooperate with any other person to commence, maintain, initiate or prosecute, any action, lawsuit, proceeding, charge, petition, complaint or claim before any court, agency or tribunal against the Company or its Personnel concerned with, or otherwise relating to, in whole or in part, this Agreement.</p>
          </li>
          <li>You undertake to indemnify the Company and its Personnel fully and hold it harmless against all losses, claims, proceedings, actions, damages, costs, expenses and any other liabilities (whether direct, indirect, incidental, punitive, and consequential) which may arise out of, or in consequence of, your use of and/or access of the Site, Service and the Data, or from the use of or reliance on the Data, any information, content, materials (including software) or products and/or services included on or otherwise made available to you through the Site or in respect of any information you obtain from the Site or in consequence of your violation of any Terms and Conditions in this Agreement.</li>
          <li>You further undertake to indemnify the Company and its Personnel fully and hold it harmless against all losses, claims, proceedings, actions, damages, costs, expensesand any other liabilities (whether direct, indirect, incidental, punitive, andconsequential) which may arise out of, or which is caused directly or indirectly by any act or omission of you, whether deliberate, accidental, negligent or reckless, in the course of the provision of the Service by the Company to you under this Agreement whether such acts or omissions amount to a breach of an express or implied obligation under this Agreement or a breach of any other legal requirement or obligation, code of practice, licence, regulation, content, forbearance, approval, permission or rule.</li>
          <li>The right of the Company to be indemnified under this Agreement is in addition to, and not exclusive of, any other right, power or remedy provided by law, but the Company is not entitled to be compensated in excess of the amount of the relevant cost, liability, loss, damage or expense incurred.</li>
        </ol>
      </li>
    );
  }

  _renderTC9 = () => {

    return (
      <li><strong className="m-r-2">GOVERNING LAW AND DISPUTE RESOLUTION</strong>
        <ol>
          <li>This Agreement shall be governed by and construed in accordance with the laws of United Kingdom, the courts of which shall be courts of competent jurisdiction.</li>
          <li>By accessing the Site and using the Service, you agree that the laws of the United Kingdomwithout regard to principles of conflict of laws, will govern this Agreement and any dispute of any sort that might arise between the Company and you without regard to principles of conflict of laws.</li>
          <li>Any dispute arising out of or in connection with this Agreement, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by litigation in the courts of the United Kingdom.</li>
        </ol>
      </li>
    );
  }

  _renderTC10 = () => {

    return (
      <li><strong className="m-r-2">MISCELLANEOUS / GENERAL TERMS</strong>
        <ol>
          <li>If any provision in this Agreement shall be deemed invalid, void, or for any reason unenforceable, that provision shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, that provision shall be deemed severable. Any modification to or deletion of a provision shall not affect the validity and enforceability of any remaining provisions.</li>
          <li>This Agreement contain the full and complete understanding between the parties and supersede all prior arrangements and understandings whether, written or oral, appertaining to the subject matter of this Agreement and may not be varied except by an instrument in writing signed by the Company. You acknowledge that no representations or promises not expressly contained in this Agreement have been made to you by the Company or any of its servants, agents, employees, members or representatives.</li>
          <li>The parties are independent businesses and are not partners, principal and agent or employer and employee and this Agreement does not establish any joint venture, trust, fiduciary or other relationship between them, other than the contractual relationship expressly provided for in it. None of the parties shall have, nor shall represent that they have, any authority to make any commitments on the other party's behalf.</li>
          <li>No failure or delay by the Company to exercise any right or remedy provided under this Agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it preclude or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall preclude or restrict the further exercise of that or any other right or remedy.</li>
          <li>Except as expressly provided for in this Agreement, a person who is not a party to this Agreement shall not have any rights to enforce any of the provisions of this Agreement.</li>
        </ol>
      </li>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "enrich.terms_and_conditions", defaultMessage: "Terms & Conditions: Enrich B2B" })}
          </title>
        </Helmet>
        <div className="section-heading mb-12">
          <h1 className="heading-04 fw-medium text-capitalize-first">
            <FormattedMessage id="terms_conditions.title" defaultMessage="terms and conditions of use" />
          </h1>
        </div>
        <div className="section-tc-internal row mt-20">
          <div className="col-lg-12">
            <div className="card p-15">
              <ol>
                {this._renderTC1()}
                {this._renderTC2()}
                {this._renderTC3()}
                {this._renderTC4()}
                {this._renderTC5()}
                {this._renderTC6()}
                {this._renderTC7()}
                {this._renderTC8()}
                {this._renderTC9()}
                {this._renderTC10()}
              </ol>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(TermsConditionsInternal);
