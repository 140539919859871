import React, { Component } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import moment from "moment";

import { ReactTable, TableHeaderRenderer, TableCellRenderer, showAlertMessage, DateRangePicker, Select } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";
import { IconSearch, IconDownload } from "../../helpers/iconStyles";

import { fetchSearchData, resetSearchDataError } from "../../actions/enrich";
import { updateLoadingState } from "../../actions/application";
import { getUserFilterData } from "../../services/enrich";

const defaultFilter = { id: null, name: "Filter", isdisabled: true };

class Searches extends Component {

  constructor(props) {
    super(props);

    const { intl } = props;

    this.defaultSearchPayload = {
      searchId: null,
      searchText: "",
      pageNo: 1,
      limit: 1000,
      sortColumn: "process_started_at",
      sortOrder: "DESC",
      startDate: null,
      endDate: null,
      userFilterIds: null
    }

    this.timer = null;

    this.state = {
      errors: {},
      searchPayload: _cloneDeep(this.defaultSearchPayload),
      tableData: [],
      userFilter: [],
    };

    this.columns = [
      {
        ID: "filename",
        accessor: "filename",
        maxWidth: 200,
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "searches.table.file_name", defaultMessage: "Name" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "process_started_at",
        accessor: "process_started_at",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "searches.table.upload_date", defaultMessage: "Date" })} />),
        Cell: (row) => {
          const { displayDateTimeFormat } = props;
          const date = _get(row, "value", null);

          return (<TableCellRenderer value={moment(date).format(displayDateTimeFormat)} tooltip={true} />)
        },
      },
      {
        ID: "status_text",
        accessor: "status_text",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "searches.table.status", defaultMessage: "Status" })} />),
        Cell: (row) => {
          const val = _get(row, "value", "");
          const textClass = _get(row, "row.original.color_code", "text-muted");

          return (<TableCellRenderer className={`${(textClass || "text-muted")} text-capitalize`} value={val} tooltip={true} />)
        },
      },
      {
        ID: "search_type_text",
        accessor: "search_type_text",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "searches.table.type", defaultMessage: "Type" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "records_uploaded",
        accessor: "records_uploaded",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "searches.table.records_uploaded", defaultMessage: "Records Searched" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "records_updated",
        accessor: "records_updated",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "searches.table.records_updated", defaultMessage: "Records Matched" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "credit_used",
        accessor: "credit_used",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "searches.table.credits", defaultMessage: "Credits Spent" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "total_time_taken",
        accessor: "total_time_taken",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "searches.table.time_taken", defaultMessage: "Time Taken" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "output_file_link",
        accessor: "output_file_link",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "searches.table.download", defaultMessage: "Download" })} />),
        Cell: (row) => {
          const downloadLink = _get(row, "value", "");

          if (!downloadLink) { return null; }

          return (
            <a href={downloadLink} className="span-link" target="_blank" rel="noopener noreferrer">
              <IconDownload width="14px" height="14px" />
            </a>
          );
        }
      },
    ];
  }

  componentDidMount() {
    const { tableData } = this.props;

    this._fetchFilterData();
    this._fetchTableData();

    this.setState({ tableData: tableData });
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.tableData, this.props.tableData)) {
      this.setState({ tableData: this.props.tableData });
    }

    if (!_isEqual(prevState.searchPayload, this.state.searchPayload) && !_isEmpty(this.state.searchPayload)) {
      const overwriteData = (!_isEqual(prevState.searchPayload.pageNo, this.state.searchPayload.pageNo)) ? false : true;
      this._fetchTableData(overwriteData);
    }

    if (!_isEqual(prevProps.searchError, this.props.searchError) && !_isEmpty(this.props.searchError)) {
      showAlertMessage(_get(this.props, "searchError.message", ""));

      if (typeof this.props.resetSearchDataError === "function") { this.props.resetSearchDataError(); }
    }
  }

  _fetchFilterData = async () => {
    const { updateLoadingState, intl } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getUserFilterData();

      let filterData = _get(response, "data", {});
      (filterData || []).unshift(_cloneDeep(defaultFilter));

      this.setState({ userFilter: filterData });

    } catch (err) {
      showAlertMessage((err.message || intl.formatMessage({ id: "searches.something_went_wrong_while_fetching_user_list", defaultMessage: "Something went wrong while fetching user list." })));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _fetchTableData = (overwriteData = true) => {
    const { fetchSearchData } = this.props;
    const { searchPayload } = this.state;

    const tmpPayload = {
      search_id: _get(searchPayload, "searchId", null),
      searchtext: _get(searchPayload, "searchText", ""),
      page_no: (overwriteData === true) ? 1 : _get(searchPayload, "pageNo", 1),
      limit: _get(searchPayload, "limit", 100),
      sort_column: _get(searchPayload, "sortColumn", "process_started_at"),
      sort_order: _get(searchPayload, "sortOrder", "DESC"),
      start_date: _get(searchPayload, "startDate", null),
      end_date: _get(searchPayload, "endDate", null),
      user_filter_ids: _map(_get(searchPayload, "userFilterIds", []), "id"),
    }

    if (typeof fetchSearchData === "function") {
      fetchSearchData({ "payload": tmpPayload, "overwriteData": overwriteData });
    }
  }

  _renderTopFilters = () => {
    const { intl } = this.props;
    const { userFilter } = this.state;

    return (
      <div className="row mb-25">
        <div className="col-lg-5 mb-5">
          <div className="position-relative mb-lg-0 mb-15">
            <span className="position-absolute end-0 top-50 translate-middle-y me-3">
              <IconSearch width="14px" height="14px" color="#9d9b9b" />
            </span>
            <input
              type="text"
              name="search"
              className="form-control"
              placeholder="Search"
              onChange={(e) => {
                const val = _get(e, "target.value", "");
                clearTimeout(this.timer);

                this.timer = setTimeout(() => {
                  this.setState((prevState) => ({
                    searchPayload: { ...prevState.searchPayload, "searchText": val }
                  }));
                }, 500);
              }}
            />
          </div>
        </div>
        <div className="col-lg">
          <div className="position-relative mb-lg-0 mb-15">
            <DateRangePicker
              className="form-control"
              onChange={(start, end) => this.setState((prevState) => ({
                searchPayload: {
                  ...prevState.searchPayload,
                  "startDate": (start || null),
                  "endDate": (end || null)
                }
              }))}
            />
          </div>
        </div>
        <div className="col-lg">
          <div className="position-relative mb-lg-0 mb-15">
            <Select
              className="form-select-custom text-capitalize"
              placeholder={intl.formatMessage({ id: "filter", defaultMessage: "filter" })}
              options={(userFilter || [])}
              getOptionLabel={(option) => (option.name || "")}
              getOptionValue={(option) => (option.id || null)}
              isOptionDisabled={(option) => (option.isdisabled || false)}
              onChange={(y) => this.setState((prevState) => ({
                searchPayload: {
                  ...prevState.searchPayload,
                  "userFilterIds": (y || null)
                }
              }))}
            />
          </div>
        </div>
      </div>
    );
  }

  _renderTableData = () => {
    const { tableData } = this.state;

    return (
      <div className="col-lg-12">
        <div className="bg-white border p-6 rounded-4 mb-5">
          <ReactTable tableData={(tableData || [])} columns={this.columns} />
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.searches", defaultMessage: "Searches: Enrich B2B" })} </title>
        </Helmet>

        <div>
          <div className="mt-6 my-4">
            <h1 className="heading-02 fw-medium">
              <FormattedMessage id="searches.title" defaultMessage="Searches" />
            </h1>
          </div>

          {this._renderTopFilters()}

          <div className="row">
          <div className="table-responsive">
            {this._renderTableData()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  displayDateTimeFormat: _get(state, "application.constants.displayDateTimeFormat", ""),
  historyPageInfo: _get(state, "enrich.searches.data.search_info", {}),
  tableData: _get(state, "enrich.searches.data.saved_search_file_upload", []),
  searchError: _get(state, "enrich.searches.errors", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchSearchData: (data) => dispatch(fetchSearchData(data)),
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  resetSearchDataError: () => dispatch(resetSearchDataError()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Searches)));