import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { get as _get } from "lodash";
import Helmet from "react-helmet";

import { ReactTable, TableHeaderRenderer, TableCellRenderer } from "../../components/common/controls";
import { IconSearch, IconDownload } from "../../helpers/iconStyles";

class LogsAnalytics extends Component {

  constructor(props) {
    super(props);

    const { intl } = props;

    this.columns = [
      {
        ID: "file_name",
        accessor: "file_name",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "logs_analytics.table.file_name", defaultMessage: "File Name" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "upload_date",
        accessor: "upload_date",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "logs_analytics.table.upload_date", defaultMessage: "Upload Date" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "progress",
        accessor: "progress",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "logs_analytics.table.progress", defaultMessage: "Progress" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "records_uploaded",
        accessor: "records_uploaded",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "logs_analytics.table.records_uploaded", defaultMessage: "Records Uploaded" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "records_updated",
        accessor: "records_updated",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "logs_analytics.table.records_updated", defaultMessage: "Records Updated" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "credit",
        accessor: "credit",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "logs_analytics.table.credits", defaultMessage: "Credits" })} />),
        Cell: (row) => (<TableCellRenderer value={_get(row, "value", "")} tooltip={true} />),
      },
      {
        ID: "download",
        accessor: "download",
        Header: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "logs_analytics.table.download", defaultMessage: "Download" })} />),
        Cell: (row) => (
          <IconDownload width="14px" height="14px" />
        ),
      },
    ];

    this.tableData = [
      {
        file_name: "File-Name.xls",
        upload_date: "09-03-2022",
        progress: "In Progress",
        records_uploaded: "455",
        records_updated: "344",
        credit: "2000"
      }
    ];
  }

  _renderTopFilters = () => {
    return (
      <div className="row mb-25">
        <div className="col-lg-5">
          <div className="position-relative mb-lg-0 mb-15">
            <span className="position-absolute end-0 top-50 translate-middle-y me-15">
              <IconSearch width="14px" height="14px" />
            </span>
            <input type="text" name="search" className="form-control form-control-sm pe-30" placeholder="Search" />
          </div>
        </div>
        <div className="col-lg">
          <div className="position-relative mb-lg-0 mb-15">
            <select className="form-select form-select-sm">
              <option>Date Range</option>
              <option value={1}>One</option>
              <option value={2}>Two</option>
              <option value={3}>Three</option>
            </select>
          </div>
        </div>
        <div className="col-lg">
          <div className="position-relative mb-lg-0 mb-15">
            <select className="form-select form-select-sm">
              <option>Filter</option>
              <option value={1}>One</option>
              <option value={2}>Two</option>
              <option value={3}>Three</option>
            </select>
          </div>
        </div>
      </div>
    );
  }

  _renderTableData = () => {

    return (
      <div className="col-lg-12">
        <div className="card p-15">
          <ReactTable tableData={this.tableData} columns={this.columns} className="max-h-520 vh-100 table-responsive"/>
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "enrich.logs_analytics", defaultMessage: "Logs & Analytics: Enrich B2B" })} </title>
        </Helmet>

        <div>
          <div className="section-heading mb-12">
            <h1 className="heading-04 fw-medium">
              <FormattedMessage id="logs_analytics.title" defaultMessage="Logs & Analytics" />
            </h1>
          </div>

          {this._renderTopFilters()}

          <div className="row">
            {this._renderTableData()}
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(LogsAnalytics);