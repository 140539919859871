import React, { PureComponent } from "react";
import { ReactModal as RModal } from "react-modal";
import classNames from "classnames";
import PropTypes from "prop-types";

export class ReactModal extends PureComponent {

  static defaultProps = {
    baseClassName: "Modal",
    showCloseButton: true,
    onClose: () => { },
    customCloseButton: (null)
  }

  render() {
    const {
      baseClassName = "",
      portalClassName = "",
      overlayClassName = "",
      className = "",
      iconClassName = "",
      showCloseButton = false,
      customCloseButton = (null),
      children,
      isOpen = false,
      onClose = () => { },
      center = true,
      ...props
    } = this.props;

    return (
      <RModal
        portalClassName={`${baseClassName}__root ${portalClassName}`}
        overlayClassName={classNames({ [`${baseClassName}__overlay`]: true, [`${baseClassName}__overlay--show`]: isOpen }, overlayClassName)}
        className={classNames({ [`${baseClassName}`]: true, [`${baseClassName}--centered`]: center }, className)}
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={onClose}
        {...props}
      >
        {!center && showCloseButton && (customCloseButton || <span className={`${baseClassName}__close icon fal fa-times ${iconClassName}`} onClick={() => this.props.onClose()}></span>)}
        {children}
      </RModal>
    )
  }
}

ReactModal.propTypes = {
  portalClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
  baseClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  showCloseButton: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  customCloseButton: PropTypes.node,
  center: PropTypes.bool,
};

export default ReactModal;