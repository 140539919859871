// Language configuration

import moment from "moment";
export const LANGUAGES = {
  "en-gb": {
    id: "en-gb",
    name: "english",
    file_name: "en.js"
  }
};

//Default web language
export const DEFAULT_LANGUAGE = "en-gb";

// Sidebar Menu
export const sidebarMenuConfig = {
  "dashboard": {
    height: "21px",
    width: "22px",
    name: "dashboard",
    group: "dashboard",
    route: "dashboard",
    icon: "iconDashboard",
    containSubMenu: false,
    menuLanguageKey: "dashboard",
  },
  "enrich": {
    height: "21px",
    width: "22px",
    icon: "iconMagic",
    route: "",
    containSubMenu: true,
    menuLanguageKey: "enrich",
    subMenus: [
      {
        height: "13px",
        width: "11px",
        icon: "iconUser",
        group: "enrich",
        route: "enrich/contact",
        name: "enrich_contact",
        menuLanguageKey: "enrich_contact",
      },
      {
        height: "12px",
        width: "12px",
        icon: "iconCompany",
        group: "enrich",
        route: "enrich/company",
        name: "enrich_company",
        menuLanguageKey: "enrich_company",
      },
      {
        height: "12px",
        width: "12px",
        icon: "iconCompany",
        group: "enrich",
        route: "enrich/company-employees",
        name: "enrich_company_employees",
        menuLanguageKey: "enrich_company_employees",
      }
    ]
  },
  "searches": {
    height: "21px",
    width: "22px",
    icon: "iconSearch",
    name: "searches",
    group: "searches",
    route: "searches",
    containSubMenu: false,
    menuLanguageKey: "searches",
  },
  // "logs_analytics": {
  //   height: "14px",
  //   width: "17px",
  //   icon: "iconLog",
  //   name: "logs_analytics",
  //   group: "logs_analytics",
  //   route: "logs-and-analytics",
  //   containSubMenu: false,
  //   menuLanguageKey: "logs_analytics",
  // },
  "api": {
    height: "21px",
    width: "22px",
    icon: "iconApi",
    route: "",
    containSubMenu: true,
    menuLanguageKey: "api",
    subMenus: [
      {
        height: "10px",
        width: "18px",
        icon: "iconApiKey",
        group: "api",
        route: "api/keys",
        name: "api_keys",
        menuLanguageKey: "api_keys",
      },
      {
        height: "16px",
        width: "13px",
        icon: "iconDocument",
        group: "api",
        route: "api/docs",
        name: "api_docs",
        menuLanguageKey: "api_docs",
      }
    ]
  },
  // "my_team": {
  //   height: "13px",
  //   width: "11px",
  //   icon: "iconTeam",
  //   name: "my_team",
  //   group: "my_team",
  //   route: "team",
  //   containSubMenu: false,
  //   menuLanguageKey: "my_team",
  // },
  "my_invoice": {
    height: "21px",
    width: "22px",
    icon: "iconInvoice",
    name: "my_invoice",
    group: "my_invoice",
    route: "invoice",
    containSubMenu: false,
    menuLanguageKey: "my_invoice",
  },
  "user_management": {
    height: "21px",
    width: "22px",
    icon: "iconUser",
    name: "user_management",
    group: "user_management",
    route: "user-management",
    containSubMenu: false,
    menuLanguageKey: "user_management",
  },
  "settings": {
    height: "21px",
    width: "22px",
    icon: "iconSetting",
    name: "settings",
    group: "settings",
    route: "settings",
    containSubMenu: false,
    menuLanguageKey: "settings",
  },
};


export const tmpConstant = {
  "datepickerFormat": "dd/MM/yyyy",
  "displayDateFormat": "DD/MM/YYYY",
  "APIRequestDateFormat": "YYYY-MM-DD",
  "APIRequestFullDateFormat": "YYYY-MM-DD hh:mm:ss",
  "displayDateTimeFormat": "DD/MM/YYYY HH:mm:ss",
  "allowedUploadFiles": {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"]
  },
  "allowedUploadFilesLabel": ".xlsx, .xls, .csv",
  "dateRangeSlots": {
    "No Date": [moment().toDate(), moment().subtract(1, "days").toDate()],
    "Today": [moment().toDate(), moment().toDate()],
    "Yesterday": [
      moment().subtract(1, "days").toDate(),
      moment().subtract(1, "days").toDate(),
    ],
    "Last 7 Days": [
      moment().subtract(6, "days").toDate(),
      moment().toDate(),
    ],
    "Last 30 Days": [
      moment().subtract(29, "days").toDate(),
      moment().toDate(),
    ],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
    "Last Month": [
      moment().subtract(1, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
  }
};

// Application routes
export const appRoutes = [
  {
    path: "/dashboard",
    exact: true,
    component: "dashboard",
    activeMenu: { group: "dashboard", name: "dashboard" }
  }, {
    path: "/enrich/contact",
    exact: true,
    component: "enrichContact",
    // component: "underMaintenance",
    activeMenu: { group: "enrich", name: "enrich_contact" }
  },
  {
    path: "/enrich/company",
    exact: true,
    component: "enrichCompany",
    // component: "underMaintenance",
    activeMenu: { group: "enrich", name: "enrich_company" }
  },
  {
    path: "/enrich/company-employees",
    exact: true,
    component: "enrichCompanyEmployees",
    // component: "underMaintenance",
    activeMenu: { group: "enrich", name: "enrich_company_employees" }
  }, {
    path: "/searches",
    exact: true,
    component: "searches",
    activeMenu: { group: "searches", name: "searches" }
  }, {
    path: "/logs-and-analytics",
    exact: true,
    component: "logsAnalytics",
    activeMenu: { group: "logs_analytics", name: "dashboard" }
  }, {
    path: "/api/keys",
    exact: true,
    component: "APIKeys",
    activeMenu: { group: "api", name: "api_keys" }
  }, {
    path: "/api/docs",
    exact: true,
    component: "underConstruction",
    activeMenu: { group: "api", name: "api_docs" }
  }, {
    path: "/team",
    exact: true,
    component: "myTeam",
    activeMenu: { group: "my_team", name: "my_team" }
  }, {
    path: "/invoice",
    exact: true,
    component: "invoices",
    activeMenu: { group: "my_invoice", name: "my_invoice" }
  }, {
    path: "/settings",
    exact: true,
    component: "settings",
    activeMenu: { group: "settings", name: "settings" }
  }, {
    path: "/add-credits",
    exact: true,
    component: "addCredits",
    activeMenu: { group: "", name: "" }
  }, {
    path: "/payment-success/:sessionId",
    exact: true,
    component: "paymentSuccess",
    activeMenu: { group: "", name: "" }
  }, {
    path: "/payment-error/:sessionId",
    exact: true,
    component: "paymentError",
    activeMenu: { group: "", name: "" }
  }, {
    path: "/user-management",
    exact: true,
    component: "userManagement",
    activeMenu: { group: "user_management", name: "user_management" }
  }, {
    path: "/terms-conditions",
    exact: true,
    component: "termsConditionsInternal",
    activeMenu: { group: "", name: "" }
  },
];