import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { Navigate, Outlet } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";

import { decryptData } from "../../../helpers/crypto";
import { withRouter } from "../withRouter";
import config from "../../../helpers/config";

import { setToken } from "../../../actions/oauth";

class PrivateRoute extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies, setToken, token } = props;

    if ((typeof setToken === "function") && _isEmpty(token)) {
      const tokenData = decryptData(cookies.get("tknData"));
      
      setToken(_get(tokenData, "token", {}));
    }
  }

  render() {
    const { token, ...rest } = this.props;

    const from = _get(rest, "location.pathname", "/");

    if (!_isEmpty(token) && (token !== null) && [config.getFrontendURL()].includes(window.location.host)) {
      window.location = config.getAdminURL(true, true, "/enrich/contact");
      return null;
    }

    return (
      (!_isEmpty(token) && (token !== null)) ? <Outlet {...rest} /> : <Navigate to={{ pathname: "/login", search: `?continue=${from || "/"}` }} />
    )
  }
}

const mapStateToProps = (state) => ({
  token: _get(state, "oauth.token", null)
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withCookies(PrivateRoute)));
