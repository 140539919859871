import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";

import config from "../helpers/config";

import rootReducer from "../reducers";
import rootSaga from "../sagas";

const persistConfig = {
  key: config.store_name,
  storage,
  whitelist: ["oauth"]
};

const sagaMiddleware = createSagaMiddleware();

let middleware = null;

// loading required middlewares depending upon the environment
// if (process.env.NODE_ENV === "production") {
//   middleware = applyMiddleware(
//     thunk,
//     sagaMiddleware
//   );
// } else {
  middleware = composeWithDevTools(
    applyMiddleware(
      sagaMiddleware
    )
  );
// }

const pReducer = persistReducer(persistConfig, rootReducer);

// creating the main/central store
export const store = createStore(pReducer, middleware);

export const persistor = persistStore(store);

// running saga.
sagaMiddleware.run(rootSaga);
