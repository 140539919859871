import React, { Component, lazy, Fragment, Suspense } from "react";
import { get as _get, map as _map, isEmpty as _isEmpty } from "lodash";
import { matchPath } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { withRouter } from "../../components/hoc/withRouter";
import Header from "../../components/common/publicLayout/Header";
import Footer from "../../components/common/publicLayout/Footer";

import config from "../../helpers/config";

const components = {
  "terms-and-conditions": lazy(() => import("./TermsAndConditions")),
  "": lazy(() => import("./Home")),
  "login": lazy(() => import("./Login")),
  "logout": lazy(() => import("./Logout")),
  "sign-up": lazy(() => import("./SignUp")),
  "faq": lazy(() => import("./FAQ")),
  "pricing": lazy(() => import("./Pricing")),
  "book-a-call": lazy(() => import("./BookACall")),
  "contact-us": lazy(() => import("./ContactUs")),
  "reset-password/:id": lazy(() => import("./ResetPassword")),
  "forgot-password": lazy(() => import("./ForgotPassword"))
};

class PublicPages extends Component {

  render() {
    const { token, location } = this.props;
    const route = _get(location, "pathname", "");

    if ((!_isEmpty(token)) && (token !== null) && (["/"].includes(route)) && ([config.getAdminURL()].includes(window.location.host))) {
      window.location = config.getAdminURL(true, true, "/dashboard");
      return null;
    }

    if ((!_isEmpty(token)) && (token !== null) && (!["/logout", "/login"].includes(route)) && ([config.getAdminURL()].includes(window.location.host))) {
      window.location = config.getFrontendURL(true, true);
      return null;
    }

    const exact = true;

    return (
      <>
        <Header isLoggedIn={!_isEmpty(token)} />
        <div className="logout-wrapper">
          {_map((components || {}), (TabComponent, key) => {
            const path = `/${key}`;
            const isMatched = (key) ? matchPath({ path, exact, strict: false }, route) : false;

            return (
              <Fragment key={key}>
                {(((route || "").substr(1) === key) || (isMatched)) && (
                  <Suspense fallback={(<></>)}>
                    <TabComponent />
                  </Suspense>
                )}
              </Fragment>
            )
          })}
        </div>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  token: _get(state, "oauth.token", null),
});

export default withRouter(connect(mapStateToProps, null)(injectIntl(PublicPages)));
